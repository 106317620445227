<template>
    <div class="toggle-compare" @click="toggle()">
        <span v-if="!lenderIsAdded">{{ $translate('cl_compare_lender', 'Jämför lån') }}</span>
        <span v-else>{{ $translate('cl_remove_lender_from_compare', 'Ta bort lån') }}</span>
        <div class="compare-btn__checkbox" :class="{'active': lenderIsAdded}">
            <i
                class="fa-solid fa-check check-icon"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        lenderId: {
            type: String,
            required: true,
        }
    },
    computed: {
        lenderIsAdded(){
            return this.$store.state.lendersToCompare.includes(this.lenderId);
        }
    },
    methods: {
        toggle() {
            if (this.lenderIsAdded) {
                this.$store.commit('REMOVE_LENDER_FROM_COMPARE', this.lenderId);
            }
            else {
                this.$store.commit('ADD_LENDER_TO_COMPARE', this.lenderId);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .toggle-compare {
        @include flex;
        gap: 8px;
        font-size: 14px;
        cursor: pointer;
        user-select: none;
        @include device(pad) {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .compare-btn__checkbox {
            @include size(16px);
            @include border(all,1px,$green);
            @include border-radius(3px);
            @include flex;
            color: $white;
            font-size: 12px;
            &.active {
                background: $green;
            }
        }   
    }
</style>