<template>
    <li
        :class="{ inactive: !model.isActive }"
        class="list-item"
    >
        <nuxt-link :to="$prismic.linkResolver(lender)">
            <div class="list-item__container">
                <PrismicImage
                    :img="lender.data.image"
                    w="100"
                    h="50"
                />
            </div>

            <div class="list-item__container">
                <span>{{ $translate('interest', 'Ränta') }}</span>
                <span>{{ model.interestString }}</span>
            </div>

            <div class="list-item__container">
                <span>{{ $translate('max_amount', 'Maxbelopp') }}</span>
                <span>{{ model.maxAmountString }}</span>
            </div>

            <div
                v-if="!model.isActive"
                class="list-item__archived"
            >
                {{ $translate('loan_expired', 'Det här lånet har utgått och går inte längre att teckna.') }}
            </div>
        </nuxt-link>
    </li>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },
    computed: {
        lender() {
            return this.$store.state.lenders[this.id];
        },

        model() {
            return new this.$models.Loan(this.lender, this.$store);
        }
    }
};
</script>
