<template>
    <div>
        <Banner 
            :data="data" 
            :lenders="lenders" 
        />
        <TableOfContents />
        <AutomaticRating 
            id="automatic-ranking" 
            :data="data"
            :lenders="lenders" 
        />

        <CompareData 
            id="compare-data"
            :data="data"
            :lenders="lenders" 
        />

        <Section id="connected-banks" width="md">
            <div class="section-heading">
                <h2 class="section-header">{{ connectedLendersH2 }}</h2>
                <div 
                    v-if="$validateText(data.primary.connected_lenders_teaser)" 
                    class="rich-text"
                    v-html="$prismic.asHtml(data.primary.connected_lenders_teaser)" 
                />
            </div>
            <div class="connected-banks-sections">
                <ConnectedBanksOverview :data="data" :lenders="lenders" />
                <AllConnectedBanks :data="data" :lenders="lenders" />
                <ConnectedBanksConclusion :data="data" :lenders="lenders" />
            </div>
        </Section>
        <Section id="user-ratings" width="md">
            <div class="section-heading">
                <h2 class="section-header">{{ userRatingsH2 }}</h2>
                <div 
                    v-if="$validateText(data.primary.user_ratings_teaser)" 
                    class="rich-text"
                    v-html="$prismic.asHtml(data.primary.user_ratings_teaser)" 
                />
            </div>
            <div class="user-ratings-wrapper">
                <UserRatings :lender="lenders[0]" />
                <UserRatings :lender="lenders[1]" />
            </div>
        </Section>
        
        <ContactInfo 
            id="contact" 
            :data="data"
            :lenders="lenders" 
        />
    </div>
</template>

<script>
import TableOfContents from './TableOfContents.vue';
import AutomaticRating from './AutomaticRating.vue';
import ConnectedBanksOverview from './ConnectedBanksOverview.vue';
import AllConnectedBanks from './AllConnectedBanks.vue';
import ConnectedBanksConclusion from './ConnectedBanksConclusion.vue';
import UserRatings from './UserRatings.vue';
import ContactInfo from './ContactInfo.vue';
import Banner from './Banner.vue';
import CompareData from './CompareData.vue';
export default {
    components: {
        TableOfContents,
        AutomaticRating,
        ConnectedBanksOverview,
        AllConnectedBanks,
        ConnectedBanksConclusion,
        UserRatings,
        ContactInfo,
        Banner,
        CompareData,
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        lenders() {
            const lenderids = [this.data.primary.lender_1.id, this.data.primary.lender_2.id];
            return lenderids.map(id => {
                const lender = Object.assign({}, this.$store.state.lenders[id]);
                const model = new this.$models.Loan(lender);
                lender.model = model;
                lender.connectedBanks = lender.data.connected_banks.map(item => {
                    const bank = Object.assign({}, this.$store.state.lenders[item.lender.id]);
                    bank.model = new this.$models.Loan(bank);
                    return bank;
                });
                return lender;
            });
        },
        userRatingsH2() {
            if (this.$validateText(this.data.primary.user_ratings_h2)) {
                return this.$prismic.asText(this.data.primary.user_ratings_h2);
            }
            return this.$translate('ctl_user_ratings_h2', 'Vad säger kunderna?')
                .replace('{lender1}', this.lenders[0].data.title)
                .replace('{lender2}', this.lenders[1].data.title);
        },
        connectedLendersH2() {
            if (this.$validateText(this.data.primary.connected_lenders_h2)) {
                return this.$prismic.asText(this.data.primary.connected_lenders_h2);
            }
            return this.$translate('ctl_connected_lenders_h2', 'Anslutna långivare')
                .replace('{lender1}', this.lenders[0].data.title)
                .replace('{lender2}', this.lenders[1].data.title);
        },
    }
};
</script>

<style lang="scss" scoped>
    .user-ratings-wrapper {
        display: grid;
        gap: 3rem;
        @include device(pad) {
            grid-template-columns: 1fr 1fr;
        }
    }

    .connected-banks-sections {
        display: flex;
        flex-direction: column;
        gap: 40px;
        @include device(pad) {
            gap: 60px;
        }
    }
    ::v-deep {
        .section-heading {
            @include spacing(margin, 8, bottom);
            .section-header {
                margin-bottom: 20px;
            }
        }
        .sub-section-header {
            margin-bottom: 20px;
        }
    }
</style>