<template>
    <li class="latest-posts__item">
        <nuxt-link :to="$prismic.linkResolver(post)">
            <PrismicImage
                :lazy="false"
                :img="postImage"
                :alt="post.data.title"
                w="286"
                h="143"
            />

            <span class="item-title">{{ post.data.title }}</span>
            <span v-if="showAuthor">{{ $translate('by', 'av') }}
                <span class="green text-bold">{{ getAuthor(post.data.author.id).data.name }}</span>
            </span>
        </nuxt-link>
    </li>
</template>

<script>
export default {
    props: {
        post: {
            type: Object,
            required: true
        },
        showAuthor: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        postImage() {
            if (this.post.data.image.url) return this.post.data.image;
            else {
                const image = {
                    dimensions: {
                        width: 1920,
                        height: 960
                    },
                    alt: this.post.data.title,
                    copyright: null,
                    url: 'https://images.prismic.io/lanen/a534bdf7-7229-4192-8883-4f32f2cb3866_post-default-img.webp?auto=compress,format'
                }

                return image
            }
        }
    },
    methods: {
        getAuthor(id) {
            return this.$store.state.authors[ id ];
        }
    }
};
</script>

<style lang="scss" scoped>
    .latest-posts__item {
        @include border-radius(3px);

        a {
            @include grid(1, 20px);
            text-decoration: none;
            color: $font;

            &:hover { text-decoration: underline; }

            img {
                @include size(100%, auto);
                @include border-radius(5px);
            }

            .item-title {
                font-family: $fontHeader;
                font-weight: bold;
                @include font-size(20px);
            }
        }
    }
</style>
