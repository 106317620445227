<template>
    <div id="detailsTable" class="table-container">
        <table class="table-container__table">
            <thead :class="{'sticky': duration > 12}">
                <tr>
                    <th>{{ $translate('month', 'Månad') }}</th>
                    <th>{{ $translate('calc_details_payment_instance', 'Betalningstillfälle') }}</th>
                    <th>{{ $translate('calc_details_debt_before_amortization', 'Skuld före amortering') }}</th>
                    <th>{{ $translate('calc_details_amortization', 'Amortering') }}</th>
                    <th>{{ $translate('calc_details_interest', 'Ränta') }}</th>
                    <th>{{ $translate('calc_details_to_pay', 'Att betala') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="repaymentPeriodData in repaymentDetails" :key="repaymentPeriodData.repaymentPeriod">
                    <td> {{ repaymentPeriodData.month }} </td>
                    <td> {{ repaymentPeriodData.repaymentPeriod }} </td>
                    <td> {{ repaymentPeriodData.debtBeforeAmortization }} </td>
                    <td> {{ repaymentPeriodData.amortization }} </td>
                    <td> {{ repaymentPeriodData.interest }} </td>
                    <td> {{ repaymentPeriodData.toPay }} </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        loanAmount: {
            type: Number,
            required: true
        },
        duration: {
            type: Number,
            required: true
        },
        interest: {
            type: Number,
            required: true
        },
    },
    computed: {
        repaymentDetails() {
            const getMonth = (repaymentPeriod) => {
                const monthNumber = (currentMonth + repaymentPeriod) % months.length;
                let month = months[monthNumber];
                if (repaymentPeriod === 1 || monthNumber === 0) {
                    month = month + ', ' + incrementingYear;
                    incrementingYear++;
                }
                return month;
            };

            const data = [];
            const amortization = this.loanAmount / this.duration;
            const currentMonth = new Date().getMonth();
            const months = [
                this.$translate('january', 'Jan'), 
                this.$translate('february', 'Feb'), 
                this.$translate('march', 'Mar'),
                this.$translate('april', 'Apr'),
                this.$translate('may', 'Maj'), 
                this.$translate('june', 'Jun'), 
                this.$translate('july', 'Jul'),
                this.$translate('august', 'Aug'),
                this.$translate('september', 'Sep'),
                this.$translate('october', 'Okt'), 
                this.$translate('november', 'Nov'), 
                this.$translate('december', 'Dec')
            ];

            let incrementingYear = new Date().getFullYear();
            let debtBeforeAmortization = this.loanAmount;

            for (let repaymentPeriod = 1; repaymentPeriod <= this.duration; repaymentPeriod++) {

                if (repaymentPeriod !== 1) {
                    debtBeforeAmortization -= amortization;
                }

                let interest = ((this.interest / 100) / 12) * debtBeforeAmortization;

                data.push({
                    month: getMonth(repaymentPeriod),
                    repaymentPeriod: repaymentPeriod,
                    debtBeforeAmortization: this.$format.currency(debtBeforeAmortization, 2),
                    amortization: this.$format.currency(amortization, 2),
                    interest: this.$format.currency(interest, 2),
                    toPay: this.$format.currency(amortization + interest, 2)
                });

            }
            return data;
        }
    },
};
</script>

<style lang="scss" scoped>
    .table-container {
        box-shadow: none;
        max-height: 75vh;
        border-radius: 0;
        @include device(pad) {
            max-height: unset;
            overflow: unset;
        }
        table {
            width: 100%;
            thead {
                font-weight: bold;
                th {
                    background: $blue;
                    color: $white;
                }
                &.sticky th{
                    position: sticky;
                    top: 0;
                    @media screen and (min-width: 1200px) {
                        top: $navHeight;
                    }
                }
            }
            th,td {
                text-align: left;
                @include spacing(padding, 2)
            }
        }
    }
</style>