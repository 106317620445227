<template>
    <li>
        <nuxt-link class="question-container" :to="$prismic.linkResolver(questionAnswer)">
            <span class="category">{{ category }}</span>
            <span class="question">{{ questionAnswer.data.question_title }}</span>

            <p class="answer">{{ questionAnswer.data.question }}</p>

            <span>
                <span>{{ $translate('by', 'av') }} </span>
                <span class="author-name"> {{ questionAnswer.data.author.data.name }}</span>
                - <span class="publication-date">{{ $format.date(questionAnswer.first_publication_date) }}</span>
            </span>
        </nuxt-link>
    </li>
</template>

<script>
export default {
    props: {
        questionAnswer: {
            type: Object,
            required: true
        }
    },
    computed: {
        category() {
            return this.questionAnswer.data.category.data.category;
        }
    }
};
</script>

<style lang="scss" scoped>
.question-container {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: $font;
    box-shadow: $bxs;
    padding: 16px;
    transition: 0.15s ease-in-out;
    &:hover { 
        box-shadow: 0 4px 10px -2px rgba(0,0,0,.3);
    }


    .question {
        @include font-size(24px);
        font-weight: bold;
        font-family: $fontHeader;
        display: block;
        margin-bottom: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        @include device(pad) {
            -webkit-line-clamp: 2;
        }
    }
    .answer {
        margin-bottom: 6px;
            @include device(pad) {
            -webkit-line-clamp: 2;
        }
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
    .author-name {
        color: $green;
    }
    .category {
        color: $green;
        font-weight: bold;
    }
}
</style>