<template>
    <Section width="sm">
        <h2 class="section-header">{{ $prismic.asText(data.primary.h2) }}</h2>
        <div v-html="$prismic.asHtml(data.primary.teaser)" />

        <ul
            class="list"
            :class="data.primary.layout"
        >
            <li
                v-for="(box, index) in data.items"
                :key="index"
                class="list-item"
            >
                <div
                    v-if="showImage"
                    class="list-item__image"
                >
                    <PrismicImage
                        :img="box.image"
                        w="100"
                        h="auto"
                    />
                </div>

                <div class="list-item__container">
                    <div
                        v-if="box.header"
                        class="list-item__header"
                    >
                        {{ box.header }}
                    </div>

                    <div
                        class="list-item__content rich-text"
                        v-html="$prismic.asHtml(box.content)"
                    />

                    <div
                        v-if="box.link.id"
                        class="list-item__button"
                    >
                        <ButtonNuxt
                            :link="box.link"
                            background="green"
                            size="md"
                            :text="box.link_text"
                        />
                    </div>
                </div>
            </li>
        </ul>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        showImage() {
            if (this.data.primary.layout === 'content-with-images') return true;
        }
    }
};
</script>

<style lang="scss" scoped>
.list {
    @include flex(center,start);
    @include spacing(margin, 10, top);

    &-item {
        @include grid(1, 10px);
        text-align: center;
        @include spacing(padding, 4);
        width: 100%;

        @include device(pad) {
            @include grid(1, 20px);
            flex: 1;
            width: auto;
            min-width: 33.3%;
        }

        &__container {
            @include grid(1, 10px);
        }

        &__header {
            @include font-size(16px);
            font-family: $fontHeader;
            font-weight: bold;
        }

        &__content::v-deep {
            p { margin: 0; }
        }

        &__button {
            width: 100%;
            padding-top: 20px;
            margin-top: 20px;
            @include border(top, 1px, #e6e6e6);
            @include flex(start);
        }
    }
}

// Variations
.list.content-with-images {
    @include grid(1, 40px);

    .list-item {
        @include grid(1, 30px);
        text-align: left;
        @include spacing(padding, 4);
        width: 100%;

        @include device(pad) {
            @include flex(start,start);

        }

        &__image { margin-right: 60px; }

        &__container { flex: 1; }
    }
}
</style>
