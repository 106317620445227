<template>
    <div class="question-options__select">
        <div class="inner">
            <label v-if="label">{{ label }}</label>

            <div class="select-container">
                <select v-model="year" name="date of birth" :placeholder="$translate('year', 'År')">
                    <option>{{ $translate('year', 'År') }}</option>
                    <option
                        v-for="(yearOption, index) in years"
                        :key="index"
                        :value="yearOption"
                    >
                        {{ yearOption }}
                    </option>
                </select>

                <i class="fas fa-chevron-down" />
            </div>

            <span v-if="description">{{ description }}</span>
        </div>

        <div
            v-if="error"
            class="question-error"
        >
            {{ error }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        description: {
            type: String,
            required: false,
            default: String
        },
        answerKey: {
            type: String,
            required: true,
            default: String
        },
        label: {
            type: String,
            required: false,
            default: String
        },
        onlyNumbers: {
            type: Boolean,
            required: false,
            default: false
        },
        error: {
            type: String,
            required: false,
            default: String
        }
    },
    data() {
        return {
            years: [],
            year: ''
        };
    },
    watch: {
        year() {
            this.emit();
        }
    },
    mounted() {
        this.getYears();
    },
    methods: {
        emit() {
            this.$emit('set', this.answerKey, this.year);
            this.$emit('resetError', this.answerKey);
        },

        allowOnlyNumbers ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if (keyCode < 48 || keyCode > 57 && this.onlyNumbers) {
                $event.preventDefault();
            }
        },

        getYears() {
            const startYear = 1920;
            const endYear = (new Date().getFullYear()) - 18;

            for (let year = startYear; year <= endYear; year++) {
                this.years.push(year);
            }
        }
    }
};
</script>

<style lang="scss" scoped>

    .question-options__select {
        @include flex;

        select {
            @include spacing(padding, 3);
            border: 2px solid #e6e6e6;
            width: 300px;
            max-width: 100%;
            font-size: 16px;
            cursor: pointer;
            outline: none;

            &:focus { border-color: $green; }
        }

        .select-container {
            position: relative;
        }

        i {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
        }

        .inner {
            width: 100%;
            max-width: 300px;
        }

        label {
            display: block;
            text-align: left;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        span {
            display: block;
            margin-top: 15px;
            font-size: 14px;
        }

        .question-error {
            color: #b1403d;
            background: rgba(177,64,61,.2);
            @include spacing(padding, 2);
            margin-top: 10px;
            font-weight: bold;
            font-size: 14px;
            width: 100%;
        }
    }
</style>
