<template>
    <Section
        :width="data.primary.width"
        :class="'bg-' + data.primary.background"
    >
        <div
            class="container rich-text"
            v-html="$prismic.asHtml(data.primary.content)"
        />

        <div
            v-if="links.length"
            class="container"
        >
            <nuxt-link
                v-for="(iterator, index) in links"
                :key="index"
                :to="$prismic.linkResolver(iterator.link)"
                class="container-link"
            >
                <PrismicImage
                    :img="iterator.image"
                    :alt="iterator.link.slug"
                    w="60px"
                    h="30px"
                />
            </nuxt-link>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        links() {
            return this.data.items.filter(item => item.link.id);
        }
    },
};
</script>

<style lang="scss" scoped>
section::v-deep {

    .container {
        &:first-child {
            h2,
            h3,
            h4,
            h5 {
                padding: 0;
                color: $white;
                text-align: center;
                border: 0;
            }

            p {
                color: $white;
                text-align: center;
                &:first-child { margin-top: 0; }
                &:last-child { margin-bottom: 0; }
            }
            ul, ol {
                color: $white;
            }
            ol li::after {
                color: $font;
            }
        }

        &:nth-child(2) {
            margin-top: 20px;
            padding-top: 10px;
            width: 100%;
            @include flex;
            @include border(top, 1px, rgba(255,255,255,.5));

            .container-link {
                @include size(60px);
                @include flex;
                padding: 10px;
                background: $white;
                @include border-radius(100px);
                margin: 10px;
                overflow: hidden;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}
</style>
