<template>
    <Section width="lg" :class="{'increased-padding': hasH1}">
        <div class="header">
            <div v-if="$validateText(data.primary.header)" v-html="$prismic.asHtml(data.primary.header)" />
            <search-lenders 
                :added-lender-ids="lenderIds" 
                @toggle="toggleLender" 
            />
        </div>
        <compare-table 
            :lender-ids="lenderIds" 
            @removeLender="removeLender"
        />
    </Section>
</template>

<script>
import CompareTable from './CompareTable.vue';
import SearchLenders from './SearchLenders.vue';
export default {
    components: {
        CompareTable,
        SearchLenders
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        lenderIds() {
            const query = this.$route.query.lender;
            if (typeof query === 'string') {
                return [query];
            }
            return query || [];
        },
        hasH1() {
            return this.data.primary.header?.[0]?.type === 'heading1';
        }
    },
    mounted() {
        this.$store.commit('CLEAR_LENDERS_TO_COMPARE');
    },
    methods: {
        toggleLender(id) {
            if (this.lenderIds.includes(id)) {
                this.removeLender(id);
            } 
            else {
                this.addLender(id);
            }
        },
        addLender(id) {
            const updatedQuery = [...this.lenderIds, id];
            this.$router.replace({
                query: {
                    lender: updatedQuery
                }
            });
        },
        removeLender(id) {
            const updatedQuery = this.lenderIds
                .filter(lenderId => lenderId !== id);
            this.$router.replace({
                query: {
                    lender: updatedQuery
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    .section {
        &.increased-padding {
            padding-top: 40px;
            @include device(pad) {
                padding-top: 60px;
            }
            @media screen and (min-width: 1200px) {
                padding-top: 140px;
            }
        }
        ::v-deep .section-inner {
            width: 100%;
            z-index: unset;
        }
    }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
    }
</style>