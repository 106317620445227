<template>
    <Section
        class="banner bg-cream"
        width="lg"
    >
        <div class="container">
            <div>
                <h1 class="banner-inner__title">{{ $prismic.asText(data.primary.h1) }}</h1>
                <div
                    class="banner-inner__teaser list-icon list-checkmark"
                    v-html="$prismic.asHtml(data.primary.teaser)"
                />
            </div>
        </div>

        <div class="container">
            <span class="container-header">{{ data.primary.links_container_header }}</span>

            <div class="container-grid">
                <Link
                    v-for="(link, index) in links"
                    :key="index"
                    :link="'/laneguiden/' + link.reroute"
                    :icon="link.icon"
                    :text="link.text"
                />
            </div>
        </div>
    </Section>
</template>
<script>
import Link from '@/components/slices/loan-guide/LoanGuideLink.vue';
export default {
    components: { Link },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            links: [
                {
                    icon: 'fa-down-left-and-up-right-to-center',
                    text: this.$translate('lg_gather_loans', 'Samla lån'),
                    reroute: '?for=debt_consolidation'
                },
                {
                    icon: 'fa-shopping-cart',
                    text: this.$translate('lg_shopping', 'Shopping'),
                    reroute: '?for=shopping'
                },
                {
                    icon: 'fa-car',
                    text: this.$translate('lg_car_purchase', 'Bilköp'),
                    reroute: '?for=car_purchase'
                },
                {
                    icon: 'fa-plane',
                    text: this.$translate('lg_travel', 'Resa'),
                    reroute: '?for=travel'
                },
                {
                    icon: 'fa-paint-brush',
                    text: this.$translate('lg_renovation', 'Renovering'),
                    reroute: '?for=renovation'
                },
                {
                    icon: 'fa-question',
                    text: this.$translate('lg_other', 'Annat'),
                    reroute: '?for=other'
                }
            ]
        };
    }
};
</script>
<style lang="scss" scoped>
    .banner::v-deep {

        &:after {
            left: 15%;
            @include size(300px);

            @include device(pad) {
                @include size(400px);
            }

            @include device(desktop) {
                left: 15%;
            }
        }

        .section-inner {
            @include grid(1, 40px);

            @include device(pad) {
                @include grid(2, 40px);
            }

            .container {
                ul {
                    @include grid(1, 20px);

                    li {
                        @include font-size(18px);

                        &:after {
                            border-radius: 100%;
                            color: $green;
                            font-size: 24px;
                        }
                    }
                }

                &:first-child { @include flex; }

                &:last-child {
                    @include container($white);
                    @include spacing(padding, 8);

                    .container-header {
                        font-family: $fontHeader;
                        @include font-size(22px);
                        @include spacing(margin, 6, bottom);
                        display: block;
                        text-align: center;
                    }

                    .container-grid {
                        width: 100%;

                        @include grid(2, 10px);

                        @include device(desktop) {
                            @include grid(3, 10px);
                        }
                    }
                }
            }
        }
    }
</style>
