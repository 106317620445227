<template>
    <div class="list-item">
        <PrismicImage
            w="75"
            h="75"
            :img="author.data.image"
            :alt="author.data.name"
        />

        <div class="list-item__inner">
            <div class="inner-header">
                <nuxt-link :to="$prismic.linkResolver(author)">{{ author.data.name }}</nuxt-link>
                <p> - {{ author.data.role }}</p>
            </div>
            <p class="bio">{{ author.data.bio }}</p>

            <div
                v-if="showArticles"
                class="inner-list"
            >
                <span>
                    {{ 
                        $translate('author_latest_articles_of_author', 'Senaste artiklarna av {author}')
                            .replace('{author}', author.data.name) 
                    }} 
                </span>
                <ul>
                    <li
                        v-for="(article, index) in articles"
                        :key="index"
                    >
                        <nuxt-link :to="$prismic.linkResolver(article)">{{ article.data.title }}</nuxt-link>
                    </li>
                </ul>
            </div>

            <div v-if="showSources" class="inner-list">
                <span>{{ $translate('author_sources', 'Källor') }}</span>
                <ul>
                    <li v-for="source in sources" :key="source">
                        <a 
                            :href="source" 
                            target="_blank" 
                            rel="nofollow noopener noreferrer"
                        >
                            {{ source }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { replace } from '@swegaming-ab/nuxtjs-helpers';
export default {
    props: {
        id: {
            type: String,
            required: true
        },
        showArticles: {
            type: Boolean,
            required: false,
            default: true
        },
        sources: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            articles: []
        };
    },
    async fetch() {
        const response = await this.$prismic.api.query(
            [
                this.$prismic.predicates.at('document.type', 'post'),
                this.$prismic.predicates.at('my.post.author', this.id) 
            ],
            {
                lang: process.env.PRISMIC_LOCALE,
                orderings: '[ document.first_publication_date desc ]',
                pageSize: 3
            }
        );
        this.articles = replace(response.results);
    },
    fetchKey() {
        return `3-author-${this.id}-posts`;
    },
    computed: {
        author() {
            return this.$store.state.authors[ this.id ];
        },
        showSources() {
            return this.sources.length > 0;
        }
    }
};
</script>

<style lang="scss" scoped>
    .list-item {
        padding-bottom: 2rem;
        width: 100%;
        @include flex(center, start);

        p { @include font-size(14px); }

        img {
            box-shadow: 0 2px 5px -2px rgba(0,0,0,.14);
            border-radius: 3px;
        }

        &__inner {
            flex: 1;
            @include grid(1, 20px);
            margin-left: 20px;
            width: 100%;

            .inner-header {
                @include flex(start);

                a {
                    font-size: 20px;
                    color: $green;
                    text-decoration: none;
                    font-family: $fontHeader;
                }

                p {
                    font-size: 16px;
                    margin-left: 10px;
                }
            }

            .inner-list {
                span {
                    font-weight: bold;
                    margin-bottom: 10px;
                    display: block;
                }

                ul {
                    @include grid(1, 10px);

                    li {
                        font-size: 14px;

                        a {
                            color: $green;
                            text-decoration: none;
                            word-break: break-word;
                        }

                        a:hover { text-decoration: underline; }
                    }
                }
            }

        }
    }
</style>
