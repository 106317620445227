export default {
    methods: {
        getAcceptsPaymentRemarksCount(connectedBanks) {
            return connectedBanks.filter(bank => bank.model.acceptsPaymentRemarks).length;
        },
        getLowInterestCount(connectedBanks) {
            return connectedBanks.filter(bank => bank.model.minInterestPossible <= 6).length;
        },
        getHighLoanAmountCount(connectedBanks) {
            return connectedBanks.filter(bank => bank.model.maxAmount <= 500000).length;
        },
    }
};