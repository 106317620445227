<template>
    <Section 
        v-if="reviews.length" 
        width="md"
        class="latest-user-reviews"    
    >
        <div class="reviews-container">
            <div class="header-wrapper">
                <div>
                    <div 
                        v-if="$validateText(data.primary.header)"
                        class="rich-text reviews-container-header"
                        v-html="$prismic.asHtml(data.primary.header)"
                    />
                    <div 
                        v-if="$validateText(data.primary.teaser)"
                        class="rich-text reviews-container-teaser"
                        v-html="$prismic.asHtml(data.primary.teaser)"
                    />
                </div>
                <div class="header-image-wrapper">
                    <prismic-image
                        :img="data.primary.image"
                        w="425"
                    />
                </div>
            </div>
            <div class="reviews-wrapper">
                <div class="reviews-headers">
                    <span>
                        {{ $translate('lender', 'Långivare') }}
                    </span>
                    <span>
                        {{ $translate('review_by', 'Omdöme av') }}
                    </span>
                    <span>
                        {{ $translate('rating', 'Betyg') }}
                    </span>
                </div>
                <latest-user-review 
                    v-for="(review, index) in reviews"
                    :key="index"
                    :review="review"
                />
            </div>
        </div>
    </Section>
</template>
<script>
import LatestUserReview from './LatestUserReview.vue';
export default {
    components: { LatestUserReview },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        reviews() {
            return this.$store.state.latestLenderUserRatings;
        }
    }
};
</script>
<style lang='scss' scoped>
    .latest-user-reviews::v-deep {
        background-color: $cream;
        .header-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            margin-bottom: 2rem;
    
            @include device(pad) {
                margin-bottom: 0rem;
            }

            .header-image-wrapper {
                display: flex;
                align-items: center;
                flex-grow: 1;
                justify-content: center;
                @include device(pad) {
                    justify-content: unset;
                }
            }
    
        }
        .reviews-container {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: auto;
            gap: 1rem;

            @include device(pad) {
                grid-template-columns: repeat(2, 1fr);
            }

            img {
                max-width: 100%;
                object-fit: contain;
            }

            .reviews-container-header {
                h2, h3 {
                    margin-bottom: 1rem;
                    padding-bottom: 0;
                    border-bottom: 0;
                }
            }
            .reviews-container-teaser p:first-child {
                margin-top: 0 !important; 
            }

            .reviews-wrapper {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .reviews-headers {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-weight: bold;
                    padding: 0 0.5rem;

                    @include device(pad) {
                        padding: 0 1rem;
                    }
                }

                .reviews-body {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: $white;
                    @include border-radius(3px);
                    box-shadow: $bxs;
                    padding: 0.5rem;
                    text-decoration: none;
                    color: $font;
                    transition: 0.1s ease-in-out;
                    flex-wrap: wrap;

                    @include device(pad) {
                        padding: 1rem;
                    }
            
                    &:hover {
                        transform: scale(1.01);
                    }

                    .body-rater-name {
                        text-align: center;
                    }
                    .body-rating {
                        display: flex;

                        .gold-star {
                            color: $yellow;
                        }
                        .gray-star {
                            color: grey;
                            opacity: 0.25;
                        }
                    }
                }
            }
        }
    }
</style>