<template>
    <li>
        <nuxt-link :to="$prismic.linkResolver(lender)">
            <PrismicImage
                :img="lender.data.image"
                :alt="lender.data.title"
                w="100"
                h="50"
            />

            <div class="container-inner">
                <span>
                    {{ 
                        $translate('urf_review_by_name', 'Omdöme av {name}')
                            .replace('{name}', data.rater_name) 
                    }}
                </span>
            </div>

            <div class="container-inner">
                <span>{{ $translate('rating', 'Betyg') }}</span>
                <div class="rating">
                    <i 
                        v-for="rating in data.rating_score" 
                        :key="`rating_${rating}`"  
                        class="fa-solid fa-star gold-star" 
                    />
                    <i 
                        v-for="(star, starIndex) in maxStars - data.rating_score"
                        :key="starIndex" 
                        class="fa-solid fa-star gray-star" 
                    />
                </div>
            </div>
        </nuxt-link>
    </li>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            maxStars: 5
        }
    },
    computed: {
        lender() {
            return this.$store.state.lenders[this.data.lenderId];
        }
    }
}
</script>

<style lang="scss" scoped>
a {
    @include grid(1, 20px);

    @include device(pad) {
        @include grid(3, 20px);
    }

    img { 
        margin: auto; 
        object-fit: contain;
    }

    .container-inner {
        @include device(mobile) {
            @include grid(1, 10px);
        }
        .rating {
            @include flex;

            .gold-star {
                color: $yellow;
            }
            .gray-star {
                color: grey;
                opacity: 0.25;
            }
        }
        @include grid(1, 0px);

        span { @include flex; }

        i {
            font-size: 16px;
        }
    }
}
</style>
