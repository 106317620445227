<template>
    <button
        v-if="data"
        type="button"
        name="button"
        :class="{ active: active === data.answerValue }"
        @click="emit(data.answerKey, data.answerValue)"
    >
        <div
            v-if="data.icon"
            :class="data.icon"
            class="fa-solid guide-link__icon"
        />
        <span class="guide-link__text">{{ data.text }}</span>
    </button>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: false,
            default: () => {}
        },
        active: {
            type: String,
            required: false,
            default: String
        }
    },
    methods: {
        emit(answerKey, anserValue) {
            this.$emit('set', answerKey, anserValue);
            this.$emit('resetError', answerKey);
        }
    }
};
</script>

<style lang="scss" scoped>
    button {
        @include spacing(padding, 4);
        text-align: center;
        font-size: 16px;
        background: #efefef;
        color: $font;
        @include grid(1, 20px);

        .guide-link__icon {
            font-size: 22px;
            margin: auto;
        }

        .guide-link__text {
            font-family: $fontHeader;
        }

        &:hover {
            background: $green;
            color: #fff;
        }
    }

    button.active {
        background: $green;
        color: #fff;
    }
</style>
