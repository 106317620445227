<template>
    <div
        class="slider"
        :class="{ sliderDisabled: interestType === 'fixed' }"
    >
        <div class="slider-inner">
            <label>{{ $translate('interest', 'Ränta') }}</label>
            <range-slider
                v-model="sliderValue"
                class="slider"
                :min="minValue"
                :max="maxValue"
                :step="0.04"
            />
        </div>

        <div
            v-if="interestType === 'fixed'"
            class="slider-inner slider-amount"
        >
            <span>{{ interest }} %</span>
            <span>{{ $translate('cannot_be_adjusted', 'Kan inte justeras') }}</span>
        </div>

        <div v-else class="slider-amount">{{ formattedValue }}</div>
    </div>
</template>

<script>
import RangeSlider from 'vue-range-slider';
import 'vue-range-slider/dist/vue-range-slider.css';
export default {
    components: { RangeSlider },
    props: {
        maxValue: {
            type: Number,
            required: false,
            default: Number
        },
        minValue: {
            type: Number,
            required: false,
            default: Number
        },
        startValue: {
            type: Number,
            required: false,
            default: Number
        },
        interestType: {
            type: String,
            required: false,
            default: String
        },
        interest: {
            type: Number,
            required: false,
            default: Number
        }
    },
    data() {
        return {
            sliderValue: undefined
        };
    },
    computed: {
        formattedValue() {
            return this.$format.number(this.sliderValue) + ' %';
        }
    },
    watch: {
        sliderValue() {
            if (this.divide) this.$emit('emit', this.sliderValue / this.divide, 'sliderInterest');
            else this.$emit('emit', this.sliderValue, 'sliderInterest');
        }
    },
    created() {
        this.sliderValue = this.interest ? this.interest : this.startValue;
    }
};
</script>

<style lang="scss">
.sliderDisabled {
    user-select: none!important;
    pointer-events: none!important;

    .range-slider-knob { display: none; }
}
</style>
