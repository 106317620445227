<template>
    <div>
        <div class="input-slider-container">
            <label>{{ label }}</label>
            <div class="input-container" @click="$refs.input.focus()">
                <input 
                    ref="input"
                    v-model="inputValue"
                    :size="inputValue.length || 1"
                    type="text"
                    @change="updateSliderValue"
                >
                {{ suffix }}
            </div>
            <range-slider
                v-model="sliderValue"
                class="slider"
                :min="minValue"
                :max="maxValue"
                :step="increment"
            />
        </div>
        <div class="slider-label-container">
            <span>{{ `${formatNumber(minValue)} ${suffix}` }}</span>
            <span>{{ `${formatNumber(maxValue)} ${suffix}` }}</span>
        </div>
    </div>
</template>

<script>
import RangeSlider from 'vue-range-slider';
import 'vue-range-slider/dist/vue-range-slider.css';
export default {
    components: { RangeSlider },
    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },
        suffix: {
            type: String,
            required: false,
            default: ''
        },
        increment: {
            type: Number,
            required: false,
            default: 1
        },
        maxValue: {
            type: Number,
            required: true,
        },
        minValue: {
            type: Number,
            required: false,
            default: 0
        },
        startValue: {
            type: Number,
            required: false,
            default: null
        },
        calcKey: {
            type: String,
            required: true,
        },
        maxDecimals: {
            type: Number,
            required: false,
            default: 0
        },
        maxFallback: {
            type: Number,
            required: false,
            default: null
        },
    },
    data() {
        const startValue = this.startValue || this.minValue;
        return {
            sliderValue: startValue,
            inputValue: this.$format.number(startValue),
            maxFallbackValue: this.maxFallback || this.maxValue
        };
    },
    watch: {
        sliderValue() {
            this.inputValue = `${this.formatNumber(this.sliderValue)}`;
            this.$emit('updateSliderValue', {key: this.calcKey, value: this.sliderValue});
        },
    },
    methods: {
        parseNumber(string) {
            const regex = /[^0-9.,]/g;
            string = string
                .replace(regex, '')
                .replace(',', '.');
            
            if (string === '') {
                string = '0';
            }

            if (string.includes('.') && this.maxDecimals > 0) {
                return parseFloat(string);
            }

            return parseInt(string);
        },
        updateSliderValue() {
            let parsedInput = this.parseNumber(this.inputValue);
            if (parsedInput > this.maxFallbackValue) {
                parsedInput = this.maxFallbackValue;
            }
            if (parsedInput === this.sliderValue) {
                this.inputValue = `${this.formatNumber(parsedInput)}`;
                return;
            }
            this.sliderValue = parsedInput;
        },
        formatNumber(number) {
            let amountOfDecimals = number.toString().split('.')[1]?.length || 0;
            amountOfDecimals > this.maxDecimals && (amountOfDecimals = this.maxDecimals);
            return this.$format.number(number, amountOfDecimals);
        }
    },
};
</script>

<style lang="scss" scoped>
    .input-slider-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        row-gap: 10px;
        margin-top: 20px;
        .slider {
            display: flex;
            width: 100%;
            padding: 0;
            ::v-deep {
                .range-slider-fill {
                    background: $green;
                }
            }
        }
        label {
            padding-right: 10px;
            display: flex;
            align-items: center;
        }
        .slider-amount {
            padding-left: 10px;
        }
    }
    .input-container {
        padding-bottom: 3px;
        border-bottom: 2px solid $green;
        display: flex;
        gap: 3px;
        align-items: center;
        cursor: text;
        input {
            width: 100%;
            all: unset;
            text-align: right;
        }
    }
    .slider-label-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 12px;
        margin-top: 5px;
    }
</style>
