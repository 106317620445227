<template>
    <div>
        <div class="header-section">
            <h3>
                {{ 
                    $translate('ctl_lender_reviews_h3', '{lender} recensioner') 
                        .replace('{lender}', lender.data.title)
                }}
            </h3>
            <div>
                <span>
                    {{ lender.model._ratings.average_score.toFixed(1) }}
                    <i class="fa-solid fa-star" />
                </span>
                ({{ 
                    $translate('rating_amount', '{amount} recensioner')
                        .replace('{amount}', lender.model._ratings.count) 
                }})
            </div>
        </div>
        <div v-if="bestRatings.length">
            <ul class="ratings-list">
                <li
                    v-for="(rating, index) in bestRatings"
                    :key="index"
                    class="rating-item"
                >
                    <div class="rating-author">
                        {{ rating.rater_name }}
                    </div>
                    <div class="rating-stars">
                        <i
                            v-for="i in 5"
                            :key="i"
                            :class="{ 'green': i <= rating.rating_score }"
                            class="fa-solid fa-star"
                        />
                    </div>
                    <div class="rating-text">
                        {{ rating.rating_body }}
                    </div>
                </li>
            </ul>
            <nuxt-link
                :to="$prismic.linkResolver(lender)"
                class="btn btn-green btn-md review-link"
            >
                {{ $translate('cta_read_review', 'Läs recension') }}
            </nuxt-link>
        </div>
        <div v-else>
            {{ $translate('ctl_no_reviews', 'Inga recensioner än!') }}
        </div>
    </div>
</template>

<script>
import { orderBy } from 'lodash';
export default {
    props: {
        lender: {
            type: Object,
            required: true
        }
    },
    computed: {
        bestRatings() {
            return orderBy(this.lender.model._ratings.data, 'posted_at', 'desc').slice(0, 3);
        }
    }
};
</script>

<style lang="scss" scoped>
    .header-section {
        margin-bottom: 30px;
        h3 {
            margin-bottom: 10px;
        }
    }
    .ratings-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .rating-item {
            padding: 15px;
            box-shadow: $bxs;
            border-radius: 3px;
            background: $white;
            .rating-author {
                font-weight: bold;
                font-size: 18px;
                &:first-letter{
                    text-transform: capitalize;
                }
            }
            .rating-text {
                display: -webkit-box;
                -webkit-box-orient: vertical;  
                overflow: hidden;
                -webkit-line-clamp: 9;
                @include device(pad) {
                    -webkit-line-clamp: 3;
                }
            }
            .rating-stars {
                color: #999;
            }
        }
    }
    .review-link {
        display: inline-block; 
        margin-top: 20px;
    }
</style>