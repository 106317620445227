<template>
    <Section v-if="quotesItems.length" :width="sectionWidth">
        <div 
            v-if="$validateText(data.primary.text)"
            class="rich-text header-text"
            v-html="$prismic.asHtml(data.primary.text)"
        />
        <div class="quotes-wrapper">
            <div 
                v-for="(item, index) in quotesItems" 
                :key="index"
                class="quote-body"
            >
                <span class="quote-by">
                    {{ item.quote_by }}
                </span>
                <div v-if="item.rating !== 'hide'" class="rating">
                    <i 
                        v-for="(rating, ratingIndex) in 5"
                        :key="ratingIndex"
                        class="fas fa-star"
                        :class="ratingIndex + 1 <= item.rating ? 'gold-star' : 'gray-star'"
                    />
                </div>
                <div 
                    class="rich-text quote-text"
                    v-html="$prismic.asHtml(item.quote_text)"
                />
            </div>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        quotesItems() {
            return this.data.items
                .filter(item => this.$validateText(item.quote_text));
        },
        sectionWidth() {
            return this.$validateText(this.data.primary.section_width) ? this.data.primary.section_width : 'md';
        }
    }
};
</script>

<style lang="scss" scoped>
    .header-text {
        margin-bottom: 2rem;
    }
    .quotes-wrapper::v-deep {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 2rem;
            @include device(pad) {
                grid-template-columns: repeat(2, 1fr);
            }

            .quote-body {
                display: flex;
                flex-direction: column;
                gap: 0.75rem;
                background-color: $cream;
                padding: 1.25rem;
                @include border-radius(25px);
                position: relative;
                &:before {
                    content: "";
                    width: 0px;
                    height: 0px;
                    position: absolute;
                    border-left: 12px solid $cream;
                    border-right: 12px solid transparent;
                    border-top: 12px solid $cream;
                    border-bottom: 12px solid transparent;
                    left: 19px;
                    bottom: -20px;
                }

                .rating {
                    margin-bottom: 0.25rem;
                }
            
                .gold-star {
                    color: $yellow;
                }
                .gray-star {
                    color: grey;
                    opacity: 0.25;
                }

                .quote-by {
                    font-weight: bold;
                }
                .quote-text {
                    font-style: italic;
                    p:first-child {
                        margin-top: 0;
                    }
                    p:last-child {
                        margin-bottom: 0;
                    }
                }

                &.cream {
                    background-color: $cream;
                }
                
            }
        }
</style>