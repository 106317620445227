<template>
    <div
        class="highlight-box rich-text"
        :class="data.border_color"
        v-html="$prismic.asHtml(data.content)"
    />
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
.highlight-box::v-deep {
    margin: 30px 0;
    border-left: 8px solid;
    color: $font;
    background: rgba(0,0,0,.02);
    width: 100%;
    @include spacing(padding, 6);
    @include border-radius(3px);
    box-shadow: $bxs;

    h2 {
        color: $font;
        border: 0;
        padding: 0;
    }

    p:first-child { margin-top: 0!important; }

    p:last-child { margin-bottom: 0!important; }
}

.highlight-box.blue { border-color: $blue; }

.highlight-box.red { border-color: $red; }

.highlight-box.green { border-color: $green; }
</style>
