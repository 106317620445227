<template v-if="lender">
    <div>
        <div class="lender-container">
            <div class="lender-info-container">
                <nuxt-link
                    :to="$prismic.linkResolver(lender)"
                >
                    <PrismicImage
                        :img="lender.data.image"
                        w="100"
                        h="50"
                        :alt="lender.data.title"
                        class="lender-logo"
                    />
                </nuxt-link>
                <ul class="loan-info-list">
                    <li>
                        <span>{{ $translate('amount', 'Lånebelopp') }}</span>
                        <span>{{ model.amountString }}</span>
                    </li>
                    <li>
                        <span>{{ $translate('duration', 'Löptid') }}</span>
                        <span>{{ model.getDurationString() }}</span>
                    </li>
                    <li>
                        <span>{{ $translate('effective_interest', 'Ränta') }}</span>
                        <span>{{ model.effectiveInterestString }}</span>
                    </li>
                </ul>
                <div class="btn-md apply-button">
                    <ButtonTarget
                        :link="lender"
                        :text="$translate('cta_apply_direct', 'Ansök direkt')"
                        size="md"
                        background="green"
                        placement="calculator"
                    />
                    <div v-if="$isNorway() && lender.data.redirect_enabled" class="compliance-string">Annonslenke</div>
                </div>
            </div>
            <high-cost-warning
                v-if="model.isHighCostCredit"
                class="high-cost"
            />
        </div>
        <div class="interest-example">{{ model.complianceExample }}</div>
    </div>
</template>

<script>
import HighCostWarning from '@/components/HighCostWarning.vue';

export default {
    components: {
        HighCostWarning
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    computed: {
        lender() {
            return this.$store.state.lenders[this.id];
        },
        model() {
            return new this.$models.Loan(this.lender, this.$store);
        },
    }
};
</script>

<style lang="scss" scoped>
.lender-container {
    width: 100%;
    height: 100%;
    box-shadow: $bxs;
    @include border-radius(3px);

    .lender-info-container {
        @include flex(around);
        flex-direction: column;
        gap: 0.5rem;
        @include spacing(padding, 4);

        @include device(pad) {
            flex-direction: row;
        }

        .lender-logo {
            object-fit: contain;
        }

        .loan-info-list {
            width: 100%;
            @include grid(1, 0);
            flex: 1;
            
            @include device(pad) {
                @include spacing(padding, 2, (left, right));
            }
            @include device(desktop) {
                @include grid(3, 1)
            }

            li {
                width: 100%;
                max-width: 340px;
                margin: 0 auto;
                @include spacing(padding, 1, (top, bottom));
                @include flex();
                flex-wrap: nowrap;
                @include border(bottom);

                @include device(desktop) {
                    flex-direction: column;
                    border: 0;
                    padding: 0;
                }

                &:last-child { border: 0; }

                span {
                    width: 100%;
                    text-align:  left;
                    @include device(desktop) {
                            text-align: center;
                        }

                    &:last-child::v-deep {
                        text-align: right;
                        font-weight: bold;
                        white-space: nowrap;

                        @include device(desktop) {
                            text-align: center;
                        }

                        i { margin-right: 10px; }
                    }
                }
            }
        }
        .apply-button {
            position: relative;
            padding-left: 0;
            padding-right: 0;

            @include flex();
            flex-direction: column;
            
            .compliance-string {
                margin-top: 5px;
            }
        }
    }
    .high-cost {
        @include spacing(padding, 2, (top, bottom));
    }
}
.interest-example {
    margin-top: 10px;
    @include font-size(12px);
    font-style: italic;
    opacity: 0.6;
}

</style>