<template>
    <Section
        class="list"
        width="md"
    >
        <h2
            v-if="$helpers.filterEmptyElements(data.primary.h2)"
            class="section-header"
        >
            {{ $prismic.asText(data.primary.h2) }}
        </h2>

        <ul>
            <Post
                v-for="post in posts"
                :key="post.id"
                :post="post"
                :show-author="true"
            />
        </ul>
    </Section>
</template>

<script>
import Post from '@/components/slices/lists/list-items/ListItemPost.vue';
import { queryAll, replace } from '@swegaming-ab/nuxtjs-helpers';
export default {
    components: { Post },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            posts: []
        };
    },
    async fetch() {
        this.posts = replace(await queryAll(this.$prismic.api, 
            [
                this.$prismic.predicates.at('document.type', 'post'),
                this.$prismic.predicates.at('my.post.post_category', this.postCategory)
            ],
            { 
                lang: process.env.PRISMIC_LOCALE,
                orderings: '[ document.first_publication_date desc ]' 
            }
        ));
    },
    computed: {
        postCategory() {
            return this.data.primary.post_category;
        },
    }
};
</script>
<style lang="scss" scoped>
    ul {
        @include grid(1, 40px);

        @include device(pad) {
            @include grid(2, 40px);
        }

        @include device(desktop) {
            @include grid(3, 40px);
        }
    }
</style>
