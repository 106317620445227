<template>
    <Section
        width="md"
        class="bg-blue"
    >
        <div class="container">
            <h2>{{ $prismic.asText(data.primary.latest_customer_reviews) }}</h2>
            <ul>
                <Review
                    v-for="(review, index) in reviews"
                    :key="index"
                    :data="review"
                />
            </ul>
        </div>

        <div class="container">
            <h2>{{ $prismic.asText(data.primary.latest_news) }}</h2>
            <ul>
                <NewsPost
                    v-for="(newsPost, index) in news"
                    :key="index"
                    :data="newsPost"
                />
            </ul>
        </div>
    </Section>
</template>

<script>
import Review from './LatestReview.vue';
import NewsPost from './LatestNewsPost.vue';
import { replace } from '@swegaming-ab/nuxtjs-helpers';
export default {
    components: { Review, NewsPost },
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            news: []
        };
    },
    async fetch() {
        const response = await this.$prismic.api.query(
            [
                this.$prismic.predicates.at('document.type', 'post'),
                this.$prismic.predicates.at('my.post.post_category', 'nyheter')  
            ],
            {
                lang: process.env.PRISMIC_LOCALE,
                orderings: '[ document.first_publication_date desc ]',
                pageSize: 3
            }
        );
        this.news = replace(response.results);
    },
    fetchKey: '3-latest-news',
    computed: {
        reviews() {
            return this.$store.state.latestLenderUserRatings.slice(0, 3);
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        .section-inner {
            @include flex;

            .container {
                width: 100%;
                text-align: center;
                @include spacing(padding,2);

                @include device(pad) {
                    width: auto;
                    flex: 1;
                    max-width: 50%;
                }

                &:last-child {
                    @include device(mobile) {
                        @include spacing(margin,6,top);
                    }
                }

                h2 {
                    color: $white;
                    @include spacing(margin, 6, bottom);
                    @include font-size(24px,10px);
                }

                ul {
                    @include grid(1, 20px);

                    li {
                        background: $white;
                        @include border-radius(3px);
                        box-shadow: $bxs;

                        a {
                            text-decoration: none;
                            color: $font;
                            @include font-size(12px);
                            font-family: $fontHeader;
                            @include spacing(padding, 4);

                            &:hover { color: $green; }

                            @include device(pad) {
                                min-height: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
