<template>
    <div class="filter-slider">
        <label>{{ label }}</label>
        <div class="slider-amount">
            {{ sliderValue === maxValue ? '+' : '' }}
            {{ formattedValue }}
        </div>
        <range-slider
            v-model="sliderValue"
            class="slider"
            :min="minValue"
            :max="maxValue"
            :step="increment"
            @change="emitValue"
        />
    </div>
</template>

<script>
import SliderMixins from '@/components/features/SliderMixins.js';
export default {
    mixins: [SliderMixins],

    methods: {
        emitValue() {
            this.$emit('updateSliderValue', {key: this.calcKey, value: this.sliderValue});
        }
    }
};
</script>

<style lang="scss" scoped>
    .filter-slider {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        row-gap: 10px;
        margin-top: 20px;
        .slider {
            display: flex;
            width: 100%;
            ::v-deep {
                .range-slider-fill {
                    background: $green;
                }
            }

        }
        label {
            padding-right: 10px;
        }
        .slider-amount {
            padding-left: 10px;
        }
    }
</style>
