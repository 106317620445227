<template>
    <div class="filter-wrapper">
        <div class="filter-top">
            <button class="expander-button" @click="show = !show">
                <span>{{ $translate('filter_filter_button', 'Filter') }}</span>
                <i
                    class="fa-sharp fa-solid fa-chevron-down fa-xs" 
                    :class="{ 'fa-rotate-180': show }"
                />
                <span v-if="activeFiltersLength" class="filters-count">{{ activeFiltersLength }}</span>
            </button>
            <span class="items-count">
                {{ 
                    $translate('filter_showing_count', 'Visar {filteredAmount} av {amount} lån')
                        .replace('{filteredAmount}', listLength) 
                        .replace('{amount}', unfilteredListLength) 
                }}
            </span>
        </div>
        <div v-show="show" class="filter-dropdown">
            <div class="filter-options">
                <button 
                    v-for="(filter, key, index) in filters.checkboxes" 
                    :key="index" 
                    class="filter-option" 
                    :class="{'active': filter.active}" 
                    @click="emitCheckboxValue(key)"
                >
                    <span class="category-title"> {{ filter.title }} </span>
                </button>
            </div>
            <slider
                :label="filters.slider.loanAmount.label"
                :start-value="filters.slider.loanAmount.startValue"
                :increment="filters.slider.loanAmount.increment"
                :suffix="filters.slider.loanAmount.suffix"
                :min-value="filters.slider.loanAmount.minValue"
                :max-value="filters.slider.loanAmount.maxValue"
                calc-key="loanAmount"
                @updateSliderValue="emitSliderValue"
            />
        </div>
        <div v-if="listLength === 0" class="no-results" :class="{'margin-top': !show}">
            {{ $translate('filter_no_results', 'Inga resultat. Pröva att ändra dina filter') }}
        </div>
    </div>
</template>

<script>
import Slider from './Slider.vue';

export default {
    components: {
        Slider
    },
    props: {
        filters: {
            type: Object,
            required: true
        },
        listLength: {
            type: Number,
            required: true
        },
        unfilteredListLength: {
            type: Number,
            required: true
        },
        activeFiltersLength: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            show: false,
        };
    },
    methods: {
        emitCheckboxValue(key) {
            this.$emit('updateCheckboxValue', key);
        },
        emitSliderValue(data) {
            this.$emit('updateSliderValue', data);
        }
    }
};
</script>

<style lang="scss" scoped>
    .filter-wrapper {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .filter-top {
            display: flex;
            width: 100%;
            justify-content: space-between;
            .expander-button {
                all: unset;
                @include spacing(margin, 2, bottom);
                @include spacing(padding, 4, (left, right));
                @include spacing(padding, 2, (top, bottom));
                font-size: 18px;
                background: $white;
                display: flex;
                align-items: center;
                gap: 5px;
                @include border-radius(3px);
                cursor: pointer;
                .filters-count {
                    background: green;
                    color: $white;
                    border-radius: 100px;
                    width: 18px;
                    height: 18px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    @include spacing(margin, 1, left)
                }
            }
            .items-count {
                display: flex;
                align-items: center;
                font-size: 14px;
            }
        }
        .filter-dropdown {
            width: 100%;
            background: $white;
            @include spacing(padding, 4);
            @include spacing(margin, 4, bottom);
            box-shadow: $bxs;
            @include border-radius(3px);
            .filter-options {
                display: grid;
                gap: 16px;
                grid-template-columns: 1fr;
                @include device(pad) {
                    grid-template-columns: repeat(2, 1fr);
                }
                @include device(desktop) {
                    grid-template-columns: repeat(3, 1fr);
                }
                .filter-option {
                    box-shadow: $bxs;
                    background: $white;
                    font-weight: normal;
                    font-size: 16px;
                    padding: 10px;
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    &.active {
                        background: $green;
                        color: $white;
                    }
                }
            }
        }
        .no-results {
            width: 100%;
            text-align: center;
            font-family: $fontHeader;
            @include font-size(16px);
            &.margin-top {
                @include spacing(margin, 2, top)
            }
        }
    }
</style>