<template>
    <Section width="sm">
        <h2 class="section-header">{{ $prismic.asText(data.primary.h2) }}</h2>

        <ul class="list">
            <li>
                <Author
                    v-for="(author, index) in $store.state.authors"
                    :id="author.id"
                    :key="index"
                />
            </li>
        </ul>
    </Section>
</template>

<script>
import Author from '@/components/slices/lists/list-items/ListItemAuthor.vue';
export default {
    components: { Author },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    .list {
        @include grid(1, 40px);
    }
</style>
