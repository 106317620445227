<template>
    <div>
        <banner :data="data" />
        <Section
            width="md"
        >
            <list-filter 
                :filters="categoryTitles"
                :active-filter="activeFilter"
                @setFilter="setFilter"
                @query="setSearchQuery"
            />

            <ul v-if="filteredList.length" class="questions-list">
                <list-item 
                    v-for="(questionAnswer, index) in topList"
                    :key="`topList${index}`"
                    :question-answer="questionAnswer"
                />
                <li v-if="! showAll && notShownList.length" class="show-more-button"> 
                    <button class="btn btn-md btn-green" @click="showAll = true">
                        {{ $translate('qa_show_all', 'Visa alla') }}
                    </button>
                </li>
                <list-item 
                    v-for="(questionAnswer, index) in notShownList"
                    v-show="showAll"
                    :key="`listRest${index}`"
                    :question-answer="questionAnswer"
                />
            </ul>
            <span v-else class="no-results">
                {{ $translate('qa_no_results', 'Inga resultat. Pröva att ändra dina filter') }}
            </span>
        </Section>
        <Section width="md"> 
            <ask-question-form />
        </Section>
    </div>
</template>

<script>

import Banner from './Banner.vue';
import ListItem from './ListItem.vue';
import AskQuestionForm from './AskQuestionForm.vue';
import ListFilter from '@/components/features/ListFilter.vue';
import { queryAll } from '@swegaming-ab/nuxtjs-helpers';

export default {
    components: {
        Banner,
        ListItem,
        AskQuestionForm,
        ListFilter
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            activeFilter: 'all',
            searchQuery: '',
            questionAnswers: [],
            categories: [],
            showAll: false
        };
    },
    async fetch() {
        //QuestionAnswers
        this.questionAnswers = await queryAll(
            this.$prismic.api,
            this.$prismic.predicates.at('document.type', 'question_answer'),
            { 
                orderings: '[document.first_publication_date desc]', 
                lang: process.env.PRISMIC_LOCALE, fetchLinks: ['category.category', 'author.name'] 
            }
        );

        //Categories
        const categories = await queryAll(
            this.$prismic.api,
            this.$prismic.predicates.at('document.type', 'category'),
            { orderings: '[document.first_publication_date desc]', lang: process.env.PRISMIC_LOCALE }
        );

        this.categories = Object.values(categories);
    },
    computed: {
        categoryTitles() {
            return this.categories.map(item => {
                return item.data.category;
            });
        },
        filteredList() {
            let filteredList = this.questionAnswers;
            if (this.activeFilter !== 'all') {
                filteredList = filteredList.filter(item => {
                    return item.data.category.data.category === this.activeFilter;
                });
            }  

            if (this.searchQuery.length) {
                filteredList = filteredList.filter(item => {
                    return item.data.question_title.toLowerCase().includes(this.searchQuery)
                        || item.data.question.toLowerCase().includes(this.searchQuery) 
                        || item.data.author.data.name.toLowerCase().includes(this.searchQuery)
                        || item.data.category.data.category.toLowerCase().includes(this.searchQuery);
                });
            }

            return filteredList;
        },
        topList() {
            return this.filteredList.slice(0, 10);
        },
        notShownList() {
            return this.filteredList.slice(10);
        }
    },
    methods: {
        setFilter(filter) {
            this.activeFilter = filter; 
        },
        setSearchQuery(query) {
            this.searchQuery = query.toLowerCase();
        },
    }
};
</script>

<style lang="scss" scoped>
    .questions-list {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .show-more-button {
            display: flex;
            justify-content: center;
        }
    }
    .no-results {
        font-family: $fontHeader;
    }
</style>