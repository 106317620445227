import RangeSlider from 'vue-range-slider';
import 'vue-range-slider/dist/vue-range-slider.css';
export default {
    components: { RangeSlider },
    props: {
        label: {
            type: String,
            required: false,
            default: String
        },
        suffix: {
            type: String,
            required: false,
            default: String
        },
        increment: {
            type: String,
            required: false,
            default: String
        },
        maxValue: {
            type: Number,
            required: false,
            default: Number
        },
        minValue: {
            type: Number,
            required: false,
            default: Number
        },
        startValue: {
            type: Number,
            required: false,
            default: Number
        },
        calcKey: {
            type: String,
            required: false,
            default: String
        }
    },
    data() {
        return {
            sliderValue: undefined
        };
    },
    computed: {
        formattedValue() {
            return `${this.$format.number(this.sliderValue)} ${this.suffix}`;
        }
    },
    created() {
        this.sliderValue = this.interest ? this.interest : this.startValue;
    }
};