<template>
    <Section width="lg">
        <div class="section-heading">
            <h2 class="section-header">{{ h2 }}</h2>
            <div 
                v-if="$validateText(data.primary.automatic_rating_teaser)" 
                class="rich-text"
                v-html="$prismic.asHtml(data.primary.automatic_rating_teaser)" 
            />
        </div>
        <div class="dynamic-table-container mobile-headers-in-cell">
            <table>
                <thead>
                    <tr>
                        <th />
                        <td v-for="lender in lenders" :key="lender.id">
                            <div class="lender-header">
                                <nuxt-link :to="$prismic.linkResolver(lender)">
                                    <PrismicImage
                                        :img="lender.data.image"
                                        w="100"
                                    />
                                </nuxt-link>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody> 
                    <tr
                        v-for="(rating, key) in ratings"
                        :key="key"
                    >
                        <th>{{ rating.header }}</th>
                        <td 
                            v-for="(scoreData, lenderIndex) in rating.scoresData" 
                            :key="lenderIndex"
                        >
                            <div 
                                class="rating"
                                @click="toggleShowExplanation(key)"
                            >
                                <div class="mobile-data-title">{{ rating.header }}</div>
                                <i :class="scoreData.score.iconClass" />
                                <span>{{ scoreData.score.text }}</span>
                                <i class="fa-light fa-circle-info" />
                            </div>

                            <div 
                                v-show="rating.showExplanation" 
                                class="rating-explanation"
                            >
                                {{ scoreData.string }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Section>
</template>

<script>
import LenderRatings from '@/mixins/LenderRatings';
export default {
    mixins: [LenderRatings],
    props: {
        data: {
            type: Object,
            required: true
        },
        lenders: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            ratings: this.getRatings(this.lenders)     
        };
    },
    computed: {
        h2() {
            if (this.$validateText(this.data.primary.automatic_rating_h2)) {
                return this.$prismic.asText(this.data.primary.automatic_rating_h2);
            }
            return this.$translate('ctl_automatic_rating_h2', 'Hur står sig {lender1} mot {lender2}')
                .replace('{lender1}', this.lenders[0].data.title)
                .replace('{lender2}', this.lenders[1].data.title);
        }
    },
    methods: {
        // TODO: This logic is quite duplicated, and hard to follow
        toggleShowExplanation(key) {
            this.ratings[key].showExplanation = !this.ratings[key].showExplanation;
        },
        getRatings(lenders) {
            let ratings = {
                cost: {
                    header: this.$translate('lr_cost_header', 'Kostnad'),
                    showExplanation: false,
                    scoresData: lenders.map(lender => {
                        return {
                            score: this.getScoreObject(this.getCostScore(lender.model)),
                            string: this.getCostExplanationInterestString(lender, lender.model) + ' ' + this.getCostExplanationCostExampleString(lender.model),
                        };
                    })
                }
            };

            ratings.connectedBanks = {
                header: this.$translate('lr_amount_of_banks_header', 'Antal banker'),
                showExplanation: false,
                scoresData: lenders.map(lender => {
                    return {
                        score: this.getScoreObject(this.getConnectedBanksScore(lender.model)),
                        string: this.$translate(
                            'lr_amount_of_banks_explanation', 
                            '{title} är en låneförmedlare och ger inte ut några egna lån. Du kan få erbjudanden från över {connectedBanks} olika banker på samma kreditupplysning när du ansöker om lån genom {title}.'
                        )
                            .replace(/{title}/g, lender.data.title)
                            .replace(/{connectedBanks}/g, lender.model.connectedBanks),
                    };
                })
            };
            
            ratings.approvalRate = {
                header: this.$translate('lr_approval_rate_header', 'Beviljandegrad'),
                showExplanation: false,
                scoresData: lenders.map(lender => {
                    return {
                        score: this.getScoreObject(this.getApprovalRatingScore(lender.model)),
                        string: this.getApprovalRateExplanation(lender, lender.model),
                    };
                })
            };

            ratings.userRatings = {
                header: this.$translate('lr_customer_ratings_header', 'Kundomdömen'),
                showExplanation: false,
                scoresData: lenders.map(lender => {
                    const userRatings = lender.model._ratings.average_score;
                    return {
                        score: this.getScoreObject(userRatings),
                        string: this.$translate('lr_customer_ratings_explanation', 'Detta betyg baseras på {count} omdömen från låntagare hos {title}. Det genomsnittliga betyget är {rating} av 5.')
                            .replace('{count}', lender.model._ratings.count)
                            .replace('{title}', lender.data.title)
                            .replace('{rating}', this.$format.number(userRatings, 2)),
                    };
                })
            };

            ratings.customerSupport = {
                header: this.$translate('lr_customer_service_header', 'Kundtjänst'),
                showExplanation: false,
                scoresData: lenders.map(lender => {
                    const daysOpen = this.getCustomerSupportDaysOpen(lender.model);
                    return {
                        score: this.getScoreObject(this.getCustomerSupportScore(daysOpen)),
                        string: this.$translate('lr_customer_service_explanation', 'Detta baseras på kundtjänstens öppettider. {title} har öppet {daysOpen} dagar i veckan.')
                            .replace('{title}', lender.data.title)
                            .replace('{daysOpen}', daysOpen),
                    };
                })
            };

            ratings.averageScore = {
                header: this.$translate('lr_combined_rating_header', 'Sammanvägt betyg'),
                showExplanation: false,
                scoresData: lenders.map((_lender, lenderIndex) => {
                    let totalScore = 0;
                    for (let i = 0; i < Object.keys(ratings).length; i++) {
                        totalScore += Object.values(ratings)[i].scoresData[lenderIndex].score.score;
                    }
                    const averageScore = totalScore / Object.keys(ratings).length;
                    return {
                        score: this.getScoreObject(Math.round(averageScore)),
                        string: this.$translate('lr_combined_rating_explanation', 'Detta är en sammanvägning av samtliga ovanstående betyg.'),
                    };
                })
            };

            return ratings;
        }
    }
};
</script>

<style lang="scss" scoped>
    .rating-explanation {
        @include font-size(14px);
        margin-top: 10px;
        font-style: italic;
    }
    .rating {
        cursor: pointer;
        font-size: 18px;
    }
</style>