<template>
    <Section :width="sectionWidth">
        <div 
            v-if="$validateText(data.primary.header)"
            class="section-header" 
            v-html="$prismic.asHtml(data.primary.header)"
        />
        <div
            v-if="$validateText(data.primary.teaser)"
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.teaser)"
        />
        <div class="interest-container">
            <div class="interest-titles">
                <span 
                    v-for="table in tables" 
                    :key="table.key"
                    :class="{'active': table.key === activeData}"
                    @click="activeData = table.key"
                >
                    {{ table.title }}
                </span>
            </div>
            <interest-table 
                v-for="table in tables"
                v-show="activeData === table.key"
                :key="`table-${table.key}`"
                :table-title="table.title"
                :table-data="table.data"
            />
        </div>
    </Section>
</template>

<script>
import InterestTable from './mortgage-tables/InterestTable.vue';
export default {
    components: {
        InterestTable
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            activeData: '3m'
        };
    },
    computed: {
        sectionWidth() {
            return this.data.primary.section_width || 'sm';
        },
        dataType() {
            return this.data.primary.data_type;
        },
        lenders() {
            return this.data.items
                .map(item => this.$store.state.lendersMortgage[item.lender_mortgage.id]);
        },
        tables() {
            return [
                {
                    title: this.$translate('lm_3m_label', '3 mån (rörlig)'),
                    key: '3m',
                    data: this.getTableData('3m')
                },
                {
                    title: this.$translate('lm_1y_label', '1 år'),
                    key: '1y',
                    data: this.getTableData('1y')
                },
                {
                    title: this.$translate('lm_2y_label', '2 år'),
                    key: '2y',
                    data: this.getTableData('2y')
                },
                {
                    title: this.$translate('lm_3y_label', '3 år'),
                    key: '3y',
                    data: this.getTableData('3y')
                },
                {
                    title: this.$translate('lm_5y_label', '5 år'),
                    key: '5y',
                    data: this.getTableData('5y')
                },
                {
                    title: this.$translate('lm_7y_label', '7 år'),
                    key: '7y',
                    data: this.getTableData('7y')
                },
                {
                    title: this.$translate('lm_10y_label', '10 år'),
                    key: '10y',
                    data: this.getTableData('10y')
                }
            ];
        },
    },
    methods: {
        getTableData(dataKey) {
            if (this.dataType === 'list-interest') {                
                return this.lenders.map(lender => {
                    const model = new this.$models.Mortgage(lender, this.$store);
                    return {
                        lender: lender.data.title,
                        value: model.listInterestStrings[dataKey]
                    };
                });
            }
            return this.lenders.map(lender => {
                const model = new this.$models.Mortgage(lender, this.$store);
                return {
                    lender: lender.data.title,
                    value: model.averageInterestStrings[dataKey]
                };
            });
        }
    }
};
</script>
<style lang="scss" scoped>
    .interest-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;

        .interest-titles {
            display: flex;
            gap: 1rem;
            position: relative;
            flex-wrap: wrap;

            span {
                display: block;
                box-sizing: border-box;
                white-space: nowrap;
                font-family: $fontHeader;
                cursor: pointer;
                padding-bottom: 0.25rem;
                border-bottom: 2px solid transparent;
                user-select: none;
                &.active {
                    border-bottom: 2px solid $green;
                }
            }
        }
    }
</style>