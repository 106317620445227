<template>
    <Section width="lg">
        <div class="section-heading">
            <h2 class="section-header">{{ h2 }}</h2>
            <div 
                v-if="$validateText(data.primary.comparison_table_teaser)" 
                class="rich-text"
                v-html="$prismic.asHtml(data.primary.comparison_table_teaser)" 
            />
        </div>
        <div class="dynamic-table-container mobile-headers-in-cell">
            <table>
                <thead>
                    <tr>
                        <th />
                        <td v-for="lender in lenders" :key="lender.id">
                            <div class="lender-header">
                                <nuxt-link :to="$prismic.linkResolver(lender)">
                                    <prismic-image
                                        :img="lender.data.image"
                                        w="100"
                                    />
                                </nuxt-link>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(dataRow, key) in tableData" :key="key">
                        <th>{{ dataRow.title }}</th>
                        <td 
                            v-for="(rowData, rowDataIndex) in dataRow.data" 
                            :key="rowDataIndex"
                        >
                            <div class="mobile-data-title">{{ dataRow.title }}</div>
                            <span :class="{'highlighted': rowData.highlighted}">
                                {{ rowData.value }}
                                <tooltip 
                                    v-if="rowData.showTooltip" 
                                    :class="[
                                        {'first-column': rowDataIndex === 0},
                                        {'last-column': rowDataIndex === dataRow.data.length - 1}
                                    ]" 
                                    :data="rowData.tooltipData" 
                                />
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ $translate('cl_apply_table_header', 'Ansök') }}</th>
                        <td v-for="lender in lenders" :key="lender.id">
                            <ButtonTarget
                                :link="lender"
                                :text="$translate('cta_apply_direct', 'Ansök direkt')"
                                size="md"
                                background="green"
                                class="cta-button"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Section>
</template>

<script>
import { Tooltip } from '@swegaming-ab/vue-components';
export default {
    components: {
        Tooltip
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        lenders: {
            type: Array,
            required: true
        }
    },
    computed: {
        h2() {
            if (this.$validateText(this.data.primary.comparison_table_h2)) {
                return this.$prismic.asText(this.data.primary.comparison_table_h2);
            }
            return this.$translate('ctl_comparison_table_h2', 'Jämför villkor & omdömen')
                .replace('{lender1}', this.lenders[0].data.title)
                .replace('{lender2}', this.lenders[1].data.title);
        },
        tableData() {
            const tableData = {
                amount: {
                    title: this.$translate('amount', 'Belopp'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.amountString
                        };
                    })
                },
                interest: {
                    title: this.$translate('interest', 'Ränta'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.interestString,
                            highlighted: lender.model.minInterestPossible === this.bestInterest
                        };
                    })
                },
                effectiveInterest: {
                    title: this.$translate('effective_interest', 'Effektiv ränta'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.effectiveInterestString,
                            highlighted: lender.model.minEffectiveInterestPossible === this.bestEffectiveInterest
                        };
                    })
                },
                duration: {
                    title: this.$translate('duration', 'Löptid'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.getDurationString()
                        };
                    })
                },
                minAge: {
                    title: this.$translate('ld_age_limit_label', 'Ålderskrav'),
                    data: this.lenders.map(lender => {
                        return {
                            value: this.$translate('ld_age_limit_value', '{minAge} eller äldre')
                                .replace('{minAge}', lender.model.minAge),
                            highlighted: lender.model.minAge === this.bestMinAge
                        };
                    })
                },
                setupFee: {
                    title: this.$translate('setup_fee', 'Uppläggningsavgift'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.setupFeeString,
                            highlighted: lender.model.setupFee === this.bestSetupFee
                        };
                    })
                },
                creditCheck: {
                    title: this.$translate('credit_check', 'Kreditupplysning'),
                    data: this.lenders.map(lender => {
                        return {
                            value: this.$getCreditCheckString(lender.model.creditCheck),
                        };
                    }),
                    disabled: this.$isNorway()
                },
                amountOfBanks: {
                    title: this.$translate('connected_banks', 'Anslutna långivare'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.isBroker 
                                ? this.$translate('connected_banks_data', '{amountOfBanks} anslutna långivare')
                                    .replace('{amountOfBanks}', lender.model.connectedBanks)
                                : this.$translate('is_not_broker', 'Är inte låneförmedlare'),
                            highlighted: lender.model.connectedBanks === this.bestAmountOfBanks
                        };
                    })
                },
                directPayments: {
                    title: this.$translate('direct_payout', 'Direktutbetalning'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.hasDirectPayments24Hours
                                ? this.$translate('twentyfour_seven', 'Dygnet runt')
                                : lender.model.hasDirectPayments
                                    ? this.$translate('yes', 'Ja')
                                    : this.$translate('no', 'Nej'),
                            showTooltip: this.$getDirectPaymentBanks(lender.model).length,
                            tooltipData: this.$translate('direct_payout_to_bank', 'Direktutbetalning till {bank}')
                                .replace('{bank}', this.$getDirectPaymentBanks(lender.model).join(', '))
                        };
                    })
                },
                paymentRemarks: {
                    title: this.$translate('ld_payment_remarks_label', 'Betalningsanmärkningar'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.acceptsPaymentRemarks 
                                ? this.$translate('accepted', 'Accepteras') 
                                : this.$translate('not_accepted', 'Accepteras inte'),
                            highlighted: lender.model.acceptsPaymentRemarks

                        };
                    })
                },
                trustpilotRatings: {
                    title: 'Trustpilot',
                    data: this.lenders.map(lender => {
                        return {
                            value: this.$translate('average_rating_score', '{averageRating} av 5 ({amount} omdömen)')
                                .replace('{averageRating}', this.$format.number(lender.model.trustpilotScore, 1))
                                .replace('{amount}', lender.model.trustpilotRatingCount),
                            highlighted: lender.model.trustpilotScore === this.bestTrustpilotRating
                        };
                    })
                },
                userRatings: {
                    title: this.$translate('rating', 'Betyg'),
                    data: this.lenders.map(lender => {
                        return {
                            value: this.$translate('average_rating_score', '{averageRating} av 5 ({amount} omdömen)')
                                .replace('{averageRating}', this.$format.number(lender.model._ratings.average_score, 1))
                                .replace('{amount}', lender.model._ratings.count),
                            highlighted: lender.model._ratings.average_score === this.bestRating
                        };
                    })
                },
            };
            return Object.fromEntries(
                Object.entries(tableData)
                    .filter(([, value]) => !value.disabled)
            );
        },
        bestInterest() {
            return Math.min(...this.lenders.map(lender => lender.model.minInterestPossible));
        },
        bestEffectiveInterest() {
            return Math.min(...this.lenders.map(lender => lender.model.minEffectiveInterestPossible));
        },
        bestMinAge() {
            return Math.min(...this.lenders.map(lender => lender.model.minAge));
        },
        bestSetupFee() {
            return Math.min(...this.lenders.map(lender => lender.model.setupFee));
        },
        bestRating() {
            return Math.max(...this.lenders.map(lender => lender.model._ratings.average_score)) || false;
        },
        bestTrustpilotRating() {
            return Math.max(...this.lenders.map(lender => lender.model.trustpilotScore)) || false;
        },
        bestAmountOfBanks() {
            return Math.max(...this.lenders.map(lender => lender.model.connectedBanks)) || false;
        },
    },
};
</script>

<style lang="scss" scoped>
    .highlighted {
        color: $green;
        font-weight: bold;
    }
    .cta-button {
        display: block;
        max-width: 150px;
        padding: 15px;
    }
    ::v-deep {
        .tooltip {
            i {
                margin: 0;
            }
            .tooltip-open {
                min-width: 150px !important;
                right: 0;
                transform: translateX(50%);
                bottom: calc(100% + 5px);
            }
            @include device(mobile) {
                &.first-column {
                    .tooltip-open {
                        left: calc(100% - 15px);
                        right: unset;
                        transform: unset;
                    }
                }
                &.last-column {
                    .tooltip-open {
                        right: calc(100% - 15px);
                        transform: unset;
                    }
                }
            }
        }
    }
</style>