<template>
    <div class="wrapper wrapper-lender">
        <Cta :document="document" />

        <Banner
            :document="document"
            :model="model"
        />

        <Section
            class="page-menu"
            width="lg"
        >
            <ul>
                <li><a v-scroll-to="'#rakna-pa-kostnaden'" href="#"> {{ $translate('lp_navigation_calculate', 'Räkna på kostanden') }}</a></li>
                <li><a v-scroll-to="'#jamforelse-och-betyg'" href="#">{{ $translate('lp_navigation_comparisons_and_rating', 'Jämförelser & betyg') }}</a></li>
                <li><a v-scroll-to="'#avgifter'" href="#">{{ $translate('lp_navigation_fees', 'Avgifter') }}</a></li>
                <li><a v-scroll-to="'#recension-och-kontaktuppgifter'" href="#">{{ $translate('lp_navigation_review_and_contact_details', 'Recension & kontaktuppgifter') }}</a></li>
                <li v-if="hasFaq"><a v-scroll-to="{el: '#faq', offset: -150}" href="#">{{ $translate('lp_navigation_faq', 'Frågor & svar') }}</a></li>
                <li><a v-scroll-to="'#write-review'" href="#">{{ $translate('lp_navigation_customer_reviews', 'Kundomdömen') }}</a></li>
            </ul>
        </Section>

        <LoanCalculator
            :document="document"
            :model="model"
        />

        <Ratings
            :document="document"
            :model="model"
        />

        <Test
            v-if="document.data.test_score"
            :document="document"
        />
        
        <Fees
            v-if="!model.isBroker"
            :model="model"
        />

        <Review
            :document="document"
            :model="model"
            :has-faq="hasFaq"
        />

        <Ucr :data="document.data" />

        <OtherBrands :document="document" />

        <author-box :document="document" /> 

        <script type="application/ld+json" v-html="jsonld" />
    </div>
</template>

<script>
import Cta from './LenderCta.vue';
import Banner from './LenderBanner.vue';
import Ratings from './LenderRatings.vue';
import Test from './LenderTest.vue';
import Fees from './LenderFees.vue';
import Review from './LenderReview.vue';
import OtherBrands from './LenderOtherBrands.vue';
import LoanCalculator from './LenderLoanCalculator';
import AuthorBox from '@/components/AuthorBox.vue';
import Ucr from '@/components/slices/ucr/Ucr.vue';
export default {
    components: {
        Cta, 
        Banner, 
        Ratings, 
        Test, 
        Fees, 
        Review, 
        LoanCalculator,
        Ucr,
        OtherBrands,
        AuthorBox
    },
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        model() {
            return new this.$models.Loan(this.document, this.$store);
        },
        jsonld() {

            if (!(this.model._ratings &&
                    this.model._ratings.data &&
                    this.model._ratings.data.length)) {
                return '';
            }

            return {
                '@context': 'https://schema.org',
                '@type': 'Product',
                'name': this.document.data.title,
                'dateModified': this.$format.date(this.document.last_publication_date),
                'aggregateRating': {
                    '@type': 'AggregateRating',
                    'ratingValue': this.model._ratings.average_score,
                    'ratingCount': this.model._ratings.count,
                    'reviewCount': this.model._ratings.count,
                    'worstRating': '1',
                    'bestRating': '5'
                }
            };
        },
        hasFaq() {
            return this.document.data.faq_items && this.document.data.faq_items[0]?.question?.length > 0;
        }
    },
    mounted () {
        this.$event.viewLender(this.document.data.title);
    },
};
</script>

<style lang="scss">
    .wrapper-lender {
        .page-menu {
            padding: 0 20px;

            ul {
                width: 100%;
                @include flex;
                text-align: center;

                li {
                    @include spacing(padding, 4);

                    a {
                        font-size: 14px;
                        text-decoration: none;
                        color: $blue;
                        font-weight: bold;

                        &:hover { text-decoration: underline; }
                    }
                }
            }
        }

        .box {
            @include flex(start,start);
            @include spacing(padding, 6, (bottom,top));
            border-top: 1px solid rgba(0,0,0,0.05);

            &-left {
                font-family: $fontHeader;
                @include font-size(22px);
                color: $font;
                width: 100%;
                margin-bottom: 40px;

                @include device(pad) {
                    margin-bottom: 0;
                    max-width: 200px;
                    width: auto;
                    flex: 1;
                }
            }

            &-right {
                width: 100%;
                font-family: $fontText;
                @include font-size(18px);

                @include device(pad) {
                    margin-left: 40px;
                    width: auto;
                    flex: 1;
                }

                i { font-size: 26px; }

                span {
                    width: 100%;
                    display: block;

                    &:first-child {
                        @include font-size(20px);
                        @include flex(start);
                        font-weight: bold;
                        margin-bottom: 20px;

                        i { margin-right: 20px; }
                    }

                    &:last-child {
                        @include font-size(16px);
                    }
                }
            }
        }
    }
</style>
