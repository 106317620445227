<template>
    <Section
        v-if="document.data.test_score"
        class="test bg-blue"
        width="md"
    >
        <h2>{{ $prismic.asText(document.data.test_h2) }}</h2>
        <p> {{ document.data.test_p }} </p>

        <div class="container">
            <div class="container-inner">
                <div
                    class="rich-text"
                    v-html="$prismic.asHtml(document.data.test_review)"
                />

                <div class="container-inner__score">
                    <span class="score-header">{{ $translate('lt_review_score_label', 'Vårt omdöme') }}</span>
                    <i class="fa-solid fa-star" />
                    <span class="score-number">{{ document.data.test_score }} / 5</span>
                </div>
            </div>

            <div class="container-inner">
                <h3>{{ $translate('lt_about_the_test_h3', 'Om testet') }}</h3>

                <ul>
                    <li>
                        <span>{{ $translate('lt_test_date_label', 'Testet utfördes') }}</span>
                        <span>{{ document.data.test_date }}</span>
                    </li>
                    <li>
                        <span>{{ $translate('lt_loan_amount_label', 'Lånebelopp') }}</span>
                        <span>{{ $format.currency(document.data.test_amount) }} </span>
                    </li>
                    <li>
                        <span>{{ $translate('lt_duration_label', 'Vald löptid') }}</span>
                        <span>{{ document.data.test_loan_duration }}</span>
                    </li>
                    <li>
                        <span>{{ $translate('lt_about_the_borrower_label', 'Om låntagaren') }}</span>
                        <span>{{ document.data.test_about_tester }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>

h2,p {
    color: $white;
    width: 100%;
    text-align: center;
}

p {
    margin-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    @include border(bottom,2px,rgba(255,255,255,.25));

}

.container {
    color: $white;
    width: 100%;
    @include grid(1, 40px);

    @include device(pad) {
        @include grid(2, 40px);
    }

    h3 { margin-bottom: 30px; }

    &-inner:first-child {
        .container-inner__score {
            @include border(top,2px,rgba(255,255,255,.25));
            padding-top: 20px;
            margin-top: 20px;

            span {
                font-family: $fontHeader;
            }

            .score-header {
                display: block;
                @include font-size(24px);
                margin-bottom: 10px;
            }

            .score-number {
                @include font-size(24px);
                margin-left: 10px;
            }

            i { font-size: 24px; }
        }
    }

    &-inner:last-child {

        ul {
            @include grid(1, 20px);

            li {
                @include grid(2, 20px);
                width: 100%;

                span:first-child {font-family: $fontHeader;}
            }
        }
    }
}

</style>
