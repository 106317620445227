<template>
    <Section v-if="lenders.length" width="sm" class="lender-payout-table">
        <div 
            v-if="$validateText(data.primary.text)"
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.text)"
        />
        <ul>
            <li v-for="lender in lenders" :key="lender.id">
                <LenderPayoutBox :lender-with-model="lender" />
            </li>
        </ul>
    </Section>
</template>

<script>
import LenderPayoutBox from '@/components/slices/rich-text/RichTextLenderPayoutBox.vue';
export default {
    components: {
        LenderPayoutBox
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        lenders() {
            return this.data.items
                .map(iterator => this.$store.state.lenders[iterator.lender.id])
                .map(lender => ({
                    ...lender,
                    model: new this.$models.Loan(lender),
                }))
                .filter(lender => lender.model.paymentAdministrationSpeed && lender.model.paymentAdministrationSpeed.date)
                .sort((a, b) => a.model.paymentAdministrationSpeed.date - b.model.paymentAdministrationSpeed.date);
        }
    },
}
</script>

<style lang="scss" >
.lender-payout-table ul {
    @include grid(1, 20px);
}
</style>
