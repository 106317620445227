<template>
    <Section
        id="avgifter"
        width="md"
        class="bg-lgray"
    >
        <h2>{{ $translate('lf_fees_h2', 'Avgifter') }}</h2>

        <div class="box">
            <div class="box-left">{{ $translate('lf_setup_fee_label', 'Uppläggningsavgift') }}</div>
            <div class="box-right">
                <span>{{ model.setupFeeString }}</span>
                <span>{{ $translate('lf_explanation_of_setup_fee', 'Kostnad för att teckna ett nytt lån.') }}</span>
            </div>
        </div>
        <div class="box">
            <div class="box-left">{{ $translate('lf_extension_fee_label', 'Förlängningsavgift') }}</div>
            <div class="box-right">
                <span>{{ model.extensionFeeString }}</span>
                <span>{{ $translate('lf_explanation_of_extension_fee', 'Kostnad för att förlänga återbetalningstiden på ett befintligt lån.') }}</span>
            </div>
        </div>
        <div class="box">
            <div class="box-left">{{ $translate('lf_avi_fee_label', 'Aviavgift') }}</div>
            <div class="box-right">
                <span>{{ model.administrationFeeString }}</span>
                <span>{{ $translate('lf_explanation_of_avi_fee', 'Administrativ avgift för upprättande och utskick av betalningsavi.') }}</span>
            </div>
        </div>
        <div class="box">
            <div class="box-left">{{ $translate('lf_reminder_fee_label', 'Påminnelseavgift') }}</div>
            <div class="box-right">
                <span>{{ model.reminderFeeString }}</span>
                <span>{{ $translate('lf_explanation_of_reminder_fee', 'Avgift som debiteras om långivaren skickar en betalningspåminnelse.') }}</span>
            </div>
        </div>
        <div class="box">
            <div class="box-left">{{ $translate('lf_late_payment_fee_label', 'Förseningsavgift') }}</div>
            <div class="box-right">
                <span>{{ model.latePaymentFeeString }}</span>
                <span>{{ $translate('lf_explanation_of_late_payment_fee', 'Tilläggsavgift som kan debiteras för upprättande av en avbetalningsplan.') }}</span>
            </div>
        </div>
        <div class="box">
            <div class="box-left">{{ $translate('lf_penalty_interest_label', 'Dröjsmålsränta') }}</div>
            <div class="box-right">
                <span>{{ model.penaltyInterestString }}</span>
                <span>{{ $translate('lf_explanation_of_penalty_interest', 'Ränta som debiteras vid dröjsmål med betalning.') }}</span>
            </div>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        model: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
h2 {
    color: $green;
    text-align: center;
    @include spacing(margin, 10, bottom);
}
</style>
