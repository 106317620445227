<template>
    <Section
        class="banner bg-cream"
        width="lg"
    >
        <h1>{{ document.data.title }}</h1>

        <div class="container">
            <LenderStats v-if="document.type === 'lender'" :model="model" />
            <LenderCorporateStats v-else-if="document.type === 'lender_corporate'" :model="model" />
        </div>

        <div class="container">
            <PrismicImage
                :img="document.data.image"
                :alt="document.data.title"
                w="200"
                h="100"
            />

            <div
                v-if="$helpers.filterEmptyElements(document.data.banner_text)"
                class="rich-text"
                v-html="$prismic.asHtml(document.data.banner_text)"
            />

            <ButtonTarget
                v-if="document.data.redirect_enabled"
                :link="document"
                :text="$translate('cta_to_application', 'Till ansökan')"
                background="green"
                size="md"
                placement="banner"
            />

            <div
                v-else
                class="container-inner"
            >   
                <p>
                    {{ 
                        $translate('lp_loan_expired', '{title} har utgått och går inte längre att teckna. Men vi hjälper dig att hitta ett annat lån!')
                            .replace('{title}', document.data.title) 
                    }} 
                </p>

                <!-- TODO: norway too -->
                <nuxt-link
                    v-if="$isSweden()"
                    to="/laneguiden/"
                    class="btn btn-green btn-md"
                >
                    {{ $translate('cta_expired_loan_link_to_loan_guide', 'Till låneguiden') }}
                </nuxt-link>
            </div>
            <div v-if="$isNorway() && document.data.redirect_enabled" class="compliance-string">Annonslenke</div>
        </div>
    </Section>
</template>

<script>
import LenderStats from '@/components/LenderStats.vue';
import LenderCorporateStats from '@/components/pages/lender-corporate/LenderCorporateStats.vue';
export default {
    components: { LenderStats, LenderCorporateStats },
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        },
        model: {
            type: Object,
            required: false,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
.banner::v-deep {

    .section-inner {
        background: $white;
        @include border-radius(5px);
        box-shadow: $bxs;
        @include flex;

        h1 {
            color: $green;
            @include spacing(padding, 8,(left,top,right));
            width: 100%;
            text-align: center;
        }

        .container {
            width: 100%;
            background: $white;
            @include spacing(padding, 4);
            flex: 1;

            &:first-child {
                @include flex;

                .lender-stats {
                    width: 100%;
                }
            }

            &:last-child {
                text-align: center;
                @include grid(1, 40px);

                img {
                    margin: auto;
                    @include border-radius(5px);
                }

                .btn {
                    margin: auto;
                    max-width: 300px;
                }

                .container-inner {
                    @include flex;
                    .btn { @include spacing(margin,8,top); }
                }
            }
        }
    }

    .btn {
        text-align: center;
        min-width: 200px;
    }

    img {
        @include size(240px, auto);
    }

    .content {
        @include device(mobile) {
            padding-top: 60px;
            order: 1;
        }

        @include device(pad) {
            padding-left: 30px;
        }

        @include device(desktop) {
            padding-left: 60px;
        }
    }
}
</style>
