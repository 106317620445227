<template>
    <Section :width="sectionWidth"> 
        <div class="image-container"> 
            <prismic-image 
                :img="data.primary.image"
                :alt="data.primary.image.alt"
                :w="imageWidth"
            />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        sectionWidth() {
            return this.data.primary.width ?? 'sm';
        },
        imageWidth() {
            if (this.sectionWidth === 'md') {
                return '900';
            }
            if (this.sectionWidth === 'lg') {
                return '1100';
            }
            return '700';
        }
    }
};
</script>

<style lang="scss" scoped>
    .image-container {
        display: flex;
        justify-content: center;
        img {
            width: 100%;
            @include border-radius(3px);
        }
    }
</style>