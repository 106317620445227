<template>
    <div>
        <h3 class="sub-section-header">{{ h3 }}</h3>
        <div 
            v-if="$validateText(data.primary.connected_lenders_conclusion_teaser)" 
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.connected_lenders_conclusion_teaser)" 
        />
        <ul class="summary-list">
            <li 
                v-for="(item, index) in items" 
                :key="index"
                class="summary-item"
            >
                {{ item }}
            </li>
        </ul>
    </div>
</template>

<script>
import ConnectedLendersData from './mixins/ConnectedLendersData';
export default {
    mixins: [ConnectedLendersData],
    props: {
        data: {
            type: Object,
            required: true
        },
        lenders: {
            type: Array,
            required: true
        }
    },
    computed: {
        h3() {
            if (this.$validateText(this.data.primary.connected_lenders_conclusion_h3)) {
                return this.$prismic.asText(this.data.primary.connected_lenders_conclusion_h3);
            }
            return this.$translate('ctl_connected_lenders_conclusion_h3', 'Sammanställning av anslutna långivare')
                .replace('{lender1}', this.lenders[0].data.title)
                .replace('{lender2}', this.lenders[1].data.title);
        },
        items() {
            return [
                this.getConclusionString(
                    this.$translate('ctl_conclusion_most_banks', '{winner} samarbetar med totalt {difference} st fler långivare än {loser}'),
                    this.$translate('ctl_conclusion_most_banks_draw', '{lender1} & {lender2} har samma antal anslutna långivare'),
                    lender => lender.model.connectedBanks
                ),
                this.getConclusionString(
                    this.$translate('ctl_conclusion_payment_remarks', '{winner} har {difference} st fler långivare som accepterar betalningsanmärkningar'),
                    this.$translate('ctl_conclusion_payment_remarks_draw', '{lender1} & {lender2} har samma antal långivare som accepterar betalningsanmärkningar'),
                    lender => this.getAcceptsPaymentRemarksCount(lender.connectedBanks)
                ),
                this.getConclusionString(
                    this.$translate('ctl_conclusion_low_interest', '{winner} har {difference} st fler långivare som kan erbjuda lån under 6 % ränta'),
                    this.$translate('ctl_conclusion_low_interest_draw', '{lender1} & {lender2} har samma antal långivare som kan erbjuda lån under 6 % ränta'),
                    lender => this.getLowInterestCount(lender.connectedBanks)
                ),
                this.getConclusionString(
                    this.$translate('ctl_conclusion_high_loan_amount', '{winner} har {difference} st fler långivare som kan erbjuda lån om 500 000 kr och över'),
                    this.$translate('ctl_conclusion_high_loan_amount_draw', '{lender1} & {lender2} har samma antal långivare som kan erbjuda lån om 500 000 kr och över'),
                    lender => this.getHighLoanAmountCount(lender.connectedBanks)
                ),
            ];
        },
    },
    methods: {
        getConclusionString(winnerTemplate, equalTemplate, getCountFunction) {
            const lender1 = this.lenders[0];
            const lender2 = this.lenders[1];
            const lender1Count = getCountFunction(lender1);
            const lender2Count = getCountFunction(lender2);
            const countDifference = Math.abs(lender1Count - lender2Count);
            if (countDifference === 0) {
                return equalTemplate
                    .replace('{lender1}', lender1.data.title)
                    .replace('{lender2}', lender2.data.title);
            }
            const [winner, loser] = lender1Count > lender2Count 
                ? [lender1, lender2] 
                : [lender2, lender1];

            return winnerTemplate
                .replace('{winner}', winner.data.title)
                .replace('{loser}', loser.data.title)
                .replace('{difference}', countDifference);
        }
    },
};
</script>

<style lang="scss" scoped>
    .summary-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .summary-item {
            padding: 15px;
            box-shadow: $bxs;
            border-radius: 3px;
            background: $white;
            font-size: 18px;
        }
    }
</style>