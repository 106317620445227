<template>
    <Section
        id="jamforelse-och-betyg"
        width="md"
        class="bg-cream"
    >   
        <h2> 
            {{ $translate('lr_h2', 'Hur bra är {title} jämfört med konkurrenterna?')
                .replace('{title}', document.data.title) 
            }} 
        </h2>
        
        <div v-for="(rating, index) in ratings" :key="index" class="box">
            <div class="box-left">{{ rating.header }}</div>
            <div class="box-right">
                <span>
                    <i :class="rating.score.iconClass" />
                    {{ rating.score.text }}
                </span>
                <span>{{ rating.string }}</span>
            </div>
        </div>
    </Section>
</template>

<script>
import LenderRatings from '@/mixins/LenderRatings.js';
export default {
    mixins: [LenderRatings],
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        },
        model: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        
        let ratings = [
            {
                header: this.$translate('lr_cost_header', 'Kostnad'),
                score: this.getScoreObject(this.getCostScore(this.model)),
                string: this.getCostExplanationInterestString(this.document, this.model) + ' ' + this.getCostExplanationCostExampleString(this.model),
            }
        ];

        if (this.model.isBroker) {
            // loan broker
            ratings.push({
                header: this.$translate('lr_amount_of_banks_header', 'Antal banker'),
                score: this.getScoreObject(this.getConnectedBanksScore(this.model)),
                string: this.$translate(
                    'lr_amount_of_banks_explanation', 
                    '{title} är en låneförmedlare och ger inte ut några egna lån. Du kan få erbjudanden från över {connectedBanks} olika banker på samma kreditupplysning när du ansöker om lån genom {title}.'
                )
                    .replace(/{title}/g, this.document.data.title)
                    .replace(/{connectedBanks}/g, this.model.connectedBanks),
            });
        }
        
        if (this.model._core.sales_stats.sales > 4) {
            ratings.push({
                header: this.$translate('lr_approval_rate_header', 'Beviljandegrad'),
                score: this.getScoreObject(this.getApprovalRatingScore(this.model)),
                string: this.getApprovalRateExplanation(this.document, this.model),
            });
        }

        const userRatings = this.model._ratings.average_score;
        ratings.push({
            header: this.$translate('lr_customer_ratings_header', 'Kundomdömen'),
            score: this.getScoreObject(userRatings),
            string: 
                this.$translate('lr_customer_ratings_explanation', 'Detta betyg baseras på {count} omdömen från låntagare hos {title}. Det genomsnittliga betyget är {rating} av 5.')
                    .replace('{count}', this.model._ratings.count)
                    .replace('{title}', this.document.data.title)
                    .replace('{rating}', this.$format.number(userRatings, 2)),
        });

        const daysOpen = this.getCustomerSupportDaysOpen(this.model);
        ratings.push({
            header: this.$translate('lr_customer_service_header', 'Kundtjänst'),
            score: this.getScoreObject(this.getCustomerSupportScore(daysOpen)),
            string: this.$translate('lr_customer_service_explanation', 'Detta baseras på kundtjänstens öppettider. {title} har öppet {daysOpen} dagar i veckan.')
                .replace('{title}', this.document.data.title)
                .replace('{daysOpen}', daysOpen),
        });

        let totalScore = 0;
        for (let i = 0; i < ratings.length; i++) {
            totalScore += ratings[i].score.score;
        }
        const averageScore = totalScore / ratings.length;

        ratings.push({
            header: this.$translate('lr_combined_rating_header', 'Sammanvägt betyg'),
            score: this.getScoreObject(Math.round(averageScore)),
            string: this.$translate('lr_combined_rating_explanation', 'Detta är en sammanvägning av samtliga ovanstående betyg.'),
        });

        return {
            ratings: ratings
        };
    }
};
</script>

<style lang="scss" scoped>
    h2 {
        color: $green;
        text-align: center;
        @include spacing(margin, 10, bottom);
    }
</style>
