<template>
    <transition name="fade">
        <div
            v-if="show && document.data.redirect_enabled"
            class="cta"
        >
            <PrismicImage
                :img="document.data.image"
                :alt="document.data.title"
                w="auto"
                h="30"
            />
            <ButtonTarget
                :link="document"
                :text="$translate('cta_to_application', 'Till ansökan')"
                background="green"
                size="sm"
                placement="sticky"
            />
            <div v-if="$isNorway() && document.data.redirect_enabled" class="compliance-string">Annonslenke</div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            show: false
        };
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scroll);
    },
    beforeMount() {
        window.addEventListener('scroll', this.scroll);
    },
    methods: {
        scroll: function(){
            if (window.scrollY >= 800) {
                this.show = true;
            }

            else {
                this.show = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.cta {
    position: fixed;
    top: 0;
    width: 100%;
    @include flex;
    background: $white;
    box-shadow: $bxs;
    @include spacing(padding,3);
    z-index: 98;

    img { margin-right: 20px; }

    .btn {
        padding: 10px 20px;
    }

    .compliance-string {
        margin-left: 20px;
    }
    
    @media screen and (min-width: 1200px) {
        top: 75px;
    }
}
</style>
