<template>
    <div class="question-options__input">
        <div class="inner">
            <label v-if="label">{{ label }}</label>

            <input
                v-model="query"
                type="text"
                name=""
                @keypress="allowOnlyNumbers($event)"
            >

            <span v-if="description">{{ description }}</span>
        </div>

        <div
            v-if="error"
            class="question-error"
        >
            {{ error }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        description: {
            type: String,
            required: false,
            default: String
        },
        answerKey: {
            type: String,
            required: true,
            default: String
        },
        label: {
            type: String,
            required: false,
            default: String
        },
        onlyNumbers: {
            type: Boolean,
            required: false,
            default: false
        },
        error: {
            type: String,
            required: false,
            default: String
        }
    },
    data() {
        return {
            query: ''
        };
    },
    watch: {
        query() {
            this.$emit('set', this.answerKey, this.query);
            this.$emit('resetError', this.answerKey);
        }
    },
    methods: {
        allowOnlyNumbers ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if (this.onlyNumbers) {
                if (keyCode < 48 || keyCode > 57) $event.preventDefault();
            }
        }
    }
};
</script>

<style lang="scss" scoped>

    .question-options__input {
        @include flex;

        &:nth-child(2),
        &:nth-child(3) { @include spacing(margin, 6, top); }

        .inner {
            width: 100%;
            max-width: 300px;
        }

        label {
            display: block;
            text-align: left;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        input {
            font-family: 16px;
            @include spacing(padding, 3);
            @include border(all, 2px);
            width: 100%;
        }

        span {
            display: block;
            margin-top: 15px;
            font-size: 14px;
        }

        .question-error {
            color: #b1403d;
            background: rgba(177,64,61,.2);
            @include spacing(padding, 2);
            margin-top: 10px;
            font-weight: bold;
            font-size: 14px;
            width: 100%;
        }
    }
</style>
