<template>
    <Page v-if="document.type === 'page'" :document="document" />
    <Post v-else-if="document.type === 'post'" :document="document" />
    <Lender v-else-if="document.type === 'lender'" :document="document" />
    <Author v-else-if="document.type === 'author'" :document="document" />
    <LenderCorporate v-else-if="document.type === 'lender_corporate'" :document="document" />
    <QuestionAnswer v-else-if="document.type === 'question_answer'" :document="document" />
</template>
<script>

import getDocument from '@/plugins/get-document';
import Page from '@/components/pages/Page.vue';
import Post from '@/components/pages/post/Post.vue';
import Lender from '@/components/pages/lender/Lender.vue';
import Author from '@/components/pages/Author.vue';
import LenderCorporate from '@/components/pages/lender-corporate/LenderCorporate.vue';
import QuestionAnswer from '@/components/pages/question-answer/QuestionAnswer.vue';

export default {
    components: {
        Page,
        Post,
        Lender,
        LenderCorporate,
        Author,
        QuestionAnswer
    },
    layout: 'site',

    async asyncData({$prismic, route, error}) {
        try {
            return await getDocument($prismic, route.path, error);
        }
        catch (e) {
            error({statusCode: 404, message: 'Not found'});
        }
    },
    head() {
        return {
            title: this.document.data.seo_title,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.document.data.seo_description
                }
            ]
                .concat(this.document.data.is_landing_page === true ? [{ name: 'robots', content: 'noindex' }] : []),
            link: [
                {
                    rel: 'canonical',
                    href: this.$prismic.linkResolver(this.document, true)
                },
            ]
                .concat(this.hreflangs)
        };
    }
};
</script>
