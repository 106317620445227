<template>
    <div>
        <h3 class="sub-section-header">{{ h3 }}</h3>
        <div 
            v-if="$validateText(data.primary.connected_lenders_overview_teaser)" 
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.connected_lenders_overview_teaser)" 
        />
        <div class="dynamic-table-container">
            <table>
                <thead>
                    <tr>
                        <th />
                        <td v-for="lender in lenders" :key="lender.id">
                            {{ lender.data.title }}
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(rowData, rowIndex) in tableData" :key="rowIndex">
                        <th>
                            {{ rowData.title }}
                            <div class="header-explanation">
                                {{ rowData.explanation }}
                            </div>
                        </th>
                        <td v-for="(cellData, cellIndex) in rowData.values" :key="cellIndex">
                            {{ cellData }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import ConnectedLendersData from './mixins/ConnectedLendersData';
export default {
    mixins: [ConnectedLendersData],
    props: {
        data: {
            type: Object,
            required: true
        },
        lenders: {
            type: Array,
            required: true,
        }
    },
    computed: {
        h3() {
            if (this.$validateText(this.data.primary.connected_lenders_overview_h3)) {
                return this.$prismic.asText(this.data.primary.connected_lenders_overview_h3);
            }
            return this.$translate('ctl_connected_lenders_overview_h3', 'Översikt')
                .replace('{lender1}', this.lenders[0].data.title)
                .replace('{lender2}', this.lenders[1].data.title);
        },
        tableData() {
            return [
                {
                    title: this.$translate('ctl_overview_connected_lenders_header', 'Anslutna långivare'),
                    explanation: this.$translate('ctl_overview_connected_lenders_explanation', 'Totala anslutna långivare'),
                    values: this.lenders.map(lender => `${lender.model.connectedBanks} ${this.$translate('amount_abbreviation', 'st')}`)
                },
                {
                    title: this.$translate('ctl_overview_payment_remarks_header', 'Betalningsanmärkningar'),
                    explanation: this.$translate('ctl_overview_payment_remarks_explanation', 'Accepterar betalningsanmärkningar'),
                    values: this.lenders.map(lender => `${this.getAcceptsPaymentRemarksCount(lender.connectedBanks)} ${this.$translate('amount_abbreviation', 'st')}`)
                },
                {
                    title: this.$translate('ctl_overview_low_interest_header', 'Lågräntelån'),
                    explanation: this.$translate('ctl_overview_low_interest_explanation', 'Ränta under 6%'),
                    values: this.lenders.map(lender => `${this.getLowInterestCount(lender.connectedBanks)} ${this.$translate('amount_abbreviation', 'st')}`)
                },
                {
                    title: this.$translate('ctl_overview_high_loan_amount_header', '500k+ lån'),
                    explanation: this.$translate('ctl_overview_high_loan_amount_explanation', 'Erbjuder lån av 500 000 kr'),
                    values: this.lenders.map(lender => `${this.getHighLoanAmountCount(lender.connectedBanks)} ${this.$translate('amount_abbreviation', 'st')}`)
                }
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
    thead td {
        min-width: 100px !important;
    }
    th, td {
        vertical-align: middle;
    }
    .header-explanation {
        font-size: 12px;
        margin-top: 5px;
    }
</style>