<template>
    <Section :width="data.primary.width">
        <h2 class="section-header">{{ $prismic.asText(data.primary.h2) }}</h2>
        <div
            v-if="$prismic.asText(data.primary.teaser)"
            class="section-teaser"
            v-html="$prismic.asHtml(data.primary.teaser)"
        />
        <div class="grid">
            <div class="container highlighted">
                <post-item
                    :post="posts[0]"
                    class="box"
                />
            </div>
            <div class="container default">
                <post-item
                    :post="posts[1]"
                    class="box"
                />
                <post-item
                    :post="posts[2]"
                    class="box"
                />
                <post-item
                    :post="posts[3]"
                    class="box"
                />
            </div>
        </div>
    </Section>   
</template>


<script>
import PostItem from '@/components/HighlightedPostItem.vue';
import { replace } from '@swegaming-ab/nuxtjs-helpers';
export default {
    components: {
        PostItem
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            posts: []
        };
    },
    async fetch() {
        const response = await this.$prismic.api.getByIDs(
            this.postIds,
            { lang: process.env.PRISMIC_LOCALE }
        );
        this.posts = replace(response.results);
    },
    fetchKey() {
        return `highlighted-posts-${this.data.id}`;
    },
    computed: {
        postIds() {
            return [
                this.data.primary.post_1_highlighted.id,
                this.data.primary.post_2.id,
                this.data.primary.post_3.id,
                this.data.primary.post_4.id
            ].filter(id => id);
        }
    }
};
</script>


<style lang="scss" scoped>
    .section-teaser {
        @include spacing(margin,6,bottom);
    }

    .grid {
        @include grid(1,20px);

        @include device(desktop) {
            @include grid(2,20px);
        }

        .container .box{
            box-shadow: $bxs;
            @include border-radius(3px);
            background: $white;
            overflow: hidden;
            @include grid(1,0);
        }

        .container.default {
            @include grid(1,20px);

            .box {
                @include device(pad) {
                    @include grid(2,0);
                }
            }
        }
        .container.highlighted .box{
            height: 100%;
        }
    }
</style>
