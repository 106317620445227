<template>
    <Section width="md">
        <h2 class="section-header">{{ $prismic.asText(data.primary.h2) }}</h2>
        <div
            v-if="data.primary.teaser"
            class="section-teaser"
            v-html="$prismic.asHtml(data.primary.teaser)"
        />

        <div class="grid-container">
            <TableCalculationExample
                v-for="(iterator, index) in data.items"
                :id="iterator.lender.id"
                :key="index"
                :slice="data"
            />
        </div>
    </Section>
</template>

<script>
import TableCalculationExample from './lender-tables/TableCalculationExample.vue';
export default {
    components: { TableCalculationExample },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        // NOTE: Add computed list when adding more tables
        // list() {}
    }
}
</script>

<style lang="scss" scoped>
    section::v-deep {
        background: $lightgray;

        .section-header,
        .section-teaser {
            @include spacing(margin,6,bottom);
        }

        .grid-container {
            // @include grid(1, 30px);
            // max-width: 100%;
            .table-container {
                margin-bottom: 30px;
                &:last-child { margin-bottom: 0; }
            }
        }
    }
</style>
