<template>
    <Section v-if="hasAuthor" width="sm">
        <h2 class="section-header">{{ $translate('author_about_the_author_h2', 'Om författaren') }}</h2>
        <Author 
            :id="document.data.author.id"
            :sources="sources"
        />
    </Section>
</template>

<script>
import Author from '@/components/slices/lists/list-items/ListItemAuthor.vue';
export default {
    components: { Author },
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        hasAuthor() {
            return this.document.data.author?.id;
        },
        sources() {
            return this.document.data.sources
                .map(iterator => iterator.source)
                .filter(source => source);
        }
    }
};
</script>
