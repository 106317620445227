<template>
    <div class="wrapper">
        <banner :document="document" />
        <Section
            width="sm"
            class="answer-title-container"
        >
            <h2 class="section-header h3">
                {{ 
                    $translate('qa_author_answers', '{author} svarar:')
                        .replace('{author}', document.data.author.data.name)
                }} 
            </h2>
        </Section>
        <Slices :slices="document.data.body" />
        <similar-questions
            :category="document.data.category.data.category" 
            :current-id="document.id" 
        />
        <Section 
            width="sm"
        >
            <Author :id="document.data.author.id" :show-articles="false" />
        </Section>
    </div>
</template>

<script>
import Slices from '@/components/slices/Slices.vue';
import SimilarQuestions from './SimilarQuestions.vue';
import Banner from './Banner.vue';
import Author from '@/components/slices/lists/list-items/ListItemAuthor.vue';


export default {
    components: {
        Banner,
        Slices,
        SimilarQuestions,
        Author,
    },
    props: {
        document: {
            type: Object,
            required: true
        }
    },
};
</script>

<style lang="scss" scoped>

.answer-title-container {
    padding-bottom: 0;
}
.slices > :first-child {
    padding-top: 0;
    ::v-deep p:first-child {
        margin-top: 0;
    }
}
</style>