<template>
    <div class="calculator-box result bg-blue">
        <h3>{{ $translate('calc_result_section_h3', 'Resultat') }}</h3>
        <div class="result-body">
            <div v-if="isCarLoan" class="result-item">
                <span>{{ $translate('calc_result_loan_amount', 'Lånebelopp') }}</span>
                <span>{{ $format.currency(loanAmount) }}</span>
            </div>
            <div class="result-item">
                <span>{{ $translate('calc_result_interest_cost', 'Räntekostnad') }}</span>
                <span>{{ $format.currency(interestCost) }}</span>
            </div>
            <div class="result-item">
                <span>{{ $translate('calc_result_interest_deduction', 'Ränteavdrag') }}</span>
                <span>{{ $format.currency(interestDeduction * -1) }}</span>
            </div>
            <div class="result-item">
                <span>{{ $translate('calc_result_total_to_repay', 'Total återbetalning') }}</span>
                <span>{{ $format.currency(totalToPay) }}</span>
            </div>
            <div class="result-item result-highlighted">
                <span>{{ $translate('calc_result_monthly_payment', 'Månadsbetalning') }}</span>
                <span>{{ $format.currency(monthlyPayments) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        loanAmount: {
            type: Number,
            required: true
        },
        interestCost: {
            type: Number,
            required: true
        },
        interestDeduction: {
            type: Number,
            required: true
        },
        totalToPay: {
            type: Number,
            required: true
        },
        monthlyPayments: {
            type: Number,
            required: true
        },
        isCarLoan: {
            type: Boolean,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
    .calculator-box {
        box-shadow: $bxs;
        @include spacing(padding, 4);
        @include border-radius(3px);
        h3 {
            text-align: center;
        }
    }
    .result {
        color: $white;
        .result-body {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .result-item {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 0.5rem;
                font-size: 18px;
                @include border(top);
                padding-top: 1rem;
                @include device(pad) {
                    flex-wrap: nowrap;
                }
                &:first-child {
                    padding-top: 0;
                    border-top: unset;
                }
                &.result-highlighted {
                    font-size: 20px;
                    font-weight: bold;
                    border-width: 2px;
                }
            }
        }
    }
</style>