<template>
    <Section 
        v-if="simlarQuestionAnswers.length"
        width="sm"
    > 
        <h3>{{ $translate('qa_similar_questions', 'Liknande frågor') }}</h3>
        <ul class="list">
            <li
                v-for="(questionAnswer, index) in simlarQuestionAnswers"
                :key="index"
                class="list-item"
            >
                <nuxt-link :to="$prismic.linkResolver(questionAnswer)" class="similar-question"> 
                    <h4 class="title">{{ questionAnswer.data.question_title }}</h4>
                    <span class="question">{{ questionAnswer.data.question }}</span>
                </nuxt-link>
            </li>
        </ul>
    </Section>
</template>

<script>
import { queryAll } from '@swegaming-ab/nuxtjs-helpers';

export default {
    props: {
        category: {
            type: String,
            required: true
        },
        currentId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            simlarQuestionAnswers: []
        };
    },
    async fetch() {
        const questionAnswers = await queryAll(
            this.$prismic.api,
            this.$prismic.predicates.at('document.type', 'question_answer'),
            { 
                orderings: '[document.first_publication_date desc]', 
                lang: process.env.PRISMIC_LOCALE, fetchLinks: ['category.category'] 
            }
        );
        this.simlarQuestionAnswers = Object.values(questionAnswers)
            .filter(questionAnswer => {
                return questionAnswer.data.category.data.category === this.category 
                    && this.currentId !== questionAnswer.id;
            })
            .slice(0, 3);
    },
};
</script>

<style lang="scss" scoped>
    h3 {
        @include spacing(margin,3,bottom)
    }
    .list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .list-item {
            .similar-question {
                text-decoration: none;
                &:hover{text-decoration: underline;}
                color: $font;
                .title {
                    font-weight: bold;
                    color: $green;
                    @include font-size(18px);
                    margin-bottom: 5px;
                }
                .question {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    @include device(pad) {
                        -webkit-line-clamp: 1;
                    }
                }
            }
        }
    }
</style>