<template>
    <Section width="sm">
        <h2 class="section-header">{{$prismic.asText(data.primary.h2)}}</h2>
        <div
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.teaser)"
        />

        <div
            v-if="data.items.length > 0"
            class="table-container mobile-scroll-shadow"
        >
            <table class="table-container__table">
                <thead>
                    <tr>
                        <th>{{ tableHeadRow.column_1 }}</th>
                        <th>{{ tableHeadRow.column_2 }}</th>
                        <th v-if="tableHeadRow.column_3">{{ tableHeadRow.column_3 }}</th>
                        <th v-if="tableHeadRow.column_4">{{ tableHeadRow.column_4 }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(row, index) in tableBodyRows"
                        :key="index"
                    >
                        <td>{{ row.column_1 }}</td>
                        <td>{{ row.column_2 }}</td>
                        <td v-if="row.column_3">{{ row.column_3 }}</td>
                        <td v-if="row.column_4">{{ row.column_4 }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        tableHeadRow() {
            return this.data.items[0]
        },
        tableBodyRows() {
            return this.data.items.filter((item, index) => item && index > 0)
        }
    }
}
</script>

<style lang="scss" scoped>
.table-container {
    width: 100%;
    overflow-x: auto;
    &__table {
        tr {
            th,
            td {
                text-align: left;
                min-width: 150px;
                @include border(right);
                &:first-child {
                    font-weight: bold;
                    max-width: 150px;
                }
                &:last-child { border: 0; }
            }
        }
    }
}
.rich-text {
    @include spacing(margin,8,bottom);
}
</style>