<template>
    <div class="cols" itemscope itemtype="https://schema.org/FAQPage">
        <QuestionAndAnswer
            v-for="(iterator, index) in faq"
            :key="index"
            :question="iterator.question"
            :answer="$prismic.asHtml(iterator.answer)"
            class="rich-text"
        />
    </div>
</template>

<script>
import { QuestionAndAnswer } from '@swegaming-ab/vue-components';
export default {
    components: { QuestionAndAnswer },
    props: {
        faq: {
            type: Array,
            required: false,
            default: null
        },
    }
};
</script>

<style lang="scss" scoped>
    .cols::v-deep {
        @include grid(1, 0px);

        .question-answer {
            width: 100%;
            line-height: 20px;
            border-bottom: 1px solid #e6e6e6;

            &:first-child {
                .question { padding-top: 0; }
            }

            .question {
                padding: 20px 0px;

                &-text {
                    font-weight: bold;
                    color: $green;
                    margin-right: 20px;
                }

                &-icon {
                    color: $green;
                }
            }

            .answer {
                p:first-child { margin-top: 0; }
            }
        }

        .question-answer:last-child {
            border-bottom: 0;
        }
    }
</style>
