<template>
    <div class="wrapper-post">
        <Banner :document="document" />

        <Slices :slices="document.data.body" :document="document" />

        <Comments :document="document" />

        <Author :document="document" />
    </div>
</template>

<script>
import Banner from './PostBanner.vue';
import Comments from './PostComments.vue';
import Author from './PostAuthor.vue';
import Slices from '@/components/slices/Slices.vue';
export default {
    components: { Slices, Banner, Comments, Author },
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
.slices::v-deep {

    section:first-child {
        padding-top: 0;
    }
}
</style>
