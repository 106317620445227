<template>
    <div class="wrapper">
        <Section
            class="banner"
            width="sm"
        >
            <h1>{{ author(document.id).data.name }}</h1>
            <div class="banner-inner__teaser"><p>{{ author(document.id).data.role }}</p></div>
        </Section>

        <Section width="md">
            <div class="container">
                <Author
                    :id="document.id"
                    :show-articles="false"
                />
            </div>

            <div class="container">
                <span>
                    {{ 
                        $translate('author_latest_articles_of_author', 'Senaste artiklarna av {author}')
                            .replace('{author}', author(document.id).data.name) 
                    }}
                </span>
                <ul>
                    <li
                        v-for="(article, index) in articles"
                        :key="index"
                    >
                        <nuxt-link :to="$prismic.linkResolver(article)">{{ article.data.title }}</nuxt-link>
                    </li>
                </ul>
            </div>
        </Section>

        <Section width="md">
            <h2 class="section-header">{{ $translate('author_form_h2', 'Skapa ett supportärende') }}</h2>
            <form class="form" @submit.prevent>
                <div class="form-container">
                    <label for="message">{{ $translate('author_form_message_label', 'Berätta om ditt ärende') }}:</label>
                    <textarea v-model="form" name="message" rows="8" />
                </div>
                <!-- Todo: fix mailto -->
                <a
                    class="form-submit btn btn-md btn-green"
                    :href="`mailto:kontakt@lånen.se?subject=Support&body=${form}`"
                >
                    {{ $translate('cta_author_submit_form', 'Skapa ärende') }}
                </a>
            </form>
        </Section>
    </div>
</template>

<script>
import Author from '@/components/slices/lists/list-items/ListItemAuthor.vue';
import { replace } from '@swegaming-ab/nuxtjs-helpers';
export default {
    components: { Author },
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            form: '',
            articles: []
        };
    },
    async fetch() {
        const response = await this.$prismic.api.query(
            [
                this.$prismic.predicates.at('document.type', 'post'),
                this.$prismic.predicates.at('my.post.author', this.document.id) 
            ],
            {
                lang: process.env.PRISMIC_LOCALE,
                orderings: '[ document.last_publication_date desc ]',
                pageSize: 5
            }
        );
        this.articles = replace(response.results);
    },
    fetchKey() {
        return `5-author-${this.document.id}-posts`;
    },
    methods: {
        author(id) {
            return this.$store.state.authors[id];
        }
    }
};
</script>

<style lang="scss" scoped>
.wrapper::v-deep {
    section {

        &:nth-child(1) {
            background: $cream;

            .section-inner {
                @include flex;
                text-align: center;

                h1 {
                    width: 100%;
                    color: $green;
                }
            }
        }

        &:nth-child(2) {
            .section-inner {
                @include grid(1, 60px);

                @include device(pad) {
                    @include grid(2, 60px);
                }

                .container {
                    &:last-child {

                        span {
                            font-family: $fontHeader;
                            @include font-size(18px);
                            color: $green;
                            @include spacing(margin,4,bottom);
                            display: block;
                        }

                        ul {
                            @include grid(1, 10px);

                            li {
                                a  {
                                    text-decoration: none;
                                    color: $blue;
                                    font-family: $fontHeader;
                                    @include font-size(14px, 8px);
                                    &:hover { text-decoration: underline; }
                                }
                            }
                        }
                    }
                }
            }
        }

        &:nth-child(3) {
            .form {

                &-submit {
                    max-width: 300px;
                    margin: auto;
                }
            }
        }
    }
}
</style>
