<template>
    <Section
        id="ucr"
        class="ucr"
        width="sm"
    >
        <ul class="ucr-list">
            <li
                v-for="(rating, index) in ratings"
                :key="index"
                class="ucr-list-item"
            >
                <div class="rating-name">{{ rating.rater_name }}</div>

                <div class="rating-stars">
                    <i
                        v-for="i in 5"
                        :key="i"
                        :class="{ active: i <= rating.rating_score }"
                        class="fa-solid fa-star"
                    />
                </div>

                <div
                    v-if="rating.rating_header"
                    class="rating-header"
                >
                    {{ rating.rating_header }}
                </div>

                <div
                    class="rating-body rich-text"
                    v-html="$format.comment(rating.rating_body)"
                />

                <div class="rating-timestamp">{{ postedAt(rating.posted_at) }}</div>

                <div v-if="rating.reply" class="reply">
                    <span class="reply-author-name">
                        {{ rating.reply.author_name }}
                    </span>
                    <div 
                        class="reply-text rich-text" 
                        v-html="$format.comment(rating.reply.comment_body)"
                    />
                    <p class="rating-timestamp">
                        {{ postedAt(rating.reply.created_at) }}
                    </p>
                </div>
            </li>
        </ul>

        <form
            v-if="!postSuccess"
            id="form"
            class="ucr-form"
            @submit.prevent
        >
            <h2 class="ucr-form-title">{{ $translate('urf_form_h2', 'Skriv ett omdöme') }}</h2>

            <div class="ucr-form-input-container">
                <label for="score">{{ $translate('urf_rating_label', 'Ditt betyg') }}</label>
                <div class="stars">
                    <i :class="[{ratingSet: newRating.rating_score}, {hover: ratingScoreHover}]" class="fa-solid fa-star" @click="newRating.rating_score = 1" @mouseenter="ratingScoreHover = 1" @mouseleave="ratingScoreHover = undefined" />
                    <i :class="[{ratingSet: newRating.rating_score > 1}, {hover: ratingScoreHover > 1}]" class="fa-solid fa-star" @click="newRating.rating_score = 2" @mouseenter="ratingScoreHover = 2" @mouseleave="ratingScoreHover = undefined" />
                    <i :class="[{ratingSet: newRating.rating_score > 2}, {hover: ratingScoreHover > 2}]" class="fa-solid fa-star" @click="newRating.rating_score = 3" @mouseenter="ratingScoreHover = 3" @mouseleave="ratingScoreHover = undefined" />
                    <i :class="[{ratingSet: newRating.rating_score > 3}, {hover: ratingScoreHover > 3}]" class="fa-solid fa-star" @click="newRating.rating_score = 4" @mouseenter="ratingScoreHover = 4" @mouseleave="ratingScoreHover = undefined" />
                    <i :class="[{ratingSet: newRating.rating_score > 4}, {hover: ratingScoreHover > 4}]" class="fa-solid fa-star" @click="newRating.rating_score = 5" @mouseenter="ratingScoreHover = 5" @mouseleave="ratingScoreHover = undefined" />
                </div>
            </div>

            <div class="ucr-form-input-container">
                <label for="name">{{ $translate('urf_first_name_label', 'Ditt förnamn') }}</label>
                <input v-model="newRating.rater_name" name="name" type="text">
            </div>

            <div class="ucr-form-input-container">
                <label for="email">{{ $translate('urf_email_label', 'Din e-postadress') }}</label>
                <input v-model="newRating.rater_email" name="email" type="text">
                <div v-if="postError.rater_email" class="error">
                    {{ $translate('urf_email_error_message', 'Kolla så att email fältet är korrekt i fyllt!') }}
                </div>
            </div>

            <div class="ucr-form-input-container">
                <label for="header">
                    <span>{{ $translate('urf_header_label', 'Ge ditt omdöme en rubrik') }}
                    </span>
                    <span>{{ $translate('urf_header_sub_label', 'Frivilligt') }}</span>
                </label>
                <input v-model="newRating.rating_header" name="header" type="text">
            </div>

            <div class="ucr-form-input-container">
                <label for="body">
                    <span>
                        {{ 
                            $translate('urf_body_label', 'Berätta om din erfarenhet av {title}')
                                .replace('{title}', document.title)
                        }}
                    </span>
                    <span>{{ $translate('urf_body_sub_label', 'Minst 20 tecken') }}</span>
                </label>
                <textarea v-model="newRating.rating_body" name="body" rows="5" cols="80" />
            </div>

            <div class="ucr-form-submit-button">
                <button
                    type="submit"
                    :class="{ disabled: !enableSubmit }"
                    @click="submit"
                >
                    {{ $translate('cta_urf_submit_form', 'skicka omdöme') }}
                </button>
            </div>
        </form>

        <div
            v-else
            id="form"
            class="ucr-form post-success"
        >
            <h2>{{ $translate('urf_success_message_h2', 'Tack!') }}</h2>
            <p>{{ $translate('urf_success_message_p', 'Ditt omdöme kommer att publiceras så fort det har blivit granskat.') }}</p>
        </div>
    </Section>
</template>

<script>
import axios from 'axios';
// import { orderBy } from 'lodash';
import orderBy from 'lodash/orderBy';
export default {
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        },
        coreApi: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data () {
        return {
            newRating: {
                source_id: undefined,
                brand_id: undefined,
                rating_header: undefined,
                rating_body: '',
                rating_score: undefined,
                rater_name: undefined,
                rater_email: undefined
            },
            enableSubmit: false,
            ratingScoreHover: undefined,
            posting: false,
            postSuccess: false,
            postError: false
        };
    },
    computed: {
        ratings () {
            return orderBy(this.coreApi.ratings.data, 'posted_at', 'desc');
        }
    },
    watch: {
        newRating: {
            deep: true,
            handler() {
                if (
                    this.newRating.rating_score &&
                    this.newRating.rater_name &&
                    this.newRating.rater_email &&
                    this.newRating.rating_body.length >= 20
                ) {
                    this.enableSubmit = true;
                }
                else {
                    this.enableSubmit = false;
                }
            }
        }
    },
    methods: {
        submit() {
            this.posting = true;
            this.postError = [];
            this.newRating.brand_id = this.coreApi.core_data.core_id;
            this.newRating.source_id = process.env.CORE_SOURCE_ID;
            let url = 'https://api.core.compary.com/api/v0/ratings';

            // TODO: Change to this.$axios etc.
            axios.post(url, this.newRating)
                .then(() => {
                    this.$event.ratingSubmitted();
                    this.posting = false;
                    this.postSuccess = true;
                })
                .catch((e) => {
                    this.postError = e.response.data.error;
                    this.posting = false;
                });
        },
        postedAt(string) {
            let format = string.split('T');
            return format[0];
        }
    }
};
</script>
