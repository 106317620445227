export default function sortLenders(lenders, modelClass) {

    let maxSalesCount = 0;
    let maxConversionRate = 0;
    let maxEpc = 0;

    lenders.forEach(lender => {
        lender.model = new modelClass(lender);

        maxEpc = lender.model._core.sales_stats.epc > maxEpc ? lender.model._core.sales_stats.epc : maxEpc;
        maxSalesCount = lender.model._core.sales_stats.sales > maxSalesCount ? lender.model._core.sales_stats.sales : maxSalesCount;
        maxConversionRate = lender.model._core.sales_stats.conversion_rate > maxConversionRate ? lender.model._core.sales_stats.conversion_rate : maxConversionRate;
    });

    lenders.forEach(lender => {

        if (lender.model._core.sales_stats.sales === 0) {
            lender.order = lender.model._core.sales_stats.clicks * -1;
        }
        else if (lender.model._core.sales_stats.sales < 5) {
            lender.order =
                lender.model._core.sales_stats.epc / maxEpc +
                lender.model._core.sales_stats.sales / maxSalesCount +
                lender.model._core.sales_stats.conversion_rate / maxConversionRate;
        }
        else {
            lender.order =
                (8 * (lender.model._core.sales_stats.epc / maxEpc)) +
                lender.model._core.sales_stats.sales / maxSalesCount +
                lender.model._core.sales_stats.conversion_rate / maxConversionRate;
        }
    });

    return lenders.slice(0).sort((a, b) => b.order - a.order);
}