<template>
    <Section
        class="indexed"
        width="sm"
    >
        <div class="container">
            <span class="index">{{ data.primary.index }}</span>
            <h2 v-if="$helpers.filterEmptyElements(data.primary.h2)">{{ $prismic.asText(data.primary.h2) }}</h2>
        </div>

        <ul class="container list">
            <li
                v-for="(section, index) in data.items"
                :key="index"
                class="list-item"
            >
                <span class="list-item__index">{{ data.primary.index }}.{{ index+1 }}</span>

                <div
                    class="list-item__content"
                    v-html="$prismic.asHtml(section.section)"
                />
            </li>
        </ul>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
section::v-deep {
    padding-top: 20px;
    padding-bottom: 20px;

    .container {

        &:first-child {
            width: 100%;
            @include flex(start);
            @include spacing(margin,6,bottom);

            h2 {
                color: $green;
                font-size: 26px;
                flex: 1;
            }
            .index {
                background: $green;
                color: $white;
                @include size(40px);
                @include border-radius(100px);
                font-size: 20px;
                font-weight: 700;
                @include flex;
                margin-right: 15px;
            }
        }

        &:last-child {
            .list-item {
                position: relative;
                padding-left: 40px;
                margin-bottom: 40px;
                word-break: break-word;

                p {
                    font-size: 14px;
                }

                &__index {
                    position: absolute;
                    left: 0;
                    color: $green;
                    font-weight: bold;
                    top: 5px;
                }

                a {
                    color: $green;
                    font-weight: bold;
                    text-decoration: none;
                    &:hover { text-decoration: underline; }
                }

                ul {
                    background: #74b54122;
                    font-size: 12px;
                    padding: 20px;
                    @include grid(1, 30px);

                    li {
                        padding: 0;
                        @include font-size(14px, 10px);
                        // margin-bottom: 30px;
                        // line-height: 18px;
                        // &:last-child { margin-bottom: 0; }

                        strong {
                            font-weight: bold;
                            display: block;
                        }

                        em {
                            font-style: italic;
                            display: inline-block;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
    }
}

.indexed {



    li {

    }

}

</style>
