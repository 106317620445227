<template>
    <Section
        class="banner bg-cream"
        width="sm"
    >
        <div
            class="banner-inner"
            :style="{ textAlign: data.primary.text_align }"
        >
            <h1 class="banner-inner__title">{{ $prismic.asText(data.primary.h1) }}</h1>
            <div
                v-if="$helpers.filterEmptyElements(data.primary.teaser)"
                class="banner-inner__teaser list-icon list-checkmark"
                v-html="$prismic.asHtml(data.primary.teaser)"
            />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
.banner::v-deep {

    h1 {
        color: $green;
        width: 100%;
        text-align: center;
    }

    .banner-inner {
        @include flex;

        ul {
            width: auto;
            @include grid(1, 15px);
            text-align: left;
        }

        a {
            color: $green;
            text-decoration: none;
            font-weight: bold;
            &:hover { text-decoration: underline; }
        }
    }
}
</style>
