<template>
    <Section
        v-if="!showResult"
        class="loan-guide bg-cream"
        width="sm"
    >
        <Container
            :header="$translate('lg_loan_for_header', 'Vad vill du låna till?')"
            :error="errors.loanFor"
        >
            <div class="options-6">
                <ButtonOption
                    v-for="(button, index) in question1"
                    :key="`question${index}`"
                    :data="button"
                    :active="answers.loanFor"
                    @set="setAnswer"
                    @resetError="resetErrors"
                />
            </div>
        </Container>
        <Container :header="$translate('lg_loan_amount_header', 'Hur mycket vill du låna?')">
            <Input
                answer-key="amount"
                :description="$translate('lg_loan_amount_description', 'Ange ett belopp mellan 1 000 - 600 000 kr.')"
                :only-numbers="true"
                :error="errors.amount"
                @set="setAnswer"
                @resetError="resetErrors"
            />
        </Container>
        <Container :header="$translate('lg_monthly_income_header', 'Ange din månadsinkomst')">
            <Input
                answer-key="income"
                :description="$translate('lg_monthly_income_description', 'Ange din månadsinkomst före skatt.')"
                :only-numbers="true"
                :error="errors.income"
                @set="setAnswer"
                @resetError="resetErrors"
            />
        </Container>
        <Container
            :header="$translate('lg_payment_remarks_header', 'Har du betalningsanmärkningar?')"
            :error="errors.paymentRemark"
        >
            <div class="options-2">
                <ButtonOption
                    v-for="(button, index) in boolean"
                    :key="`question${index}`"
                    :data="button"
                    :active="answers.paymentRemark"
                    @set="setAnswer"
                    @resetError="resetErrors"
                />
            </div>
        </Container>
        <Container
            :header="$translate('lg_payout_time_header', 'Hur snabbt behöver du pengarna?')"
            :error="errors.when"
        >
            <div class="options-3">
                <ButtonOption
                    v-for="(button, index) in when"
                    :key="`question${index}`"
                    :data="button"
                    :active="answers.when"
                    @set="setAnswer"
                    @resetError="resetErrors"
                />
            </div>
        </Container>
        <Container :header="$translate('lg_personal_information', 'Övriga uppgifter om dig')">
            <Select
                answer-key="dateOfBirth"
                :label="$translate('lg_date_of_birth', 'Vilket år är du född?')"
                :error="errors.dateOfBirth"
                @set="setAnswer"
                @resetError="resetErrors"
            />
            <Input
                answer-key="email"
                :label="$translate('lg_email_label', 'E-postadress')"
                :error="errors.email"
                @set="setAnswer"
                @resetError="resetErrors"
            />
            <Input
                answer-key="phone"
                :label="$translate('lg_phone_label', 'Telefonnummer')"
                :error="errors.phone"
                :only-numbers="true"
                @set="setAnswer"
                @resetError="resetErrors"
            />

            <Label
                answer-key="acceptTips"
                :error="errors.acceptTips"
                @set="setAnswer"
            >
                {{ $translate('lg_accept_tips', 'Jag vill ta emot personliga tips om lån som passar mig.') }}
            </Label>
            <Label
                answer-key="acceptPersonalInfo"
                :error="errors.acceptPersonalInfo"
                @set="setAnswer"
                @resetError="resetErrors"
            >
                <div class="accept-personal-info-disclaimer" v-html="$prismic.asHtml($translate('lg_accept_personal_info_disclaimer'))" />
            </Label>

            <Button
                :text="$translate('lg_submit_form', 'Visa mina bästa lånealternativ')"
                background="green"
                size="md"
                :class="{disabled: disableSubmit}"
                @click="submit()"
            />
        </Container>
    </Section>

    <Section
        v-else
        class="loan-guide-result bg-cream"
        width="lg"
    >
        <h2 class="section-header">{{ $translate('lg_result_h2', 'Ditt resultat') }}</h2>
        <ul class="lender-list">
            <ListItem
                v-for="(lender, index) in resultList"
                :id="lender.id"
                :key="index"
                :position="index + 1"
            />
        </ul>
    </Section>
</template>

<script>
import axios from 'axios';
import Container from './components/Container.vue';
import ButtonOption from './components/Button.vue';
import Input from './components/Input.vue';
import Select from './components/Select.vue';
import ListItem from '@/components/slices/lists/list-items/ListItemLender.vue';
import Label from './components/Label.vue';

import sortLenders from '@/plugins/sort-lenders.js';

export default {
    components: { ListItem, Container, ButtonOption, Input, Select, Label },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            answers: {
                loanFor: '',
                amount: '',
                income: '',
                paymentRemark: '',
                when: '',
                dateOfBirth: '',
                email: '',
                phone: '',
                acceptTips: '',
                acceptPersonalInfo: ''
            },
            errors: {
                loanFor: '',
                amount: '',
                income: '',
                paymentRemark: '',
                when: '',
                dateOfBirth: '',
                email: '',
                phone: '',
                acceptPersonalInfo: ''
            },
            showResult: false,
            disableSubmit: false
        };
    },
    computed: {
        resultList() {
            // sort instead
            const lenders = Object.values(this.$store.state.lenders)
                .map(l => Object.assign({}, l));

            const sorted = sortLenders(lenders, this.$models.Loan);

            const filtered = sorted.filter(lender => {
                if (!lender.data.redirect_enabled) {
                    return false;
                }

                if (this.answers.amount) {
                    if (this.answers.amount < lender.model.minAmount) {
                        return false;
                    }
                    if (this.answers.amount > lender.model.maxAmount) {
                        return false;
                    }
                }
                
                if (this.answers.paymentRemark && this.answers.paymentRemark === 'true') {
                    if (! lender.model.acceptsPaymentRemarks) {
                        return false;
                    }
                }

                if (this.answers.when && this.answers.when === 'immediately' && this.answers.amount < 50000) {
                    if (! lender.model.hasDirectPayments) {
                        return false;
                    }
                }

                return true;
            });

            return filtered.slice(0, 5);
        },

        validateAnswers() {
            if (
                this.answers.loanFor &&
                this.answers.amount >= '1000' && this.answers.amount <= '600000' &&
                this.answers.income &&
                this.answers.paymentRemark &&
                this.answers.when &&
                this.answers.dateOfBirth &&
                this.validateEmail(this.answers.email) &&
                this.answers.acceptPersonalInfo
            ) {
                return true;
            }

            return false;
        },
        question1() {
            return [
                {
                    text: this.$translate('lg_gather_loans', 'Samla lån'),
                    answerKey: 'loanFor',
                    answerValue: 'debt_consolidation',
                    icon: 'fa-down-left-and-up-right-to-center'
                },
                {
                    text: this.$translate('lg_shopping', 'Shopping'),
                    answerKey: 'loanFor',
                    answerValue: 'shopping',
                    icon: 'fa-shopping-cart'
                },
                {
                    text: this.$translate('lg_car_purchase', 'Bilköp'),
                    answerKey: 'loanFor',
                    answerValue: 'car_purchase',
                    icon: 'fa-car'
                },
                {
                    text: this.$translate('lg_travel', 'Resa'),
                    answerKey: 'loanFor',
                    answerValue: 'travel',
                    icon: 'fa-plane'
                },
                {
                    text: this.$translate('lg_renovation', 'Renovering'),
                    answerKey: 'loanFor',
                    answerValue: 'renovation',
                    icon: 'fa-paint-brush'
                },
                {
                    text: this.$translate('lg_other', 'Annat'),
                    answerKey: 'loanFor',
                    answerValue: 'other',
                    icon: 'fa-question'
                }
            ];
        },

        boolean() {
            return [
                {
                    text: this.$translate('yes', 'Ja'),
                    answerKey: 'paymentRemark',
                    answerValue: 'true',
                },
                {
                    text: this.$translate('no', 'Nej'),
                    answerKey: 'paymentRemark',
                    answerValue: 'false',
                }
            ];
        },

        when() {
            return [
                {
                    text: this.$translate('lg_no_preference', 'Spelar ingen roll'),
                    answerKey: 'when',
                    answerValue: 'no_preference',
                },
                {
                    text: this.$translate('lg_within_seven_days', 'Inom 7 dagar'),
                    answerKey: 'when',
                    answerValue: 'within_7_days',
                },
                {
                    text: this.$translate('lg_immediately', 'Direkt'),
                    answerKey: 'when',
                    answerValue: 'immediately',
                }
            ];
        }
    },
    beforeMount() {
        if (this.$route.query.for) {
            this.answers.loanFor = this.$route.query.for;
        }
    },
    methods: {
        setAnswer(key, value) {
            this.answers[key] = value;
        },

        resetErrors(key) {
            this.errors[key] = '';
        },

        validateEmail(email) {
            let reg = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            return reg.test(email);
        },
        validatePhoneNumber(phoneNumber) {
            phoneNumber = `${this.answers.phone}`; // Convert to string

            const reg = new RegExp('^[0-9]+$');
            if (!reg.test(phoneNumber)) {
                return false;
            }

            if (this.$isNorway()) {
                return (
                    (phoneNumber.startsWith('4') && phoneNumber.length === 8) ||
                    (phoneNumber.startsWith('9') && phoneNumber.length === 8) ||
                    (phoneNumber.startsWith('47') && phoneNumber.length === 10) ||
                    (phoneNumber.startsWith('+') && phoneNumber.length === 11) ||
                    (phoneNumber.startsWith('0047') && phoneNumber.length === 12) 
                );
            }

            return (
                (phoneNumber.startsWith('07') && phoneNumber.length === 10) ||
                (phoneNumber.startsWith('46') && phoneNumber.length === 11) ||
                (phoneNumber.startsWith('+') && phoneNumber.length === 12) ||
                (phoneNumber.startsWith('0046') && phoneNumber.length === 13)
            );
        },
        formatPhoneNumber(phoneNumber) {
            
            if (this.$isNorway()) {
                if (phoneNumber.startsWith('47')) {
                    return phoneNumber;
                }

                if (phoneNumber.startsWith('0047')) {
                    return phoneNumber.replace('0047', '47');
                }

                if (phoneNumber.startsWith('+47')) {
                    return phoneNumber.replace('+47', '47');
                }

                return `47${phoneNumber}`;
            }

            return phoneNumber;
        },
        submit() {
            if (this.validateAnswers) {
                this.disableSubmit = true;
                this.showResult = true;

                const payload = {
                    // eslint-disable-next-line camelcase
                    guide_id: process.env.BROKER_GUIDE_ID,
                    user: {
                        // eslint-disable-next-line camelcase
                        email: this.answers.email,
                        // eslint-disable-next-line camelcase
                        phone_number: this.formatPhoneNumber(this.answers.phone)
                    },
                    questionnaire: {
                        // eslint-disable-next-line camelcase
                        loan_reason: this.answers.loanFor,
                        // eslint-disable-next-line camelcase
                        loan_amount: Number(this.answers.amount),
                        // eslint-disable-next-line camelcase
                        monthly_income: this.answers.income,
                        // eslint-disable-next-line camelcase
                        payment_remarks: this.answers.paymentRemark,
                        // eslint-disable-next-line camelcase
                        payout_speed: this.answers.when
                    }
                };

                payload.tracking = this.$tracking.getTrackingObject();

                // TODO: Change to this.$axios etc.
                axios.post('https://api.broker.compary.com/guides', payload)
                    .then(() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                        this.$event.loanWizardSubmitted();
                        this.showResult = true;
                    })
                    .catch(() => {
                        this.disableSubmit = false;
                        this.showResult = false;
                    });
            }

            this.setErrors();
        },
        // eslint-disable-next-line complexity
        setErrors() {
            if (!this.answers.loanFor) {
                this.errors.loanFor = this.$translate('lg_loan_for_error_message', 'Du måste välja ett alternativ.');
            }

            if (this.answers.amount < 1000 || this.answers.amount > 600000) {
                this.errors.amount = this.$translate('lg_loan_amount_error_message', 'Ett belopp mellan 1 000 – 600 000 kr måste anges.');
            }

            if (!this.answers.income) {
                this.errors.income = this.$translate('lg_monthly_income_error_message', 'Ange din månadsinkomst.');
            }
             
            if (!this.answers.paymentRemark) {
                this.errors.paymentRemark = this.$translate('lg_payment_remarks_error_message', 'Du måste uppge om du har betalningsanmärkningar eller inte.');
            }

            if (!this.answers.when) {
                this.errors.when = this.$translate('lg_payout_time_error_message', 'Välj en önskad utbetalningstid.');
            }

            if (!this.answers.dateOfBirth) {
                this.errors.dateOfBirth = this.$translate('lg_date_of_birth_error_message', 'Ange vilket år du är född.');
            }

            if (!this.answers.email) {
                this.errors.email = this.$translate('lg_email_error_message', 'Ange din e-postadress.');
            }

            if (this.answers.email && !this.validateEmail(this.answers.email)) {
                this.errors.email = this.$translate('lg_enter_a_valid_email_error_message', 'Ange korrekt email format.');
            }

            if (!this.answers.phone) {
                this.errors.phone = this.$translate('lg_phone_error_message', 'Ange ditt telefonnummer.');
            }

            if (this.answers.phone && !this.validatePhoneNumber(this.answers.phone)) {
                this.errors.phone = this.$translate('lg_enter_a_valid_phone_error_message', 'Ange korrekt telefonnummer.');
            }


            if (!this.answers.acceptPersonalInfo) {
                this.errors.acceptPersonalInfo = this.$translate('lg_accept_personal_info_disclaimer_error_message', 'Du måste läsa och godkänna villkoren.');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .loan-guide::v-deep {
        padding-top: 0;

        .section-inner {
            @include grid(1, 60px);

            .loan-guide__question {
                counter-increment: counter;

                &:after {
                    content: counter(counter);
                    position: absolute;
                    top: -20px;
                    left: -20px;
                    border: 7px solid $cream;
                    @include flex;
                    border-radius: 100px;
                    font-weight: bold;
                    @include size(30px);
                    background: $green;
                    color: $white;
                }

                input { font-size: 16px!important; }
            }
        }

        .options {
            &-2 { @include grid(2, 10px); }

            &-3 {
                @include grid(1, 10px);

                @include device(pad) {
                    @include grid(3, 10px);
                }
            }

            &-6 {
                @include grid(2, 10px);

                @include device(pad) {
                    @include grid(3, 10px);
                }
            }
        }
    .accept-personal-info-disclaimer p {
        line-height: 18px;
        font-size: 12px;
    }
    .accept-personal-info-disclaimer p > a {
        color: $green;
        text-decoration: none;
    }
    .accept-personal-info-disclaimer p > a:hover {
        color: $green;
        text-decoration: underline;
    }
    }

    .loan-guide-result::v-deep {
        .lender-list {
            @include grid(1, 40px);
        }
    }

    .disabled {
        opacity: .6;
        pointer-events: none;
    }
</style>
