<template>
    <nuxt-link
        v-if="link"
        :to="link"
        class="guide-link"
    >
        <div
            :class="icon"
            class="guide-link__icon fa-solid"
        />
        <span class="guide-link__text">{{ text }}</span>
    </nuxt-link>

    <button
        v-else
        type="button"
        name="button"
        class="guide-link"
    >
        <div
            :class="icon"
            class="guide-link__icon"
        />
        <span class="guide-link__text">{{ text }}</span>
    </button>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true,
            default: String
        },
        icon: {
            type: String,
            required: true,
            default: String
        },
        link: {
            type: String,
            required: false,
            default: String
        }
    }
};
</script>

<style lang="scss" scoped>
.guide-link.active {
    color: $white;
    background: $green;
}

.guide-link {
    @include reset-link;
    @include grid(1, 20px);
    position: relative;
    min-height: 90px;
    @include spacing(padding,4);
    color: #666;
    background: $lightgray;
    text-align: center;

    &__icon {
        font-size: 22px;
        margin: auto;
    }

    &__text {
        font-family: $fontHeader;    
    }

    &:hover {
        color: $white;
        background: $green;
    }
}
</style>
