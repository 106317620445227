<template>
    <Section
        v-if="enabled"
        width="sm"
    >
        <div class="container">
            <Author
                :id="document.data.author.id"
                :show-articles="false"
                :sources="sources"
            />
        </div>
    </Section>
</template>

<script>
import Author from '@/components/slices/lists/list-items/ListItemAuthor.vue';
export default {
    components: { Author },
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        enabled() {
            return this.document.data.author.id;
        },
        sources() {
            return this.document.data.sources
                .map(iterator => iterator.source)
                .filter(source => source);
        }
    }
}
</script>

<style lang="scss" scoped>
    section::v-deep {
        .container {
            @include spacing(padding,4);
            background: $lightgray;
            box-shadow: $bxs;
            @include border-radius(5px);
        }
    }
</style>
