<template>
    <div>
        <input :id="id" v-model="checked" type="checkbox" class="checkbox">  
        <label :for="id" class="switch" />
    </div>
</template>

<script>
export default {
    props: {
        checkedStartValue: {
            type: Boolean,
            required: false,
            defualt: false
        },
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            checked: this.checkedStartValue
        };
    },
    watch: {
        checked() {
            this.$emit('updateValue', this.checked);
        }
    }
};
</script>

<style lang="scss" scoped>
    .switch {
        cursor: pointer;
        position: relative;
        display: inline-block;
        width: 40px;
        height: 20px;
        background-color: #e6e6e6;
        border-radius: 20px;
        transition: all 0.3s;
    }
    .switch::after {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius:50%;
        background-color: $white;
        top: 1px;
        left: 1px;
        transition: all 0.3s;
    }

    .checkbox:checked + .switch::after {
        left : 20px;
    }
    .checkbox:checked + .switch {
        background-color: $green;
    }
    .checkbox {
        display : none;
    }
</style>