<template>
    <div
        v-if="topList"
        :class="{ open: open }"
        class="dropdown"
    >
        <div
            class="dropdown-header"
            @click="open =! open"
        >
            <span>Topplista</span>
        </div>

        <div
            v-show="open"
            class="dropdown-body rich-text"
        >
            <ol>
                <li
                    v-for="(lender, index) in topList"
                    :key="index"
                >  
                    <a :href="$helpers.getRedirectUrl(lender, 'banner-text-toplist', index + 1)" target="_blank" rel="nofollow">
                        {{ getLenderWithData(lender.id).data.title }}
                    </a>
                </li>
            </ol>

            <button
                v-scroll-to="'#top-list'"
                class="btn btn-blue btn-md"
            >
                Se hela topplistan
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            open: false
        };
    },
    computed: {
        topList() {
            if (this.data.primary.dropdown_toplist?.id) {
                let list = this.$store.state.lists[this.data.primary.dropdown_toplist.id].data.lenders;
                return list
                    .slice(0, this.data.primary.dropdown_toplist_amount)
                    .map(iterator => iterator.lender);

            }
            return null;
        }
    },
    methods: {
        getLenderWithData(id) {

            const lender = this.$store.state.lenders[id];
            if (lender) {
                return lender;
            }

            return this.$store.state.lendersCorporate[id];
        }
    },
};
</script>

<style lang="scss" scoped>
    ol {
        @include spacing(margin,6,bottom);
        @include grid(1,0);

        li {
            a {
                @include flex(start);
                text-decoration: none;
                color: $blue;
                font-family: $fontHeader;
                &:hover { text-decoration: underline; }
            }
        }
    }
</style>
