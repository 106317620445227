<template>
    <nuxt-link v-if="post" :to="$prismic.linkResolver(post)">
        <div>
            <PrismicImage
                :lazy="false"
                :img="post.data.image"
                :alt="post.data.title"
            />
        </div>
        <div class="container-box-inner">
            <span class="item-title">{{ post.data.title }}</span>
            <span>{{ $translate('by', 'av') }}
                <span class="green text-bold">{{ author.data.name }}</span>
            </span>
        </div>
    </nuxt-link>
</template>


<script>
export default {
    props: {
        post: {
            type: Object,
            required: true
        },
    },
    computed: {
        author() {
            return this.$store.state.authors[this.post.data.author.id];
        }
    }
};
</script>


<style lang="scss" scoped>
    a {
        text-decoration: none;
        color: $font;
        &:hover { text-decoration: underline; }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .item-title {
        font-family: $fontHeader;
        font-weight: bold;
        @include font-size(20px);
    }
    .highlighted .item-title {
        @include font-size(24px);
        
        @include device(pad) {
            @include font-size(32px);
        }
    }
    .container-box-inner {
        @include spacing(padding, 4);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }
</style>
