export default {
    methods: {
        getScoreObject(score) {
            if (score > 4.5) {
                return {
                    score: score,
                    text: this.$translate('lr_best', 'Toppklass'),
                    iconClass: 'fa-solid fa-star green'
                };
            }

            if (score > 2.5) {
                return {
                    score: score,
                    text: this.$translate('lr_good', 'Bra'),
                    iconClass: 'fa-solid fa-star yellow'
                };
            }

            if (score > 1.5) {
                return {
                    score: score,
                    text: this.$translate('lr_ok', 'Lika bra'),
                    iconClass: 'fa-solid fa-star orange'
                };
            }

            return {
                score: score,
                text: this.$translate('lr_not_good', 'Inte bra'),
                iconClass: 'fa-solid fa-star red'
            };
        },
        getConnectedBanksScore(model) {
            if (model.connectedBanks > 35) return 5;
            if (model.connectedBanks > 30) return 4;
            if (model.connectedBanks > 20) return 3;
            if (model.connectedBanks > 10) return 2;

            return 1;
        },
        getApprovalRatingScore(model) {
            let conversionRate = model._core.sales_stats.conversion_rate;
            conversionRate = conversionRate * 100;
            conversionRate = conversionRate > 5 ? 5 : conversionRate;
            return Math.round(conversionRate);
        },
        getLowestInterest(model) {
            const rate = model.interestType === 'fixed'
                ? model._data.interest_fixed
                : model._data.interest_interval_min;

            return rate / 100;
        },
        getCostOf10KIn2M(model) {
            const interestCost = 10000 * (Math.pow((this.getLowestInterest(model)) + 1, 1/6) - 1);
            const otherFees = model.setupFee;

            return interestCost + otherFees;
        },
        getCostOf25KIn1Y(model) {
            const interestCost = 25000 * this.getLowestInterest(model);
            const otherFees = model.setupFee || 0;

            return interestCost + otherFees;
        },
        getCostExplanationCostExampleString(model) {

            if (model.isHighCostCredit) {
                return this.$translate('lr_cost_explanation_10k_2m', 'Ett lån på 10 000 kr i 2 månader kostar som minst {cost}')
                    .replace('{cost}', this.$format.currency(this.getCostOf10KIn2M(model))); 
            }
            return this.$translate('lr_cost_explanation_25k_1y', 'Ett lån på 25 000 kr i 1 år kostar som minst {cost}')
                .replace('{cost}', this.$format.currency(this.getCostOf25KIn1Y(model)));
        },
        getCostExplanationInterestString(lender, model) {
            return this.$translate('lr_cost_explanation_interest', '{title} erbjuder en ränta på {interestString}.')
                .replace('{title}', lender.data.title)
                .replace('{interestString}', model.interestString);
        },
        getApprovalRateExplanation(lender, model) {
            const score = this.getApprovalRatingScore(model);
            if (score >= 4) {
                return this.$translate('lr_approval_rate_explanation_good', 'Jämfört med andra lån har en hög andel av Lånen.se-användare fått sina låneansökningar godkända hos {title}. Baseras på antalet godkända låneansökningar de senaste 30 dagarna.')
                    .replace('{title}', lender.data.title);
            }

            if (score >= 2) {
                return this.$translate('lr_approval_rate_explanation_ok', '{title} godkänner ungefär lika många låneansökningar från Lånen.se-användare som andra långivare. Baseras på antalet godkända låneansökningar de senaste 30 dagarna.')
                    .replace('{title}', lender.data.title);
            }
            return this.$translate('lr_approval_rate_explanation_not_good', '{title} godkänner färre låneansökningar från Lånen.se-användare än andra långivare. Baseras på antalet godkända låneansökningar de senaste 30 dagarna.')
                .replace('{title}', lender.data.title);
        },
        getCostScore(model) {
            let cost;
            if (model.isHighCostCredit) {
                cost = this.getCostOf10KIn2M(model);
                if (cost < 400) {
                    return 5;
                }

                if (cost < 700) {
                    return 3;
                }

                if (cost < 1000) {
                    return 2;
                }

                return 1;
            }

            cost = this.getCostOf25KIn1Y(model);
            if (cost < 1000) {
                return 5;
            }

            if (cost < 1250) {
                return 3;
            }

            if (cost < 2000) {
                return 2;
            }

            return 1;

        },
        getCustomerSupportDaysOpen(model) {
            let daysOpen = 0;
            ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(day => {
                if (model.openingHours[day]) {
                    daysOpen++;
                }
            });
            return daysOpen;
        },
        getCustomerSupportScore(daysOpen) {

            if (daysOpen === 7) {
                return 4;
            }

            if (daysOpen === 0) {
                return 1;
            }

            return 3;
        }
    }
};