<template>
    <div
        v-animate="{ threshold: .3, animation: 'up-slide' }"
        class="loan-guide__question"
    >
        <span class="question-header">{{ header }}</span>
        <div class="question-options">
            <slot />
        </div>

        <div
            v-if="error"
            class="question-error"
        >
            {{ error }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        header: {
            type: String,
            required: true,
            default: String
        },
        error: {
            type: String,
            required: false,
            default: String
        }
    }
};
</script>

<style lang="scss" scoped>
    .loan-guide__question {
        position: relative;
        text-align: center;
        width: 100%;
        border-radius: 3px;
        background: $white;
        padding: 30px;
        box-shadow: $bxs;
        box-shadow: 0 6px 16px -6px rgba(0, 0, 0, .1);
        counter-increment: counter;
        margin-bottom: 40px;
        box-sizing: border-box;
        @include device(mobile) {
            padding: 25px 17px;
        }

        .question-header {
            font-family: $fontHeader;
            display: block;
            @include font-size(16px);
            @include spacing(margin, 6, bottom);

            @include device(pad) {
                @include font-size(20px);
            }
        }

        .question-options {
            width: 100%;
        }

        .question-error {
            color: #b1403d;
            background: rgba(177,64,61,.2);
            @include spacing(padding, 2);
            margin-top: 10px;
            font-weight: bold;
            font-size: 14px;
            width: 100%;
        }

        .btn {
            width: 100%;
            margin-top: 40px;
        }
    }
</style>
