<template>
    <div class="slider">
        <div class="slider-inner">
            <label>{{ label }}</label>
            <range-slider
                v-model="sliderValue"
                class="slider"
                :min="minValue"
                :max="maxValue"
                :step="increment"
            />
        </div>

        <div class="slider-amount">{{ formattedValue }}</div>
    </div>
</template>

<script>
import SliderMixins from './SliderMixins.js';
export default {
    mixins: [SliderMixins],
    
    watch: {
        sliderValue() {
            if (this.divide) this.$emit('emit', this.sliderValue / this.divide, this.calcKey);
            else this.$emit('emit', this.sliderValue, this.calcKey);
        }
    },
};
</script>

<style lang="scss">
.slider {
    font-family: $fontText;
    @include grid(2, 20px);


    span {
        display: block;
    }

    label {
        width: 100%;
        display: block;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .slider-amount {
        padding: 10px;
        border: 1px solid #e6e6e6;
        margin-left: 20px;
        border-radius: 5px;
        font-size: 14px;
        @include flex;
        font-weight: bold;
        box-shadow: $bxs;
    }

    .range-slider {
        width: 100%;
        padding: 0 1px;
    }

    .range-slider-fill {
        background: #e6e6e6;
        height: 10px;
        border-radius: 5px;
    }

    .range-slider-rail {
        height: 10px;
        background: #e6e6e6;
        border-radius: 5px;
    }

    .range-slider-knob {
        height: 25px;
        width: 25px;
        border: 2px solid #fff;
        background: $green;
        box-shadow: none;
    }
}
</style>
