<template>
    <Section width="sm">
        <h2 class="section-header">{{ $translate('pc_h2', 'Diskussion') }}</h2>
        <h3>{{ $translate('pc_sub_header_h3', 'Användarkommentarer') }}</h3>
        <ul
            v-if="comments.length"
            class="comments-list"
        >
            <li
                v-for="(comment, index) in comments"
                :key="index"
                class="comments-list__comment"
            >
                <div class="comment-header">
                    <span>{{ comment.author_name }}</span>
                    <span>{{ publicationDate(comment.created_at) }}</span>
                </div>
                <span v-html="addLineBreak(comment.comment_body)" />
            </li>
        </ul>

        <div
            v-else
            class="comments-list__empty"
        >
            <span>{{ $translate('pc_if_empty_p', 'Här finns inga kommentarer ännu.') }}</span>
        </div>

        <template v-if="!newCommentPostSuccessful">
            <h3>{{ $translate('pc_form_h3', 'Skriv en kommentar') }}</h3>
            <form
                class="comments-form"
                @submit.prevent
            >
                <div class="comments-form__container">
                    <HtmlInput
                        :label="$translate('pc_first_name_label', 'Ditt förnamn:')"
                        name="name"
                        :error="newCommentErrors.name"
                        :error-text="$translate('pc_first_name_error_message', 'Oj, du har missat att fylla i ditt namn.')"
                        @set="set"
                        @clearError="clearError"
                    />
                </div>

                <div class="comments-form__container">
                    <HtmlTextarea
                        :label="$translate('pc_comment_label', 'Din kommentar:')"
                        name="comment"
                        :error="newCommentErrors.comment"
                        :error-text="$translate('pc_comment_error_message', 'Din kommentar måste vara minst 20 tecken lång.')"
                        @set="set"
                        @clearError="clearError"
                    />
                </div>
                <div class="comments-form__container" style="display: flex; flex-wrap: wrap;">
                    <button
                        class="comments-form__submit btn btn-md btn-green"
                        @click="postComment"
                    >
                        {{ $translate('pc_submit_form', 'Kommentera') }}
                    </button>
                </div>
            </form>
            <div v-if="$translate('pc_form_footer_p')" class="comments-footer" v-html="$prismic.asHtml($translate('pc_form_footer_p'))" />
        </template>
        <template v-else>
            <div class="comment-succesful">
                <h2>{{ $translate('pc_form_success_message_h2', 'Tack för din kommentar!') }}</h2>
                <p> {{ $translate('pc_form_success_message_p', ' Din kommentar kommer snart att publiceras.') }}</p>
            </div>
        </template>
    </Section>
</template>

<script>
import axios from 'axios';
import HtmlInput from '@/components/html/HtmlInput.vue';
import HtmlTextarea from '@/components/html/HtmlTextarea.vue';
export default {
    components: { HtmlInput, HtmlTextarea },
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            newComment: {
                name: '',
                comment: ''
            },
            newCommentErrors: {
                name: false,
                comment: false
            },
            comments: [],
            externalId: this.document.id,
            newCommentPostSuccessful: false
        };
    },
    async fetch() {
        try {
            // TODO: Change to this.$axios etc.
            const exportApi = 'https://api.core.compary.com/api/v0/export/comments';
            const response = await axios.get(`${exportApi}?external_id=${this.externalId}`);
            this.comments = response.data.results;
        }
        catch (error) {
            console.log(error);
        }

    },
    methods: {
        set(key, value) {
            this.newComment[key] = value;
        },

        clearError(key) {
            this.newCommentErrors[key] = false;
        },

        addLineBreak(string) {
            return string.replace(/\n/g, "<br />");
        },

        publicationDate(date) {
            return date.split('T')[0];
        },

        postComment() {

            const api = 'https://api.core.compary.com/api/v0/comments';

            const payload = {
                'external_id': this.externalId,
                'source_id': process.env.CORE_SOURCE_ID,
                'author_name': this.newComment.name,
                'comment_body': this.newComment.comment,
            };

            // NOTE: Maybe we can do this better?
            if (!this.newComment.name.length) this.newCommentErrors.name = true
            if (this.newComment.comment.length < 20) this.newCommentErrors.comment = true

            if (!this.newCommentErrors.name && !this.newCommentErrors.comment) {
                // TODO: Change to this.$axios etc.
                axios.post(api, payload)
                    .then(response => {
                        this.$event.commentSubmitted();
                        this.newCommentPostSuccessful = true;
                    })
                    .catch(error => {
                        this.newCommentPostSuccessful = false;
                    });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    section::v-deep {

        h3 {
            @include spacing(margin,6,bottom);
        }

        .comments-list {
            @include spacing(margin,10,bottom);
            @include grid(1,20px);

            &__comment,
            &__empty {
                @include spacing(padding,4);
                background: $white;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
                @include border-radius(5px);
            }

            &__comment {
                @include grid(1, 20px);


                .comment-header {
                    @include flex(between);
                    span:first-child {
                        flex: 1;
                        padding-right: 20px;
                        font-family: $fontHeader;
                    }
                    span:last-child {
                        @include font-size(14px);
                        color: #999;
                    }
                }
            }

            &__empty {
                @include spacing(margin,10,bottom);

                span {
                    @include font-size(16px);
                    font-style: italic;

                    @include device(pad) { @include font-size(20px); }
                }
            }
        }

        .comments-form {
            @include spacing(padding,6);
            background: $white;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
            @include border-radius(5px);
            @include grid(1, 40px);

            &__container {
                .html-input {
                    @include device(pad) {
                        @include flex(start,start);
                    }

                    label {
                        font-family: $fontHeader;
                        padding: 15px 0;
                        flex: 1;

                        @include device(mobile) {
                            display: block;
                        }
                    }

                    input,
                    textarea {
                        @include border-radius(5px);
                        @include border;
                        font-family: $fontText;
                        font-size: 16px;
                        flex: 2;
                        resize: vertical;
                        padding: 15px;

                        @include device(mobile) {
                            width: 100%;
                        }
                    }
                }
            }

            &__submit {
                margin: auto;
                width: 100%;
                max-width: 300px;
            }
        }

        .comments-footer, .comments-footer p {
            margin-top: 20px;
            @include font-size(12px, 6px);
            text-align: center;
            color: #999;

            a {
                text-decoration: none;
                font-family: $fontHeader;
                color: $green;
                &:hover { text-decoration: underline; }
            }
        }

        .comment-succesful {
            @include spacing(padding,6);
            background: $white;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
            @include border-radius(5px);

            h2 {
                color: $green;
                @include spacing(margin,6,bottom);
            }

            p {
                @include font-size(20px);
            }
        }
    }
</style>
