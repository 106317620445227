<template>
    <Section
        class="list bg-cream"
        width="lg"
    >
        <h2 class="section-header">{{ $prismic.asText(data.primary.h2) }}</h2>

        <ul>
            <Page
                v-for="(page, index) in data.items"
                :key="index"
                :data="page"
            />
        </ul>
    </Section>
</template>

<script>
import Page from '@/components/slices/lists/list-items/ListItemPage.vue';
export default {
    components: { Page },
    props: {
        data: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
ul {
    @include grid(1, 40px);
    width: 100%;

    @include device(pad) {
        @include grid(2, 30px);
    }

    @include device(desktop) {
        @include grid(3, 30px);
    }
}
</style>
