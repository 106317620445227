<template>
    <div v-if="mortgageLayout" class="list-icons">
        <div
            v-if="model.isBroker && model.connectedBanks > 0"
            class="list-icons-inner"
        >
            <i class="fa-solid fa-building-columns" />
            <span>
                {{ 
                    $translate('connected_banks_data', '{amountOfBanks} anslutna långivare')
                        .replace('{amountOfBanks}', model.connectedBanks)
                }}
            </span>
        </div>
        <div
            v-if="model.maxLTVRatioString"
            class="list-icons-inner"
        >
            <i class="fa-sharp fa-solid fa-sack-dollar" />
            <span>
                {{ $translate('loan_up_to_max_ltv', 'Upp till {maxLTV} belåningsgrad')
                    .replace('{maxLTV}', model.maxLTVRatioString) }}
            </span>
        </div>

        <div
            v-if="model.acceptsPaymentRemarks"
            class="list-icons-inner"
        >
            <i class="fa-solid fa-flag" />
            <span>{{ $translate('ld_payment_remarks_are_accepted', 'Betalningsanmärkningar accepteras') }}</span>
        </div>
    </div>

    <div v-else class="list-icons">
        <div
            v-if="model.isBroker && model.connectedBanks > 0"
            class="list-icons-inner"
        >
            <i class="fa-solid fa-building-columns" />
            <span>
                {{ 
                    $translate('connected_banks_data', '{amountOfBanks} anslutna långivare')
                        .replace('{amountOfBanks}', model.connectedBanks)
                }}
            </span>
        </div>

        <div
            v-if="$isSweden() && model.isBroker && model.takesUC"
            class="list-icons-inner"
        >
            <i class="fa-solid fa-eye" />
            <span>{{ $translate('only_one_uc', 'Endast 1 UC') }}</span>
        </div>
        <div
            v-if="model._core.sales_stats.conversion_rate > 0.035"
            class="list-icons-inner"
        >
            <i class="fa-solid fa-thumbs-up" />
            <span>{{ $translate('low_credit_demands', 'Låga kreditkrav') }}</span>
        </div>

        <div
            v-if="model.acceptsPaymentRemarks"
            class="list-icons-inner"
        >
            <i class="fa-solid fa-flag" />
            <span>{{ $translate('ld_payment_remarks_are_accepted', 'Betalningsanmärkningar accepteras') }}</span>
        </div>

        <div
            v-if="$isSweden() && !model.takesUC"
            class="list-icons-inner"
        >
            <i class="fa-solid fa-eye-slash" />
            <span>{{ $translate('without_uc', 'Utan UC') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        },
        model: {
            type: Object,
            required: true,
            default: () => {}
        },
        mortgageLayout: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
    .list-icons {
        width: 100%;
        @include flex;
        background: $lightgray;
        @include border(top);

        &-inner {
            @include spacing(padding, 2);
            font-size: 12px;
            width: 100%;
            @include flex;
            font-family: $fontHeader;
            i {
                color: $green;
                margin-right: 10px;
            }

            @include device(pad) {
                @include spacing(padding, 4);
                width: auto;
            }
        }
    }
</style>
