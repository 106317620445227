<template>
    <div
        v-if="tableTitles.length"
        class="table-container mobile-scroll-shadow"
    >
        <table class="table-container__table">
            <tbody>
                <tr v-if="showLogos">
                    <th>
                        {{ $translate('lender', 'Långivare') }}
                    </th>
                    <td 
                        v-for="(lender, index) in lenders" 
                        :key="index"
                    >
                        <prismic-image 
                            v-if="lender"
                            :img="lender.data.image"
                            w="100"
                        />
                    </td>
                </tr>
                <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
                    <th>
                        {{ tableTitles[rowIndex] }}
                    </th>
                    <td 
                        v-for="(cell, cellIndex) in row" 
                        :key="cellIndex"
                    >
                        <div 
                            class="rich-text" 
                            v-html="cell" 
                        />
                    </td>
                </tr>
                <tr v-if="showOutLinks">
                    <th>
                        {{ $translate('application_label', 'Ansök') }}
                    </th>
                    <td 
                        v-for="(lender, index) in lenders" 
                        :key="index"
                    >
                        <ButtonTarget 
                            v-if="lenders[index]" 
                            :link="lenders[index]" 
                            :text="$translate('cta_to_application', 'Till ansökan')"
                            background="green"
                            size="md" 
                            class="application-button"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import CustomizableTable from './mixins/CustomizableTable.js';
export default {
    mixins: [
        CustomizableTable
    ],
    computed: {
        rows() {
            const rows = [];

            for (let i = 0; this.tableTitles.length > i; i++) {
                const rowValues = this.tableData
                    .map(row => row[i]);
                rows.push(rowValues);
            }
            return rows;
        }
    }
};
</script>