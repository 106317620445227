<template>
    <li>
        <nuxt-link :to="$prismic.linkResolver(data)">
            <div class="bold">{{ data.data.title }}</div>
            <div class="published">
                <span>{{ $translate('published', 'Publicerad') }}</span>
                <div class="bold date">
                    <i class="fa-solid fa-calendar-days" />
                    {{ publicationDate }}
                </div>
            </div>
        </nuxt-link>
    </li>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        publicationDate() {
            return this.$format.date(this.data.first_publication_date);
        }
    }
}
</script>

<style lang="scss" scoped>
a {
    @include flex(between);

    div {
        flex: 1;

        &:first-child { text-align: left; }
        &:last-child { text-align: right; }
    }

    i { color: $green; }

    .date {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: 3px;
    }
}
</style>
