<template>
    <Section
        id="scolarship-form"
        class=""
        width="sm"
    >
        <h2 class="section-header">{{ $prismic.asText(scholarship.h2) }}</h2>
        <form v-if="!success" class="form" @submit.prevent="submit">
            <div v-if="errorForm" class="html-input__error">
                {{ scholarship.error_message }}
            </div>

            <div class="form-container">
                <label>{{ scholarship.name_label }}</label>
                <input v-model="name" :placeholder="scholarship.name_placeholder">
            </div>

            <div class="form-container">
                <label>{{ scholarship.email_label }}</label>
                <input v-model="email" :placeholder="scholarship.email_placeholder">
            </div>

            <div class="form-container">
                <label>{{ scholarship.school_name_label }}</label>
                <input v-model="schoolName">
            </div>
            <div class="form-container">
                <label for="message">{{ scholarship.short_summary_label }}</label>
                <textarea v-model="summary" name="message" rows="8" />
            </div>

            <div class="form-container">
                <label for="message">{{ scholarship.justification_label }}</label>
                <textarea v-model="motivation" name="message" rows="8" />
            </div>


            <div v-if="errorFile" class="html-input__error">
                {{ scholarship.upload_file_error_message }}
            </div>
            <div class="form-container">
                <label for="message">{{ scholarship.upload_file_label }}</label>
                <input ref="file" type="file" @change="uploadFile">
            </div>

            <button
                type="submit"
                class="form-submit btn btn-md btn-green"
            >
                {{ scholarship.submit_form }}
            </button>
        </form>
        <div v-else class="form">
            <h3>{{ scholarship.success_message_h3 }}</h3>
        </div>
    </Section>
</template>
<script>
import axios from 'axios';
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() { 
        return {
            success: false,
            name: '',
            email: '',
            schoolName: '',
            summary: '',
            motivation: '',
            file: null,
            errorForm: false,
            errorFile: false
        };
    },
    computed: {
        message() {
            return `
                Namn: ${this.name}
                Email: ${this.email}
                Namn på skola: ${this.schoolName}
                
                Sammanfattning: 
                ${this.summary}
                
                Motivering: 
                ${this.motivation}
            `;
        },
        scholarship() {
            return this.data.primary;
        }
    },
    methods: {
        uploadFile() {
            const files = this.$refs.file.files;
            if (files.length > 0) {
                this.file = files[0];
            }
            else {
                this.file = null;
            }
        },
        submit() {

            this.errorForm = false; 
            this.errorFile = false;

            if (!(this.name && this.email && this.schoolName && this.motivation && this.summary)) {
                this.errorForm = true;
                return;
            }

            if (!this.file) {
                this.errorFile = true;
                return;
            }

            const data = new FormData();
            data.append('message', this.message);
            data.append('attachment', this.file);

            axios
                .post('https://formspree.io/f/xnqwyoop', data, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then(() => {
                    this.success = true;
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }
};
</script>