<template>
    <Section
        v-if="otherLenderIds.length > 0"
        class="other-brands bg-green"
        width="sm"
    >
        <h2> 
            {{ 
                $translate('lp_other_brands', 'Andra varumärken från {companyName}')
                    .replace('{companyName}', $getCompanyName(document)) 
            }} 
        </h2>
        <div class="grid-container">
            <nuxt-link
                v-for="(id, index) in otherLenderIds"
                :key="index"
                :to="$prismic.linkResolver(lenderData(id))"
                class="grid-container__item"
            >
                <div class="item-container">
                    <PrismicImage
                        :img="lenderData(id).data.image"
                        w="120"
                    />
                </div>

                <div class="item-container">
                    <span>{{ $translate('interest', 'Ränta') }}</span>
                    <span>{{ lenderModel(lenderData(id)).interestString }}</span>
                </div>

                <div class="item-container">
                    <span>{{ $translate('max_amount', 'Maxbelopp') }}</span>
                    <span>{{ lenderModel(lenderData(id)).maxAmountString }}</span>
                </div>
            </nuxt-link>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        otherLenderIds() {
            let otherLenderIds = [];
            const registrationNumber = this.$getRegistrationNumber(this.document);
            if (!registrationNumber) {
                return [];
            }
            Object.values(this.$store.state.lenders).forEach(lender => {
                if (lender.id !== this.document.id) {
                    // only add other lenders
                    if (registrationNumber === this.$getRegistrationNumber(lender)) {
                        otherLenderIds.push(lender.id);
                    }
                }
            });
            
            return otherLenderIds;
        }
    },
    methods: {
        lenderData(id) {
            return this.$store.state.lenders[id];
        },
        lenderModel(lender) {
            return new this.$models.Loan(lender, this.$store);
        }
    }
};
</script>

<style lang="scss" scoped>
section::v-deep {
    h2 {
        color: $white;
        text-align: center;
        @include spacing(margin,6,bottom);
    }

    .grid-container {
        padding-top: 40px;
        margin-top: 20px;
        @include grid(1, 15px);
        @include border(top, 1px, rgba(255,255,255, .5));

        @include device(pad) {
            @include grid(1, 30px);
        }

        &__item {
            background: $white;
            @include border-radius(5px);
            color: $font;
            @include grid(1, 0px);
            text-decoration: none;
            transition: .15s ease-in-out;
            &:hover { transform: scale(1.025); }

            @include device(pad) {
                @include flex;
            }

            .item-container {
                @include spacing(padding,6);
                flex: 1;
                text-align: center;

                &:nth-child(1) { @include flex; }
                &:nth-child(2),
                &:nth-child(3) { @include grid(1, 15px); }

                img { height: auto; }

                span {
                    font-family: $fontText;
                    @include font-size(14px,0px);
                    &:last-child { font-family: $fontHeader; }
                }
            }
        }
    }
}
</style>
