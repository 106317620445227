<template>
    <div class="table-wrapper" :class="{'mobile-scroll-shadow': lenderIds.length > 2}">
        <table v-if="lenderIds && lenderIds.length">
            <thead>
                <tr>
                    <th />
                    <td v-for="lender in lenders" :key="lender.id">
                        <div class="lender-header">
                            <nuxt-link :to="$prismic.linkResolver(lender)">
                                <prismic-image
                                    :img="lender.data.image"
                                    w="100"
                                />
                            </nuxt-link>
                            <div class="title-and-remove-button-wrapper">
                                <span>{{ lender.data.title }}</span>
                                <i 
                                    class="fa-solid fa-xmark remove-lender-button" 
                                    role="button" 
                                    @click="$emit('removeLender', lender.id)" 
                                />
                            </div>
                        </div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(dataRow, key) in tableData" :key="key">
                    <th>{{ dataRow.title }}</th>
                    <td 
                        v-for="(rowData, rowDataIndex) in dataRow.data" 
                        :key="lenderIds[rowDataIndex]"
                    >
                        <div class="mobile-data-title">{{ dataRow.title }}</div>
                        <span :class="{'highlighted': rowData.highlighted}">
                            {{ rowData.value }}
                            <tooltip 
                                v-if="rowData.showTooltip" 
                                :class="[
                                    {'first-column': rowDataIndex === 0},
                                    {'last-column': rowDataIndex === dataRow.data.length - 1}
                                ]" 
                                :data="rowData.tooltipData" 
                            />
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>{{ $translate('cl_apply_table_header', 'Ansök') }}</th>
                    <td v-for="lender in lenders" :key="lender.id">
                        <ButtonTarget
                            :link="lender"
                            :text="$translate('cta_apply_direct', 'Ansök direkt')"
                            size="md"
                            background="green"
                            class="cta-button"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-else class="no-lenders-container">
            <span class="header">{{ $translate('cl_no_lenders_header', 'Inga långivare valda') }}</span>
            <span class="description">
                {{ 
                    $translate('cl_no_lenders_description', 'Klicka på "{buttonCta}" för att börja jämföra!') 
                        .replace('{buttonCta}', $translate('cl_add_lenders_button_cta', 'Lägg till lån'))
                }}
            </span>
        </div>
    </div>
</template>

<script>
import { capitalize } from 'lodash';
import { Tooltip } from '@swegaming-ab/vue-components';
export default {
    components: {
        Tooltip
    },
    props: {
        lenderIds: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    computed: {
        lenders() {
            return this.lenderIds.map(id => {
                const lender = Object.assign({}, this.$store.state.lenders[id]);
                lender.model = new this.$models.Loan(lender, this.$store);
                return lender;
            });
        },
        tableData() {
            const tableData = {
                amount: {
                    title: this.$translate('amount', 'Belopp'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.amountString
                        };
                    })
                },
                interest: {
                    title: this.$translate('interest', 'Ränta'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.interestString,
                            highlighted: lender.model.minInterestPossible === this.bestInterest
                        };
                    })
                },
                effectiveInterest: {
                    title: this.$translate('effective_interest', 'Effektiv ränta'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.effectiveInterestString,
                            highlighted: lender.model.minEffectiveInterestPossible === this.bestEffectiveInterest
                        };
                    })
                },
                duration: {
                    title: this.$translate('duration', 'Löptid'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.getDurationString()
                        };
                    })
                },
                minAge: {
                    title: this.$translate('ld_age_limit_label', 'Ålderskrav'),
                    data: this.lenders.map(lender => {
                        return {
                            value: this.$translate('ld_age_limit_value', '{minAge} eller äldre')
                                .replace('{minAge}', lender.model.minAge),
                            highlighted: lender.model.minAge === this.bestMinAge
                        };
                    })
                },
                setupFee: {
                    title: this.$translate('setup_fee', 'Uppläggningsavgift'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.setupFeeString,
                            highlighted: lender.model.setupFee === this.bestSetupFee
                        };
                    })
                },
                creditCheck: {
                    title: this.$translate('credit_check', 'Kreditupplysning'),
                    data: this.lenders.map(lender => {
                        return {
                            value: capitalize(lender.model.creditCheck),
                        };
                    }),
                    disabled: this.$isNorway()
                },
                amountOfBanks: {
                    title: this.$translate('connected_banks', 'Anslutna långivare'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.isBroker 
                                ? this.$translate('connected_banks_data', '{amountOfBanks} anslutna långivare')
                                    .replace('{amountOfBanks}', lender.model.connectedBanks)
                                : this.$translate('is_not_broker', 'Är inte låneförmedlare'),
                            highlighted: lender.model.connectedBanks === this.bestAmountOfBanks
                        };
                    })
                },
                directPayments: {
                    title: this.$translate('direct_payout', 'Direktutbetalning'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.hasDirectPayments24Hours
                                ? this.$translate('twentyfour_seven', 'Dygnet runt')
                                : lender.model.hasDirectPayments
                                    ? this.$translate('yes', 'Ja')
                                    : this.$translate('no', 'Nej'),
                            showTooltip: this.$getDirectPaymentBanks(lender.model).length,
                            tooltipData: this.$translate('direct_payout_to_bank', 'Direktutbetalning till {bank}')
                                .replace('{bank}', this.$getDirectPaymentBanks(lender.model).join(', '))
                        };
                    })
                },
                paymentRemarks: {
                    title: this.$translate('ld_payment_remarks_label', 'Betalningsanmärkningar'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.acceptsPaymentRemarks 
                                ? this.$translate('accepted', 'Accepteras') 
                                : this.$translate('not_accepted', 'Accepteras inte'),
                            highlighted: lender.model.acceptsPaymentRemarks

                        };
                    })
                },
                userRatings: {
                    title: this.$translate('rating', 'Betyg'),
                    data: this.lenders.map(lender => {
                        return {
                            value: this.$translate('average_rating_score', '{averageRating} av 5 ({amount} omdömen)')
                                .replace('{averageRating}', this.$format.number(lender.model._ratings.average_score, 1))
                                .replace('{amount}', lender.model._ratings.count),
                            highlighted: lender.model._ratings.average_score === this.bestRating
                        };
                    })
                },
            };
            return Object.fromEntries(
                Object.entries(tableData)
                    .filter(([, value]) => !value.disabled)
            );
        },
        bestInterest() {
            return Math.min(...this.lenders.map(lender => lender.model.minInterestPossible));
        },
        bestEffectiveInterest() {
            return Math.min(...this.lenders.map(lender => lender.model.minEffectiveInterestPossible));
        },
        bestMinAge() {
            return Math.min(...this.lenders.map(lender => lender.model.minAge));
        },
        bestSetupFee() {
            return Math.min(...this.lenders.map(lender => lender.model.setupFee));
        },
        bestRating() {
            return Math.max(...this.lenders.map(lender => lender.model._ratings.average_score)) || false;
        },
        bestAmountOfBanks() {
            return Math.max(...this.lenders.map(lender => lender.model.connectedBanks)) || false;
        },
    },
};
</script>

<style lang="scss" scoped>
    .table-wrapper {
        border-top: 8px solid $green;
        margin-top: 20px;
        overflow-x: auto;
        table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
            thead {
                td {
                    min-width: 165px;
                    .lender-header {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        text-decoration: none;
                        a {
                            width: fit-content;
                        }
                        .title-and-remove-button-wrapper {
                            display: flex;
                            flex-wrap: nowrap;
                            gap: 5px;
                            span {
                                color: $font;
                                font-weight: bold;
                                line-height: 18px;
                            }
                            .remove-lender-button {
                                cursor: pointer;
                                vertical-align: middle;
                                font-size: 18px;
                                color: #999;
                                &:hover {
                                    color: $red;
                                }
                            }
                        }
                    }
                }
            }
            tr {
                background-color: $white;
                &:nth-child(even) {
                    background-color: $lightgray;
                }
            }
            th, td {
                padding: 12px;
                border-bottom: 1px solid #f3f3f3;
                border-right: 1px solid #f3f3f3;
            }
            th {
                border-left: 1px solid #f3f3f3;
                background: #f3f3f3;
                z-index: 1;
                display: none;
                @include device(pad) {
                    display: table-cell;
                }
                text-align: left;
                white-space: nowrap;
                width: 1%;
                background: inherit !important;
                position: sticky;
                left: 0;
            }
            td:first-of-type {
                border-left: 1px solid #f3f3f3;
                @include device(pad) {
                    border-left: none;
                }
            }
        }
    }
    .highlighted {
        color: $green;
        font-weight: bold;
    }
    .cta-button {
        display: block;
        max-width: 150px;
        padding: 15px;
    }
    .mobile-data-title {
        font-size: 12px;
        font-weight: bold; 
        margin-bottom: 5px;
        @include device(pad) {
            display: none;
        }
    }
    ::v-deep {
        .tooltip {
            i {
                margin: 0;
            }
            .tooltip-open {
                min-width: 150px !important;
                right: 0;
                transform: translateX(50%);
                bottom: calc(100% + 5px);
            }
            @include device(mobile) {
                &.first-column {
                    .tooltip-open {
                        left: calc(100% - 15px);
                        right: unset;
                        transform: unset;
                    }
                }
                &.last-column {
                    .tooltip-open {
                        right: calc(100% - 15px);
                        transform: unset;
                    }
                }
            }
        }
    }
    .no-lenders-container {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        .header {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 16px;
        }
    }
</style>