<template>
    <Section
        class="faq"
        width="sm"
    >
        <h2
            v-if="$validateText(data.primary.h2)"
            class="section-header"
        >
            {{ $prismic.asText(data.primary.h2) }}
        </h2>
        <faq-layout :faq="data.items" />
    </Section>
</template>

<script>
import FaqLayout from '@/components/FaqLayout.vue';
export default {
    components: { FaqLayout },
    props: {
        data: {
            type: Object,
            required: true
        }
    }
};
</script>