<template>
    <Section
        class="section-image-carousel"
        width="sm"
    >
        <div 
            v-if="$validateText(data.primary.text)"
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.text)"
        />
        <div v-if="showFullscreenImage" class="image-fullscreen">
            <div class="popup-container">
                <i class="fa-light fa-circle-xmark fa-2x close-button white" @click="showFullscreenImage = false" />
                <prismic-image 
                    :img="activeImg"
                    :alt="activeImg.alt"
                    :w="imageWidth(activeImg)"
                    class="image-popup"
                />
            </div>
            <div
                class="fullscreen-overlay"
                @click.stop="showFullscreenImage = false"
            />
        </div>
        <div class="wrapper">
            <div ref="swiper" class="swiper-container">
                <div class="swiper-wrapper">
                    <div 
                        v-for="(item, index) in items" 
                        :key="index" 
                        class="swiper-slide"
                        @click="selectImage(item.carousel_image)"
                    >
                        <prismic-image 
                            :img="item.carousel_image"
                            :alt="item.carousel_image.alt"
                            :w="imageWidth(item.carousel_image)"
                        />
                        <i class="fa-solid fa-up-right-and-down-left-from-center fa-lg white enlarge-icon" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Swiper from 'swiper';

import 'swiper/swiper-bundle.min.css';
export default {
    props: {
        data: {
            type: Object,
            required: true
        },
    },
    data: () => ({
        activeImg: {},
        showFullscreenImage: false,
    }),
    computed: {
        items() {
            return this.data.items
                .filter(item => item.carousel_image.url);
        },
    },
    mounted() {
        const swiper = new Swiper(this.$refs.swiper, {
            spaceBetween: 10,
            grabCursor: true,
            slidesPerView: 1.2,
            breakpoints: {
                600: {
                    slidesPerView: 1.5,
                },
            }
            
        });
        return swiper;
    },
    methods: {
        imageWidth(image) {
            return image.dimensions.width > 1000 ? '1000' : `${image.dimensions.width}`;
        },
        selectImage(payload) {
            const swiperInstance = this.$refs.swiper.swiper;
            if (swiperInstance.clickedIndex !== swiperInstance.activeIndex){
                swiperInstance.slideTo(swiperInstance.clickedIndex);
            }
            else {
                this.activeImg = payload;
                this.showFullscreenImage = true;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.section-image-carousel {
    ::v-deep .section-inner {
        z-index: unset;
    }

    .image-fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        @include size(100vw,100vh);
        display: flex;
        justify-content: center;
        align-items: center;

        .popup-container{
            @include spacing(padding,4);
            width: 100%;
            max-width:max-content;
            z-index: 1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            display: flex;
            justify-content: center;
        
            .image-popup {
                width: 90%;
                max-height: 95vh;
                @include device(pad) {
                    width: 100%;
                    max-height: 85vh;
                    @include spacing(padding,4);
                }
            }
            .close-button {
                position: absolute;
                top: -20px;
                right: 16px;
                cursor: pointer;
                @include device(pad) {
                    top: -20px;
                    right: 30px;
                }
            }
        }
        .fullscreen-overlay {
            position: fixed;
            width:100%;
            height:100%;
            top: 0;
            left: 0;
            background: rgba(0,0,0,.5);
            }
    }
    .wrapper {
        overflow: hidden;

        .swiper-container {
            width: 100%;
            
            .swiper-wrapper {
                align-items: flex-start;

                .swiper-slide {
                    width:100%;
                    display: flex;
                    justify-content: center;

                    opacity: .4;
                    transition: opacity .3s ease-in-out;
                    border-radius: 15px;
                    img {
                        width:auto;
                        max-width:100%;
                    }
                    .enlarge-icon {
                        display: none;
                    }
                }
                .swiper-slide-active {
                    opacity: 1; 
                    .enlarge-icon {
                        @include device(pad) {
                            display: block;
                            position: absolute;
                            bottom:15px;
                            right:10px;
                            &::before {
                                background-color: rgba(0, 0, 0, 0.7);
                                border-radius: 10%;
                                padding: 4px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>