<template>
    <Section width="md">
        <div class="section-heading">
            <h2 class="section-header">{{ contactH2 }}</h2>
            <div 
                v-if="$validateText(data.primary.connected_lenders_conclusion_teaser)" 
                class="rich-text"
                v-html="$prismic.asHtml(data.primary.connected_lenders_conclusion_teaser)" 
            />
        </div>
        <div class="tables-container">
            <div 
                v-for="(tableData, index) in tablesData" 
                :key="index"
            >
                <h3 class="sub-section-header">{{ tableData.title }}</h3>
                <div class="dynamic-table-container mobile-headers-in-cell">
                    <table>
                        <thead>
                            <tr>
                                <th />
                                <td v-for="lender in lenders" :key="lender.id">
                                    {{ lender.data.title }}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(rowData, rowIndex) in tableData.data" :key="rowIndex">
                                <th>
                                    {{ rowData.title }}
                                </th>
                                <td v-for="(value, lenderIndex) in rowData.values" :key="lenderIndex">
                                    <div class="mobile-data-title">{{ rowData.title }}</div>
                                    {{ value }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        lenders: {
            type: Array,
            required: true
        }
    },
    computed: {
        contactH2() {
            if (this.$validateText(this.data.primary.contact_h2)) {
                return this.$prismic.asText(this.data.primary.contact_h2);
            }
            return this.$translate('ctl_contact_h2', 'Kontakt & öppettider')
                .replace('{lender1}', this.lenders[0].data.title)
                .replace('{lender2}', this.lenders[1].data.title);
        },
        companyInfoH3() {
            if (this.$validateText(this.data.primary.contact_company_info_h3)) {
                return this.$prismic.asText(this.data.primary.contact_company_info_h3);
            }
            return this.$translate('ctl_contact_company_info_h3', 'Företaget')
                .replace('{lender1}', this.lenders[0].data.title)
                .replace('{lender2}', this.lenders[1].data.title);
        },
        openingHoursH3() {
            if (this.$validateText(this.data.primary.contact_opening_hours_h3)) {
                return this.$prismic.asText(this.data.primary.contact_opening_hours_h3);
            }
            return this.$translate('ctl_contact_opening_hours_h3', 'Öppettider')
                .replace('{lender1}', this.lenders[0].data.title)
                .replace('{lender2}', this.lenders[1].data.title);
        },
        tablesData() {
            return [
                {
                    title: this.companyInfoH3,
                    data: this.companyTableData,
                },
                {
                    title: this.openingHoursH3,
                    data: this.openingHoursTableData,
                }
            ];
        },
        companyTableData() {
            return [
                {
                    title: this.$translate('company_name', 'Företagsnamn'),
                    values: this.lenders.map(lender => lender.model._data.company_name)
                },
                {
                    title: this.$translate('company_phone', 'Telefon'),
                    values: this.lenders.map(lender => lender.model._data.company_phone)
                },
                {
                    title: this.$translate('company_email', 'E-post'),
                    values: this.lenders.map(lender => lender.model._data.company_email)
                },
                {
                    title: this.$translate('company_address', 'Adress'),
                    values: this.lenders.map(lender => lender.model._data.company_addres)
                },
            ];
        },
        openingHoursTableData() {
            return [
                {
                    title: this.$translate('monday', 'Måndag'),
                    values: this.lenders.map(lender => lender.model._data.opening_hours_monday_open + ' - ' + lender.model._data.opening_hours_monday_close)
                },
                {
                    title: this.$translate('tuesday', 'Tisdag'),
                    values: this.lenders.map(lender => lender.model._data.opening_hours_tuesday_open + ' - ' + lender.model._data.opening_hours_tuesday_close)
                },
                {
                    title: this.$translate('wednesday', 'Onsdag'),
                    values: this.lenders.map(lender => lender.model._data.opening_hours_wednesday_open + ' - ' + lender.model._data.opening_hours_wednesday_close)
                },
                {
                    title: this.$translate('thursday', 'Torsdag'),
                    values: this.lenders.map(lender => lender.model._data.opening_hours_thursday_open + ' - ' + lender.model._data.opening_hours_thursday_close)
                },
                {
                    title: this.$translate('friday', 'Fredag'),
                    values: this.lenders.map(lender => lender.model._data.opening_hours_friday_open + ' - ' + lender.model._data.opening_hours_friday_close)
                },
                {
                    title: this.$translate('saturday', 'Lördag'),
                    values: this.lenders.map(lender => lender.model._data.opening_hours_saturday_open + ' - ' + lender.model._data.opening_hours_saturday_close)
                },
                {
                    title: this.$translate('sunday', 'Söndag'),
                    values: this.lenders.map(lender => lender.model._data.opening_hours_sunday_open + ' - ' + lender.model._data.opening_hours_sunday_close)
                }
            ];
        }
    }
}
</script>

<style lang="scss" scoped>
.tables-container {
    display: flex;
    flex-direction: column;
    gap: 40px;    
}
thead {
    td {
        min-width: 100px !important;
        font-weight: bold;
    }
}
</style>