<template>
    <Section width="sm"> 
        <h2 v-if="$validateText(data.primary.h2)" class="section-header">
            {{ $prismic.asText(data.primary.h2) }}
        </h2>
        <div class="section-content">
            <div v-if="$validateText(data.primary.teaser)" class="rich-text" v-html="$prismic.asHtml(data.primary.teaser)" />
            <div v-if="data.primary.show_toplist" class="rich-text">
                <ol>
                    <toplist-item
                        v-for="(item, index) in data.items" 
                        :key="index" 
                        :lender-id="item.lender.id"
                        :lender-type="item.lender.type"
                        :text="item.toplist_text"
                    />
                </ol>
            </div>
        </div>
        <ol class="list">
            <li v-for="(item, index) in items" :key="index" class="list-item">
                <nuxt-link
                    :to="$prismic.linkResolver(item.lender)"
                    class="list-item-header"
                >
                    <h3>{{ index + 1 }}. {{ item.h3 }}</h3>
                    <div class="img-wrapper">
                        <PrismicImage
                            :img="item.lender.data.image"
                            w="100"
                            :alt="item.lender.data.title"
                        />
                    </div>
                </nuxt-link>
                <div v-if="$validateText(item.content)" class="rich-text" v-html="$prismic.asHtml(item.content)" />
                <div class="table-container">
                    <table class="table-container__table">
                        <tbody>
                            <tr v-for="(tableRowData, rowIndex) in item.tableData" :key="rowIndex">
                                <th>{{ tableRowData.title }}</th>
                                <td>
                                    {{ tableRowData.value }}
                                    <Tooltip v-if="tableRowData.showTooltip" :data="tableRowData.tooltipData" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <ButtonTarget
                    class="to-application-button"
                    size="md"
                    background="green"
                    :text="$translate('cta_to_application', 'Till ansökan')"
                    :link="item.lender"
                />
            </li>
        </ol>
    </Section>
</template>

<script>
import { pick, capitalize } from 'lodash';
import ToplistItem from './ToplistCustomRichTextItem.vue';
import { Tooltip } from '@swegaming-ab/vue-components';

export default {
    components: {
        ToplistItem,
        Tooltip
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        items() {
            const items = [];
            this.data.items.forEach(item => {
                const storeKey = item.lender.type === 'lender_corporate' ? 'lendersCorporate' : 'lenders';
                const lender = this.$store.state[storeKey][item.lender.id];
                items.push({
                    lender: lender,
                    h3: this.$prismic.asText(item.h3)?.length ? this.$prismic.asText(item.h3) : lender.data.title,
                    content: item.content,
                    tableData: this.getTableData(lender)
                });
            });
            return items;
        },
        
    },
    methods: {
        getTableData(lender) {
            if (lender.type === 'lender_corporate') {
                const model = new this.$models.CorporateLoan(lender);

                return this.allTableData(
                    [
                        'loanAmountLowest',
                        'loanAmountHighest',
                        'requiresCollateral',
                        'paymentRemarksCompany',
                        'paymentRemarksRepersentative',
                        'takesUCCompany',
                        'takesUCRepersentative',
                    ],
                    model
                );
            }

            const model = new this.$models.Loan(lender);

            if (this.$isSweden()) {
                return this.swedenTableData(model);
            }
            if (this.$isNorway()) {
                return this.norwayTableData(model);
            }
            
        },
        swedenTableData(model) {
            if (model.isHighCostCredit) {
                return this.allTableData(
                    [
                        'loanAmount',
                        'interest',
                        'duration',
                        'creditCheck',
                        'paymentRemarks',
                        'directPayments',
                    ],
                    model
                );
            }
            if (model.isBroker) {
                return this.allTableData(
                    [
                        'loanAmount',
                        'interest',
                        'duration',
                        'paymentRemarks',
                        'connectedBanks',
                    ],
                    model
                );
            }
            return this.allTableData(
                [
                    'loanAmount',
                    'interest',
                    'duration',
                    'paymentRemarks',
                    'setupFee',
                ],
                model
            );
        },
        norwayTableData(model) {
            if (model.isHighCostCredit) {
                return this.allTableData(
                    [
                        'loanAmount',
                        'interest',
                        'duration',
                        'paymentRemarks',
                        'ageRequirement',
                    ],
                    model
                );
            }
            if (model.isBroker) {
                return this.allTableData(
                    [
                        'loanAmount',
                        'interest',
                        'duration',
                        'paymentRemarks',
                        'ageRequirement',
                        'connectedBanks',
                    ],
                    model
                );
            }
            return this.allTableData(
                [
                    'loanAmount',
                    'interest',
                    'duration',
                    'paymentRemarks',
                    'ageRequirement',
                    'setupFee',
                ],
                model
            );
        },
        allTableData(keys, model) {
            const allTableData = {
                loanAmount: {
                    title: this.$translate('amount', 'Belopp'),
                    value: model.amountString,
                },
                interest: {
                    title: this.$translate('interest', 'Ränta'),
                    value: model.interestString,
                },
                duration: {
                    title: this.$translate('duration', 'Löptid'),
                    value: model.getDurationString(this.$translate('month_short', 'mån'), this.$translate('year', 'år')),
                },
                creditCheck: {
                    title: this.$translate('credit_check', 'Kreditupplysning'),
                    value: capitalize(model.creditCheck),
                },
                paymentRemarks: {
                    title: this.$translate('ld_payment_remarks_label', 'Betalningsanmärkningar'),
                    value: model.acceptsPaymentRemarks 
                        ? this.$translate('accepted', 'Accepteras') 
                        : this.$translate('not_accepted', 'Accepteras inte'),
                },
                directPayments: {
                    title: this.$translate('direct_payout', 'Direktutbetalning'),
                    value: model.hasDirectPayments24Hours 
                        ? this.$translate('twentyfour_seven', 'Dygnet runt') 
                        : this.$formatBoolean(model.hasDirectPayments),
                    showTooltip: this.showDirectPaymentsExplanation(model),
                    tooltipData: this.directPaymentsExplanation(model)
                },
                setupFee: {
                    title: this.$translate('setup_fee', 'Uppläggningsavgift'),
                    value: model.setupFeeString,
                },
                connectedBanks: {
                    title: this.$translate('connected_banks', 'Anslutna långivare'),
                    value: model.connectedBanks,
                },
                ageRequirement: {
                    title: this.$translate('ld_age_limit_label', 'Ålderskrav'),
                    value: this.$translate('ld_age_limit_value', '{minAge} eller äldre')
                        .replace('{minAge}', model.minAge),
                },
                loanAmountLowest: {
                    title: this.$translate('bl_stats_min_amount', 'Lägsta lånebelopp'),
                    value: model.minAmountString,
                },
                loanAmountHighest: {
                    title: this.$translate('bl_stats_max_amount', 'Högsta lånebelopp'),
                    value: model.maxAmountString,
                },
                requiresCollateral: {
                    title: this.$translate('bl_demands_security', 'Kräver säkerhet'),
                    value: this.$formatBoolean(model.requiresCollateral),
                },
                paymentRemarksCompany: {
                    title: this.$translate('bl_payment_remarks_company', 'Betalningsanmärkningar på företaget'),
                    value: model.acceptsPaymentRemarks?.company 
                        ? this.$translate('accepted', 'Accepteras') 
                        : this.$translate('not_accepted', 'Accepteras inte'),
                },
                paymentRemarksRepersentative: {
                    title: this.$translate('bl_payment_remarks_on_the_company_signer', 'Betalningsanmärkningar på firmatecknaren'),
                    value: model.acceptsPaymentRemarks?.repersentative 
                        ? this.$translate('accepted', 'Accepteras') 
                        : this.$translate('not_accepted', 'Accepteras inte'),
                },
                takesUCCompany: {
                    title: this.$translate('bl_takes_uc_on_company', 'Tar uc på företaget'),
                    value: this.$formatBoolean(model.takesUC?.company),
                },
                takesUCRepersentative: {
                    title: this.$translate('bl_takes_uc_on_company_signer', 'Tar uc på firmatecknaren'),
                    value: this.$formatBoolean(model.takesUC?.repersentative),
                },
            };
            return pick(allTableData, keys);
        },
        showDirectPaymentsExplanation(model) {
            const banks = [
                'Nordea', 'DanskeBank', 'Handelsbanken', 'SEB', 'Swedbank'
            ];

            for (let i = 0; i < banks.length; i++) {
                if (model[`hasDirectPaymentTo${banks[i]}`]) {
                    return true;
                }
            }

            return false;
        },
        directPaymentsExplanation(model) {
            const banks = [
                'Nordea', 'DanskeBank', 'Handelsbanken', 'SEB', 'Swedbank'
            ];

            let hasDirectPaymentTo = [];
            banks.forEach(bank => {
                if (model[`hasDirectPaymentTo${bank}`]) {
                    hasDirectPaymentTo.push(bank);
                }
            });
            return this.$translate('direct_payout_to_bank', 'Direktutbetalning till {bank}')
                .replace('{bank}', hasDirectPaymentTo.join(', '));
        },
    }
};
</script>

<style lang="scss" scoped>

    .list-item-header {
        display: flex;
        color: unset;
        text-decoration: unset;
        flex-wrap: wrap;
        margin-bottom: 30px;
        gap: 20px;
        justify-content: space-between;
        align-items: flex-end;
        @include device(pad) {
            flex-wrap: nowrap;
        }
        &:hover {
            text-decoration: underline;
        }
        h3 {
            flex-grow: 1;
        }
        .img-wrapper {
            display: flex;
            justify-content: center;
            margin: 0 auto;
        }
        
    }

    .section-content > :last-child {
        @include spacing(padding, 7, bottom);
        @include spacing(margin, 6, bottom);
        @include border(bottom, 1px);
    }

    .table-container {
        @include spacing(margin, 5, (top, bottom));
        
        table {
            @include device(pad) {
                table-layout: fixed;
            }
            tr {
                @include border(bottom);
                &:last-child { border: 0; }

                th,
                td {
                    @include font-size(14px,6px);
                    text-align: left;
                    min-width: 80px;
                    &:first-child {
                        font-weight: bold;
                        @include border(right);
                    }
                }
            }
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        .list-item {
            &:not(:first-child) {
                @include spacing(padding, 6, top);
                @include spacing(margin, 7, top);
                @include border(top, 1px);
            }
        }
    }
    .to-application-button {
        display: block;
        @include device(pad) {
            display: inline-block;
        }
    }
</style>