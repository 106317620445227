<template>
    <div class="wrapper wrapper-ucr">
        <Section
            id="write-review"
            width="sm"
            class="ucr-cta"
        >
            <h2>
                {{ 
                    $translate('urf_h2', 'Vad tycker våra kunder om {title}?')
                        .replace('{title}', data.title) 
                }}
            </h2>

            <div class="container">
                <a
                    v-scroll-to="'#form'"
                    href="#"
                    class="btn btn-md btn-white bxs"
                >
                    {{ $translate('cta_urf_go_to_form', 'Skriv ett omdöme') }}
                </a>
            </div>
        </Section>

        <UcrForm
            :document="data"
            :core-api="data.core_api"
        />
    </div>
</template>

<script>
import UcrForm from './UcrForm.vue';
export default {
    components: { UcrForm },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/html/buttons.scss';
.wrapper-ucr::v-deep {
    .ucr-cta {
        text-align: center;
        color: $white;

        .container {
            border-top: 1px solid rgba(255,255,255,.2);
            margin-top: 40px;
            padding-top: 40px;
            width: 100%;
            @include flex;
        }
    }

    .ucr {
        background: $lightgray;

        &-list {
            @include grid(1, 20px);
            margin-bottom: 20px;

            &-item {
                background: $white;
                @include border-radius(5px);
                box-shadow: $bxs;
                @include spacing(padding, 6);
                @include grid(1, 0);

                .rating-name {
                    font-family: $fontHeader;
                    font-size: 16px;
                }
                .rating-stars {
                    @include spacing(margin, 3, (top, bottom));
                    .active { color: $green; }
                    i { color: #999; }
                }
                .rating-timestamp {
                    opacity: .7;
                    font-size: 14px;
                    font-style: italic;
                }
                .rating-header { 
                    @include spacing(margin, 3, (bottom));
                    font-weight: bold; 
                }
                .rating-body {
                    P:first-child { @include spacing(margin, 0, (top)); }
                    p { @include font-size(14px); }
                }
                
                .reply {
                    background: $cream;
                    margin-top: 15px;
                    padding: 15px;
                    .reply-author-name {
                        font-family: $fontHeader;
                        display: block;
                        margin-bottom: 10px;
                    }
                    .reply-text::v-deep {
                        margin-bottom: 0 !important;
                        > * {
                            font-size: 14px !important;
                        }
                    }
                }
            }
        }

        &-cta {
            background: url('~assets/images/blue-logos.webp');
        }

        &-form {
            width: 100%;
            @include spacing(padding, 8);
            background: $white;
            @include border-radius(5px);
            box-shadow: $bxs;
            // border: 1px solid $borderColor;

            &-title {
                margin-bottom: 50px;
                color: $green;
                text-align: center;
            }

            &-input-container {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 30px;
                font-family: $fontText;

                label {
                    width: 100%;
                    @include font-size(14px, 4px);
                    margin-bottom: 10px;

                    @include device(pad) {
                        width: auto;
                        margin-bottom: 0;
                        flex: 1;
                    }

                    span {
                        @include font-size(14px, 4px);

                        &:last-child {
                            display: block;
                            font-size: 12px;
                            color: #999;
                        }
                    }
                }
                input,
                textarea,
                .stars {
                    width: 100%;
                    -webkit-appearance: none;
                    padding: 15px;
                    font-size: 16px;
                    @include border;
                    font-family: $fontText;

                    @include device(pad) {
                        width: auto;
                        flex: 2;
                        margin-left: 30px;
                    }
                }
                textarea {
                    resize: vertical;
                }
                .stars {
                    i {
                        font-size: 20px;
                        color: #999;
                        cursor: pointer;
                    }

                    .active,
                    .ratingSet {
                        // sets color of chosen score stars
                        color: $green;
                    }
                }
                .error {
                    width: 100%;
                    background: #fbafaf;
                    @include border(all, 1px, red);
                    margin-top: 10px;
                    padding: 10px;
                    font-size: 14px;
                    @include border-radius(2px);
                }
            }

            &-submit-button {
                display: flex;
                justify-content: center;

                button {
                    @extend .btn;
                    @extend .btn-green;
                    @extend .btn-md;
                }
                button.disabled {
                    // disables button
                    opacity: .6;
                    pointer-events: none;
                }
            }
        }

        .post-success {
            h1,h2,h3,h4,h5,h6 {
                color: $green;
                @include spacing(margin,6,bottom);
            }
        }
    }
}
</style>
