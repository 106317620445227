<template>
    <Section
        class="banner bg-cream"
        width="sm"
    >
        <div class="banner-inner">
            <h1 class="banner-inner__title">{{ $prismic.asText(data.primary.h1) }}</h1>
            <div
                v-if="$helpers.filterEmptyElements(data.primary.teaser)"
                class="banner-inner__teaser list-icon list-checkmark"
                v-html="$prismic.asHtml(data.primary.teaser)"
            /> 
            <button class="btn btn-md btn-green">
                <a v-scroll-to="'#question-form'" href="#">
                    {{ $translate('qa_ask_question_cta', 'Ställ din fråga') }}
                </a>
            </button>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.banner::v-deep {

    text-align: center;
    h1 {
        color: $green;
        width: 100%;
    }

    .banner-inner {
        @include flex;
        .banner-inner__teaser {
            ul {
                width: auto;
                @include grid(1, 15px);
                text-align: left;
            }

            a {
                color: $green;
                text-decoration: none;
                font-weight: bold;
                &:hover { text-decoration: underline; }
            }
        }
        button {
            @include spacing(margin,5,top);
            @include spacing(padding,8,(left, right));
            a {
                text-decoration: none;
                color: $white;
            }
        }
    }
}
</style>