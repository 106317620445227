<template>
    <Section width="lg" class="table-of-contents">
        <ul>
            <li 
                v-for="(link, index) in scrollLinks" 
                :key="index"
            >
                <a 
                    v-scroll-to="{
                        el: link.link,
                        offset: -50    
                    }" 
                    href="#"
                >
                    {{ link.title }}
                </a>
            </li>
        </ul>
    </Section>
</template>

<script>
export default {
    computed: {
        scrollLinks() {
            return [
                {
                    title: this.$translate('ctl_automatic_rating_scroll_link', 'Jämfört med konkurrenter'),
                    link: '#automatic-ranking'
                },
                {
                    title: this.$translate('ctl_comparison_table_scroll_link', 'Jämför data'),
                    link: '#compare-data'
                },
                {
                    title: this.$translate('ctl_connected_lenders_scroll_link', 'Anslutna långivare'),
                    link: '#connected-banks'
                },
                {
                    title: this.$translate('ctl_user_ratings_scroll_link', 'Kundrecensioner'),
                    link: '#user-ratings'
                },
                {
                    title: this.$translate('ctl_contact_scroll_link', 'Kontakt'),
                    link: '#contact'
                },
            ];
        }
    }
};
</script>

<style lang="scss" scoped>
    .table-of-contents {
        padding: 0 20px;

            ul {
                width: 100%;
                @include flex;
                text-align: center;

                li {
                    @include spacing(padding, 4);

                    a {
                        font-size: 14px;
                        text-decoration: none;
                        color: $blue;
                        font-weight: bold;

                        &:hover { text-decoration: underline; }
                    }
                }
            }
    }
</style>