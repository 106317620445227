<template>
    <div
        :class="{ open: open }"
        class="dropdown"
    >
        <div
            class="dropdown-header"
            @click="open =! open"
        >
            <span>{{ header }}</span>
        </div>

        <div
            v-show="open"
            class="dropdown-body rich-text"
            v-html="data"
        />
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: String,
            required: false,
            default: 'content'
        },
        header: {
            type: String,
            required: false,
            default: 'Header'
        }
    },
    data() {
        return {
            open: false
        };
    }
};
</script>

<style lang="scss" scoped>
    .dropdown-body ::v-deep {
        p:last-child {
            margin-bottom: 0;
        }
    }
</style>
