<template>
    <table class="interest-data-container">
        <thead>
            <tr class="interest-data-item">
                <th>{{ $translate('bank', 'Bank') }}</th>
                <th>{{ tableTitle }}</th>
            </tr>
        </thead>
        <tbody>
            <tr 
                v-for="(data, index) in tableData" 
                :key="index"
                class="interest-data-item"
            >
                <td>{{ data.lender }}</td>
                <td>{{ data.value }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: {
        tableTitle: {
            type: String,
            required: true
        },
        tableData: {
            type: Array,
            required: true
        },
    },
};
</script>

<style lang="scss" scoped>
    .interest-data-container {
        thead .interest-data-item {
            background-color: $lightgray;
            font-weight: bold;
        }
        .interest-data-item {
            display: flex;
            justify-content: space-between;
            padding: 1rem 0.75rem;
            border-bottom: 2px solid rgba(0, 0, 0, 0.03);
            td {
                &:first-letter {
                    text-transform: uppercase;
                }
            }
        }
    }
</style>