<template>
    <li class="list-card">
        <PrismicImage
            :img="data.image"
            :alt="data.page_title"
            w="350"
            h="150"
            class="list-card__image"
        />

        <div class="list-card__content">
            <span>{{ data.page_title }}</span>
            <p class="italic">{{ data.loan_amount }}</p>
            <p>{{ data.cta_text }}</p>
        </div>

        <div class="list-card__btns">
            <ButtonNuxt
                size="md"
                background="green"
                :text="data.button_text"
                :link="data.page"
            />
        </div>
    </li>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    .list-card {
        @include flex;
        @include border-radius(3px);
        box-shadow: 0 2px 5px -2px rgba(0,0,0,.14);
        overflow: hidden;
        width: 100%;
        background: $white;

        &__image {
            max-width: 100%;
            @include size(100%, 150px);
            object-fit: cover!important;
        }

        &__content {
            @include spacing(padding, 4);
            @include grid(1, 20px);
            width: 100%;
            text-align: center;

            span {
                @include reset-font;
                @include font-size(20px, 0);
                font-weight: bold;
                font-family: $fontHeader;
                display: block;
            }

            p { @include font-size(14px); }
            p.italic { font-style: italic; }
        }

        &__btns {
            @include grid(1,0);
            @include spacing(padding, 4, (left, bottom, right));
            width: 100%;
        }
    }

</style>
