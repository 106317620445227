<template>
    <Section
        class="bg-lgray"
        width="sm"
    >
        <div class="container">
            <h2 class="section-header">{{ $translate('private_loan', 'Privatlån') }}</h2>
            <ul class="list">
                <li v-for="(company, orgNumber) in getSortedCompanyArray(companiesByPrivateLenders)" :key="orgNumber">
                    <span class="header">{{ getCompanyName('lenders', company.lenderIds[0]) }}</span>
                    <ul>
                        <Lender
                            v-for="(id, index) in company.lenderIds"
                            :id="id"
                            :key="index"
                        />
                    </ul>
                </li>
            </ul>
        </div>

        <div class="container">
            <h2 class="section-header">{{ $translate('company_loan', 'Företagslån') }}</h2>
            <ul class="list">
                <li v-for="(company, orgNumber) in getSortedCompanyArray(companiesByCorporateLenders)" :key="orgNumber">
                    <span class="header">{{ getCompanyName('lendersCorporate', company.lenderIds[0]) }}</span>
                    <ul>
                        <Corporate
                            v-for="(id, index) in company.lenderIds"
                            :id="id"
                            :key="index"
                        />
                    </ul>
                </li>
            </ul>
        </div>
    </Section>
</template>

<script>
import Lender from './list-items/ListItemLender.vue';
import Corporate from './list-items/ListItemCorporate.vue';
export default {
    components: { Lender, Corporate },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        companiesByPrivateLenders: function() {
            return this.getByCompanyObject('lenders', Object.values(this.$store.state.lenders));
        },
        companiesByCorporateLenders: function() {
            return this.getByCompanyObject('lendersCorporate', Object.values(this.$store.state.lendersCorporate));
        }
    },
    methods: {

        /**
         * Return on object with all the companies and their respective
         * lenders. The object will be return as the following:
         *  {
         *      XXXXXXXX01: { name: 'Name', lenderIds: ['prismicid1', 'prismicid2'] },
         *      XXXXXXXX02: { name: 'Name2', lenderIds: ['anotherprismicid1', 'anotherprismicid2'] },
         *      ...
         *  }
         */
        getByCompanyObject(lenderType, lenderArray) {
            let lendersByCompany = [];
            lenderArray.forEach(lender => {   
                const registrationNumber = this.getRegistrationNumber(lenderType, lender);
                if (registrationNumber) {
                    if (!lendersByCompany[registrationNumber]) {
                        lendersByCompany[registrationNumber] = {
                            name: this.getCompanyName(lenderType, lender.id),
                            lenderIds: []
                        };
                    }
                    lendersByCompany[registrationNumber].lenderIds.push(lender.id);
                }
            });

            return lendersByCompany;
        },

        /**
         * Return a sorted array from the provided lendersByCompany
         * object. The object should be the one that you get from getByCompanyObject
         * function
         */
        getSortedCompanyArray(lendersByCompany) {
            const lendersArray = Object.values(lendersByCompany);
            lendersArray.sort((c1, c2) => {
                const c1uppercase = c1.name.toUpperCase();
                const c2uppercase = c2.name.toUpperCase();

                if (c1uppercase < c2uppercase) {
                    return -1;
                }
                if (c1uppercase > c2uppercase) {
                    return 1;
                }

                return 0;
            });
            return lendersArray;
        },
        getRegistrationNumber(lenderType, lender) {
            const registrationNumber = this.$store.state[lenderType][lender.id].data.core_api.brand_data.company_registration_number;
            if (registrationNumber !== undefined) {
                return registrationNumber.replace('-', '').replace(' ', '');
            }  
            return false;
        },
        getCompanyName(lenderType, id) {
            return this.$store.state[lenderType][id].data.core_api.brand_data.company_name;
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        .section-inner {
            @include grid(1,100px);
        }
        .list {
            @include grid(1,60px);

            li {

                .header {
                    @include spacing(margin,4,bottom);
                    font-family: $fontHeader;
                    @include font-size(20px);
                    display: block;
                }

                ul {
                    @include grid(1,20px);

                    .list-item.inactive {
                        &:hover { border-color: rgba(204, 0, 0,.2); }
                    }
                    .list-item {
                        @include border(all,1px,$white);
                        box-shadow: $bxs;
                        background: $white;
                        @include border-radius(5px);
                        transition: .15s ease-in-out;
                        overflow: hidden;
                        &:hover { border-color: $green; }

                        a {
                            width: 100%;
                            @include grid(1, 0);
                            text-decoration: none;
                            color: $font;

                            @media screen and (min-width: 500px) {
                                @include flex;
                            }
                        }

                        &__archived {
                            @include spacing(padding,3);
                            @include font-size(12px);
                            width: 100%;
                            background: rgba(204, 0, 0,.075)!important;
                            font-family: $fontHeader;
                            color: #990000;
                        }

                        &__container {
                            @include spacing(padding,6);
                            flex: 1;
                            text-align: center;

                            span {
                                font-family: $fontText;
                                @include font-size(14px,0px);
                                &:last-child {
                                    font-family: $fontHeader;
                                    font-weight: bold;
                                }
                            }

                            &:nth-child(1) {
                                @include flex;
                                img { height: auto; }
                            }

                            &:nth-child(2),
                            &:nth-child(3) {
                                @include grid(1, 10px);
                            }
                        }
                    }
                }
            }
        }
    }
</style>
