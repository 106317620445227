<template>
    <div
        v-if="tableTitles.length"
        class="table-container"
        :class="{'mobile-scroll-shadow': tableTitles.length > 2}"
    >
        <table class="table-container__table">
            <thead>
                <tr>
                    <th v-if="showLogos">
                        {{ $translate('lender', 'Långivare') }}
                    </th>
                    <th 
                        v-for="(title, index) in tableTitles" 
                        :key="index"
                    >
                        {{ title }}
                    </th>
                    <th v-if="showOutLinks" class="table-header-application">
                        {{ $translate('application_label', 'Ansök') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(data, index) in tableData"
                    :key="index"
                >
                    <td v-if="showLogos">
                        <prismic-image 
                            v-if="lenders[index]"
                            :img="lenders[index].data.image"
                            w="100"
                        />
                    </td>
                    <td 
                        v-for="(cell, cellIndex) in data" 
                        :key="cellIndex"
                    >
                        <div 
                            class="rich-text" 
                            v-html="cell" 
                        />
                    </td>
                    <td v-if="showOutLinks">
                        <ButtonTarget 
                            v-if="lenders[index]" 
                            :link="lenders[index]" 
                            :text="$translate('cta_to_application', 'Till ansökan')"
                            background="green"
                            size="md" 
                            class="application-button"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import CustomizableTable from './mixins/CustomizableTable.js';
export default {
    mixins: [
        CustomizableTable
    ]
};
</script>

<style lang="scss" scoped>
    .table-header-application {
        width: 1%;
    }
</style>