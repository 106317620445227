<template>
    <div
        v-if="!show"
        class="video container"
    >
        <i
            class="fa-sharp fa-solid fa-circle-play white"
            @click="show = true"
        />
    </div>

    <div
        v-else
        class="video rich-text"
        v-html="$prismic.asHtml(data.content)"
    />
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            show: false
        };
    }
};
</script>

<style lang="scss" scoped>
.video {
    @include border-radius(3px);
    box-shadow: $bxs;
    @include size(700px, 200px);
    max-width: 100%;
    background: $green;

    @include device(pad) {
        @include size(700px, 360px);
    }

    i {
        font-size: 72px;
        cursor: pointer;
    }
}

.video.container {
    @include flex;
}
</style>
