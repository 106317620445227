<template>
    <div class="lenders-container">
        <h3 v-if="$validateText(header)">{{ header }}</h3>
        <p v-show="$validateText(header) && !highlightedLenders.length">{{ $translate('no_matching_lenders', 'Inga långivare passar in på detta lånet') }}</p>
        <lender v-for="lender in highlightedLenders" :id="lender.id" :key="lender.id" />
    </div>
</template>

<script>
import sortLenders from '@/plugins/sort-lenders.js';
import Lender from './Lender.vue';

export default {
    components: {
        Lender
    },
    props: {
        duration: {
            type: Number,
            required: true
        },
        loanAmount: {
            type: Number,
            required: true
        },
        header: {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        sortedLenders() {
            const lenders = Object.values(this.$store.state.lenders)
                .map(l => Object.assign({}, l));
            return sortLenders(lenders, this.$models.Loan);
        },
        highlightedLenders() {
            const filtered = this.sortedLenders.filter(({data, model}) => {
                return (
                    data.redirect_enabled &&
                    !data.lists_disabled &&
                    this.loanAmount >= model.minAmount &&
                    this.loanAmount <= model.maxAmount &&
                    this.duration >= model.minDuration &&
                    this.duration <= model.maxDuration
                );
            });

            return filtered.slice(0, 3);
        }
    }
}
</script>

<style lang="scss" scoped>
.lenders-container {
    width:100%;
    @include flex(start, stretch);
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;

    h3 {
        @include spacing(margin, 4, top)
    }
}
</style>