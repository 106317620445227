<template>
    <Section 
        v-if="blockItems.length"
        width="md" 
        class="icon-block"
    >
        <div 
            v-if="$validateText(data.primary.header) || $validateText(data.primary.teaser)"
            class="header-teaser-section"
        >
            <div 
                v-if="$validateText(data.primary.header)"
                class="rich-text"
                v-html="$prismic.asHtml(data.primary.header)"
            />
            <div 
                v-if="$validateText(data.primary.teaser)"
                class="rich-text"
                v-html="$prismic.asHtml(data.primary.teaser)"
            />
        </div>
        <div class="icon-block-wrapper">
            <div
                v-for="(item, index) in blockItems"
                :key="index"
                class="icon-block-body"
            >
                <div class="icon-block-content">
                    <i 
                        class="fa-solid fa-xl icon" 
                        :class="item.icon" 
                    />
                    <div 
                        class="rich-text content-title"
                        v-html="$prismic.asHtml(item.title)"    
                    />
                </div>
                <div 
                    class="rich-text"
                    v-html="$prismic.asHtml(item.text)"    
                />
            </div>
        </div>
    </Section>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        blockItems() {
            return this.data.items
                .filter(item => this.$validateText(item.title));
        }
    }
};
</script>
<style lang='scss' scoped>
    .icon-block::v-deep {
        .header-teaser-section {
            margin-bottom: 2rem;
        }
        .icon-block-wrapper {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: auto;
            gap: 2rem;

            @include device(pad) {
                grid-template-columns: repeat(2, 1fr);
            }

            .icon-block-body {
                display: flex;
                flex-direction: column;
                @include spacing(padding, 6);
                @include border-radius(3px);
                box-shadow: $bxs;

                .icon-block-content {
                    display: flex;
                    align-items: baseline;
                    gap: 1rem;
                    margin-bottom: 1rem;
                    padding-bottom: 0.5rem;
                    border-bottom: 2px solid rgba(0, 0, 0, 0.03);
                    .content-title {
                        h3, h4, h5, h6 {
                            font-size: 20px;
                            line-height: 30px;
                            margin: 0 !important;
                        }
                    }
                    @include device(pad) {                
                        .content-title {
                            h3, h4, h5, h6 {
                                font-size: 24px;
                                line-height: 34px;
                            }
                        }
                    }
                    .icon {
                        color: $green;
                    }
                }
            }
        }
    }
</style>