<template>
    <div class="list">
        <Lender
            v-for="(iterator, index) in data"
            :id="iterator.lender.id"
            :key="index"
            :content="iterator.content"
            :lender-type="iterator.lender.type"
        />
    </div>
</template>

<script>
import Lender from './HighlightLendersListItem.vue';
export default {
    components: { Lender },
    props: {
        data: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.list {
    @include grid(1, 30px);
}
</style>
