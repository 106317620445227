<template>
    <div class="html-input">
        <label :for="name">{{ label }}</label>

        <input
            v-model="input"
            :type="type"
            :name="name"
        >

        <div
            v-if="error"
            class="html-input__error"
        >
            {{ errorText }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
            default: 'label'
        },
        name: {
            type: String,
            required: true,
            default: 'name'
        },
        defaultValue: {
            type: String,
            required: false,
            default: null
        },
        error: {
            type: Boolean,
            required: false,
            default: false
        },
        errorText: {
            type: String,
            required: true,
            default: 'name'
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        }
    },
    data() {
        return {
            input: this.defaultValue
        };
    },
    watch: {
        input() {
            this.$emit('clearError', this.name);
            this.$emit('set', this.name, this.input);
        }
    }
}
</script>
