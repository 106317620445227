<template>
    <div id="question-form">
        <div v-if="!formSubmitted">
            <h2>{{ $translate('qa_form_header', 'Ställ oss en fråga') }}</h2>
            <form>
                <html-input
                    :label="$translate('pc_first_name_label', 'Ditt förnamn:')"
                    name="name"
                    :default-value="newQuestion.name.value"
                    :error="newQuestion.name.error"
                    :error-text="$translate('pc_first_name_error_message', 'Oj, du har missat att fylla i ditt namn.')"
                    @set="set"
                    @clearError="clearError"
                />
                <html-input
                    :label="$translate('urf_email_label', 'Din e-postadress:')"
                    name="email"
                    type="email"
                    :default-value="newQuestion.email.value"
                    :error="newQuestion.email.error"
                    :error-text="$translate('urf_email_error_message', 'Kolla så att email fältet är korrekt i fyllt!')"
                    @set="set"
                    @clearError="clearError"
                />

                <html-textarea
                    :label="$translate('qa_your_question_label', 'Din fråga:')"
                    name="question"
                    :error="newQuestion.question.error"
                    :error-text="$translate('qa_question_error', 'Din fråga måste vara minst 20 tecken lång.')"
                    :rows="5"
                    @set="set"
                    @clearError="clearError"
                />
                <button
                    class="submit btn btn-md btn-green"
                    @click.prevent="submit"
                    :disabled="loading"
                >
                    {{ $translate('qa_submit_question', 'Skicka in') }}
                </button>
            </form>
        </div>
        <div v-else class="success">
            <h2>{{ $translate('qa_submit_success_header ', 'Tack för din fråga!') }}</h2>
            <p>{{ $translate('qa_submit_success_text', 'Vi kommer snart publicera ett inlägg där vi besvarar din fråga.') }}</p>
            <button
                class="btn btn-md btn-green"
                @click="formSubmitted = false"
            >
                {{ $translate('qt_ask_another_question', 'Ställ en till fråga') }}
            </button>
        </div>
    </div>
</template>

<script>
import HtmlInput from '@/components/html/HtmlInput.vue';
import HtmlTextarea from '@/components/html/HtmlTextarea.vue';
import axios from 'axios';

export default {
    components: {
        HtmlInput,
        HtmlTextarea
    },
    data() {
        return {
            formSubmitted: false,
            loading: false,
            newQuestion: {
                name: {
                    value: '',
                    error: false,
                    validate: (value) => {
                        return value.length;
                    }
                },
                email: {
                    value: '',
                    error: false,
                    validate: (value) => {
                        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
                        return value && reg.test(value);
                    }
                },
                question: {
                    value: '',
                    error: false,
                    validate: (value) => {
                        return value.length > 20;
                    }
                },
            },

        };
    },
    methods: {
        set(key, value) {
            this.newQuestion[key].value = value;
        },
        clearError(key) {
            this.newQuestion[key].error = false;
        },
        submit() {
            let hasErrors = false;
            Object.values(this.newQuestion).forEach(input => {
                ! input.validate(input.value) && (input.error = true, hasErrors = true);
            });

            if (! hasErrors) {
                this.loading = true;
                let payload = {}
                Object.entries(this.newQuestion).map(([key, values]) => {
                    payload[key] = values.value
                });
            
                axios.post('https://formspree.io/f/mzbqqadp', payload)
                .then(() => {
                    this.loading = false;
                    this.formSubmitted = true;
                    this.newQuestion.question.value = '';
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    h2 {
        @include spacing(margin,6,bottom);
    }
    form {
            @include spacing(padding,6);
            background: $white;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
            @include border-radius(5px);
            @include grid(1, 40px);

            .html-input ::v-deep {
                @include device(pad) {
                    @include flex(start,start);
                }

                label {
                    font-family: $fontHeader;
                    padding: 15px 0;
                    flex: 1;

                    @include device(mobile) {
                        display: block;
                    }
                }

                input,
                textarea {
                    @include border-radius(5px);
                    @include border;
                    font-family: $fontText;
                    font-size: 16px;
                    flex: 2;
                    resize: vertical;
                    padding: 15px;

                    @include device(mobile) {
                        width: 100%;
                    }
                }
            }

            .submit {
                margin: auto;
                width: 100%;
                max-width: 300px;
                &[disabled] {
                    opacity: .6;
                    pointer-events: none;
                }
            }
        }
    .success {
        @include spacing(padding,6);
        background: $white;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
        @include border-radius(5px);

        button {
            margin-top: 16px;
            @include device(mobile) {
                width: 100%;
            }
        }
    }
</style>