<template>
    <ul class="lender-stats">
        <li
            v-for="(stat, index) in stats"
            :key="index"
        >
            <span>{{ stat.label }}:</span>
            <span v-html="stat.value" />
        </li>
    </ul>
</template>

<script>

import { capitalize } from 'lodash';
export default {
    props: {
        model: {
            type: Object,
            required: true
        },
        statsType: {
            type: String,
            requried: true,
            default: 'private'
        }
    },
    data() {

        let stats = [
            {
                label: this.$translate('amount', 'Belopp'),
                value: this.model.amountString
            },
            {
                label: this.$translate('duration', 'Löptid'),
                value: this.model.getDurationString(this.$translate('month_short', 'mån'), this.$translate('year', 'år'))
            }
        ];

        if (this.model.isBroker) {
            stats.push({
                label: this.$translate('connected_banks', 'Anslutna långivare'),
                value: this.model.connectedBanks
            });
        }

        stats = stats.concat([
            {
                label: this.$translate('ld_payment_remarks_label', 'Betalningsanmärkningar'),
                value: this.model.acceptsPaymentRemarks.company ? this.$translate('accepted', 'Accepteras') : this.$translate('not_accepted', 'Accepteras inte')
            },
            {
                label: this.$translate('without_security_in_the_company', 'Utan säkerhet i företaget'),
                value: this.model.requiresCollateral ? this.$translate('yes_security_needed', 'Ja, säkerhet krävs') : this.$translate('no_security_needed', 'Ingen säkerhet i företaget krävs')
            },
            {
                label: this.$translate('without_personal_bail', 'Utan personlig borgen'),
                value: this.$helpers.boolean(this.model.requiresPersonalBail)
            },
        ]);

        if (this.model.companyRequirements.minRevenue) {
            stats.push({
                label: this.$translate('min_revenue', 'Minsta omsättning'),
                value: this.model.companyRequirementsString.minRevenue
            });
        }

        if (this.model.companyRequirements.minAge) {
            stats.push({
                label: this.$translate('company_history_label', 'Företagets historik'),
                value: this.$translate('company_history_explanation', 'Aktivt i minst {minAge}')
                    .replace('{minAge}', this.model.companyRequirementsString.minAge)
            });
        }

        stats = stats.concat([
            {
                label: this.$translate('company_credit_check_label', 'Kreditupplysning på företaget'),
                value: capitalize(this.model.creditCheck.company)
            },
            {
                label: this.$translate('credit_check_on_the_representative_label', 'Kreditupplysning på företrädaren'),
                value: capitalize(this.model.creditCheck.repersentative)
            }
        ]);

        return {
            stats: stats
        };
    }
};
</script>
