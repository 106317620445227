<template>
    <div class="lender-box">
        <nuxt-link
            :to="$prismic.linkResolver(lenderWithModel)"
            class="lender-box__inner"
        >
            <PrismicImage
                :img="lenderWithModel.data.image"
                :alt="lenderWithModel.data.title"
                w="100"
                h="50"
            />  
        </nuxt-link>

        <div
            class="payout-info"
        >
            <span>{{ $translate('estimated_payout_time', 'Förväntad utbetalning inom {payout}')
                .replace('{payout}', payoutSpeedString) }}</span>
        </div>

        <div
            class="payout-date"
        >
           <span>{{ payoutDateDayString }}</span>
            <span>{{ payoutDateTimeString }}</span>
        </div>

        <div class="lender-box__inner">
            <ButtonTarget
                size="sm"
                background="green"
                :text="$translate('cta_to_application', 'Till ansökan')"
                :link="lenderWithModel"
                placement="rich-text-lender-box"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        lenderWithModel: {
            type: Object,
            required: true
        },
    },
    computed: {
        payoutSpeed() {
            return this.lenderWithModel.model.paymentAdministrationSpeed;
        },
        payoutDate() {
            return this.payoutSpeed.date;
        },
        payoutDateDayString() {
            const now = new Date();
            const payoutDay = this.payoutDate.getDay();
            const today = now.getDay();

            if (payoutDay === today) {
                return this.$translate('today', 'Idag');
            }

            if (payoutDay === (today + 1) % 7) {
                return this.$translate('tomorrow', 'Imorgon');
            }

            return [
                this.$translate('sunday', 'Söndag'),
                this.$translate('monday', 'Måndag'),
                this.$translate('tuesday', 'Tisdag'),
                this.$translate('wednesday', 'Onsdag'),
                this.$translate('thursday', 'Torsdag'),
                this.$translate('friday', 'Fredag'),
                this.$translate('saturday', 'Lördag')
            ][payoutDay];
        },
        payoutDateTimeString() {
            return this.payoutDate.toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' });
        },
        payoutEta() {
            return this.payoutSpeed.eta;
        },
        payoutSpeedString() {

            let hours = 0;
            if (this.payoutEta.days) {
                hours += this.payoutEta.days * 24;
            }
            if (this.payoutEta.hours) {
                hours += this.payoutEta.hours;
            }

            if (hours > 0) {
                return `${hours} ${this.$translate('hours', 'timmar')}`;
            }
            return `${this.payoutEta.minutes} ${this.$translate('minutes', 'minuter')}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.lender-box {
    @include border;
    box-shadow: $bxs;
    @include border-radius(5px);
    @include spacing(padding, 4);
    @include grid(1, 30px);
    width: 100%;
    color: $font;

    @include device(pad) {
        @include grid(4, 10px);
    }

    .lender-box__inner {
        @include grid(1, 10px);
        text-align: center;

        &:first-child {
            @include flex;
        }

        span {
            font-size: 14px;
            &:last-child { font-weight: bold; }
        }

        a {
            @include flex;
            padding-top: 15px;
            padding-bottom: 15px;
        }

        img { @include size(100px, auto); }
    }

    .payout-info {
        font-size: 14px;
    }
    
    .payout-date {
        font-size: 14px;
        background: $lightgray;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 3px;

        span:first-child {
            font-weight: bold;
        }
        span:first-letter {
            text-transform: uppercase;
        }
    }
}
</style>
