<template>
    <Section width="md">
        <h2 class="section-header">{{ data.primary.post_category }}</h2>

        <ul>
            <Post
                v-for="post in filteredPosts"
                :key="post.id"
                v-animate="{ duration: '.3s', threshold: .3, animation: 'up-slide' }"
                :post="post"
            />
        </ul>

        <Button
            v-if="maxIndex == 2"
            link="#"
            :text="data.primary.read_more_button_text"
            background="green"
            size="md"
            @click="maxIndex = 999"
        />
    </Section>
</template>

<script>
import Post from '@/components/slices/lists/list-items/ListItemPost.vue';
import { queryAll, replace } from '@swegaming-ab/nuxtjs-helpers';
export default {
    components: { Post },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            maxIndex: 2,
            posts: []
        };
    },
    async fetch() {
        this.posts = replace(await queryAll(this.$prismic.api,
            this.$prismic.predicates.at('document.type', 'post'),
            { lang: process.env.PRISMIC_LOCALE, orderings: '[ document.first_publication_date desc ]' }
        ));
    },
    computed: {
        filteredPosts() {
            let self = this;
            let arr = this.posts.filter((post) => {
                if (post.data.post_category === self.data.primary.post_category) return post;
                return false;
            });

            return arr.filter((post, index) => {
                if (index <= self.maxIndex) return post;
                return false;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    h2 {
        text-transform: capitalize;
        color: $green;
    }

    ul {
        @include grid(1, 60px);

        @include device(pad) {
            @include grid(3, 30px);
        }
    }

    .btn {
        @include spacing(margin, 10, top);
    }
</style>
