<template>
    <div class="slices">
        <template v-for="(slice, index) in slices" v-key="index">
            <BannerLinks v-if="slice.slice_type === 'banner__links'" :key="index" :data="slice" />
            <BannerLender v-else-if="slice.slice_type === 'banner__lender'" :key="index" :data="slice" />
            <BannerSimple v-else-if="slice.slice_type === 'banner__simple'" :key="index" :data="slice" />
            <TextBox v-else-if="slice.slice_type === 'text_box'" :key="index" :data="slice" />

            <!-- NOTE: Exception due to prismic ID conflict. Await fix -->
            <RichText v-else-if="slice.slice_type === 'rich_text' || slice.slice_type === 'rich_text_1'" :key="index" :data="slice" />

            <ListPages v-else-if="slice.slice_type === 'list__pages'" :key="index" :data="slice" />
            <ListAuthors v-else-if="slice.slice_type === 'list__authors'" :key="index" :data="slice" />
            <ListSelectedLenders v-else-if="slice.slice_type === 'list__selected_lenders'" :key="index" :data="slice" />
            <ListAllLenders v-else-if="slice.slice_type === 'list__all_lenders'" :key="index" :data="slice" />
            <ListAllPosts v-else-if="slice.slice_type === 'list__all_posts'" :key="index" :data="slice" />

            <ListCorporateLendersSelected v-else-if="slice.slice_type === 'list__selected_corporate_lenders'" :key="index" :data="slice" />
            <ListMortgageLendersSelected v-else-if="slice.slice_type === 'list__selected_mortgage_lenders'" :key="index" :data="slice" />
            <ListLendersWithTable v-else-if="slice.slice_type === 'list__lender_with_table'" :key="index" :data="slice" />

            <Latest v-else-if="slice.slice_type === 'latest_reviews_news'" :key="index" :data="slice" />
            <LoanGuide v-else-if="slice.slice_type === 'loan_guide'" :key="index" :data="slice" />
            <FAQ v-else-if="slice.slice_type === 'faq'" :key="index" :data="slice" />
            <LatestPosts v-else-if="slice.slice_type === 'latest_3_posts'" :key="index" :data="slice" />
            <InfoBoxes v-else-if="slice.slice_type === 'info_boxes'" :key="index" :data="slice" />
            <LoanGuideCTA v-else-if="slice.slice_type === 'loan_guide_cta'" :key="index" :data="slice" />
            <Indexed v-else-if="slice.slice_type === 'indexed_section'" :key="index" :data="slice" />
            <Stats v-else-if="slice.slice_type === 'stats'" :key="index" :data="slice" />
            <CompanyLoans v-else-if="slice.slice_type === 'company_loans'" :key="index" :data="slice" />
            <LenderTable v-else-if="slice.slice_type === 'lender_table'" :key="index" :data="slice" />
            <HighlightLenders v-else-if="slice.slice_type === 'highlight_lenders'" :key="index" :data="slice" />
            <Columns v-else-if="slice.slice_type === 'columns'" :key="index" :data="slice" />
            <LenderBox v-else-if="slice.slice_type === 'lender_box'" :key="index" :data="slice" />
            <HighlightedPosts v-else-if="slice.slice_type === 'highlighted_posts'" :key="index" :data="slice" />
            <ContentAndImage v-else-if="slice.slice_type === 'content_and_image'" :key="index" :data="slice" />
            <ImageSlice v-else-if="slice.slice_type === 'image'" :key="index" :data="slice" />
            <ImageCarousel v-else-if="slice.slice_type === 'image_carousel'" :key="index" :data="slice" />
            <CustomizableTable v-else-if="slice.slice_type === 'customizable_table'" :key="index" :data="slice" />
            <CustomizableLenderTable v-else-if="slice.slice_type === 'customizable_lender_table'" :key="index" :data="slice" />

            <ScolarshipForm v-else-if="slice.slice_type === 'scolarship_form'" :key="index" :data="slice" />
            <CompareLendersTable v-else-if="slice.slice_type === 'compare_lenders_table'" :key="index" :data="slice" />
            <AllQuestionAnswers v-else-if="slice.slice_type === 'all_question_answers'" :key="index" :data="slice" />
            <Calculator v-else-if="slice.slice_type === 'calculator'" :key="index" :data="slice" />
            <MortgageTable v-else-if="slice.slice_type === 'mortgage_table'" :key="index" :data="slice" />
            <CompareLenders v-else-if="slice.slice_type === 'compare_lenders'" :key="index" :data="slice" />
            <LinkSection v-else-if="slice.slice_type === 'link_section'" :key="index" :data="slice" :document="document" />
            <LatestUserReviews v-else-if="slice.slice_type === 'latest_user_reviews'" :key="index" :data="slice" />
            <CooperationLinks v-else-if="slice.slice_type === 'cooperation_links'" :key="index" :data="slice" />
            <IconBlock v-else-if="slice.slice_type === 'icon_block'" :key="index" :data="slice" />
            <CompareTwoBrokers v-else-if="slice.slice_type === 'compare_two_brokers'" :key="index" :data="slice" />
            <CustomerQuotes v-else-if="slice.slice_type === 'customer_quotes'" :key="index" :data="slice" />
            <TrustpilotTable v-else-if="slice.slice_type === 'trustpilot_table'" :key="index" :data="slice" />
            <ConnectedBanksTable v-else-if="slice.slice_type === 'connected_banks_table'" :key="index" :data="slice" />
            <ContentQuote v-else-if="slice.slice_type === 'content_quote'" :key="index" :data="slice" />
            <LenderPayoutTable v-else-if="slice.slice_type === 'lender_payout_table'" :key="index" :data="slice" />
        </template>
    </div>
</template>

<script>
import BannerLinks from '@/components/slices/banners/BannerLinks.vue';
import BannerLender from '@/components/slices/banners/BannerLender.vue';
import BannerSimple from '@/components/slices/banners/BannerSimple.vue';
import ListPages from '@/components/slices/lists/ListPages.vue';
import ListCorporateLendersSelected from '@/components/slices/lists/ListCorporateLendersSelected.vue';
import ListMortgageLendersSelected from '@/components/slices/lists/ListMortgageLendersSelected.vue';
import ListSelectedLenders from '@/components/slices/lists/ListLendersSelected.vue';
import ListAllLenders from '@/components/slices/lists/ListLendersAll.vue';
import ListAuthors from '@/components/slices/lists/ListAuthors.vue';
import ListAllPosts from '@/components/slices/lists/ListAllPosts.vue';
import TextBox from '@/components/slices/TextBox.vue';
import RichText from '@/components/slices/rich-text/RichText.vue';
import Latest from '@/components/slices/latest/Latest.vue';
import LoanGuide from '@/components/slices/loan-guide/LoanGuide.vue';
import FAQ from '@/components/slices/FAQ.vue';
import LatestPosts from '@/components/slices/LatestPosts.vue';
import InfoBoxes from '@/components/slices/InfoBoxes.vue';
import LoanGuideCTA from '@/components/slices/LoanGuideCTA.vue';
import Indexed from '@/components/slices/Indexed.vue';
import Stats from '@/components/slices/Stats.vue';
import CompanyLoans from '@/components/slices/company-loans/CompanyLoans.vue';
import LenderTable from '@/components/slices/tables/LenderTable.vue';
import HighlightLenders from '@/components/slices/highlight-lenders/HighlightLenders.vue';
import Columns from '@/components/slices/Columns.vue';
import LenderBox from '@/components/slices/LenderBox.vue';
import ScolarshipForm from '@/components/slices/forms/ScolarshipForm';
import CompareLendersTable from '@/components/slices/CompareLendersTable';
import HighlightedPosts from '@/components/slices/HighlightedPosts.vue';
import AllQuestionAnswers from '@/components/slices/question-answers/AllQuestionAnswers.vue';
import ContentAndImage from '@/components/slices/ContentAndImage.vue';
import ListLendersWithTable from '@/components/slices/lists/ListLendersWithTable.vue';
import ImageSlice from '@/components/slices/Image.vue';
import ImageCarousel from '@/components/slices/ImageCarousel.vue';
import CooperationLinks from '@/components/slices/CooperationLinks.vue';
import CustomizableTable from '@/components/slices/customizable-table/CustomizableTable.vue';
import CustomizableLenderTable from '@/components/slices/customizable-table/CustomizableLenderTable.vue';
import Calculator from '@/components/slices/calculator/index.vue';
import MortgageTable from '@/components/slices/tables/MortgageTable.vue';
import CompareLenders from '@/components/slices/compare-lenders/index.vue';
import LinkSection from '@/components/slices/LinkSection.vue';
import LatestUserReviews from '@/components/slices/latest-user-reviews/index.vue';
import IconBlock from '@/components/slices/IconBlock.vue';
import CompareTwoBrokers from '@/components/slices/compare-two-brokers/index.vue';
import CustomerQuotes from '@/components/slices/CustomerQuotes.vue';
import TrustpilotTable from '@/components/slices/tables/TrustpilotTable.vue';
import ConnectedBanksTable from '@/components/slices/tables/connected-banks/ConnectedBanksTable.vue';
import ContentQuote from '@/components/slices/ContentQuote.vue';
import LenderPayoutTable from '@/components/slices/LenderPayoutTable.vue';
export default {
    components: {
        BannerLinks,
        BannerLender,
        BannerSimple,
        TextBox,
        RichText,
        ListPages,
        ListCorporateLendersSelected,
        ListMortgageLendersSelected,
        ListSelectedLenders,
        ListAllLenders,
        ListAllPosts,
        ListAuthors,
        Latest,
        LoanGuide,
        FAQ,
        LatestPosts,
        InfoBoxes,
        LoanGuideCTA,
        Indexed,
        Stats,
        CompanyLoans,
        LenderTable,
        HighlightLenders,
        Columns,
        LenderBox,
        ScolarshipForm,
        CompareLendersTable,
        HighlightedPosts,
        AllQuestionAnswers,
        ContentAndImage,
        ListLendersWithTable,
        ImageSlice,
        ImageCarousel,
        CustomizableTable,
        CustomizableLenderTable,
        Calculator,
        MortgageTable,
        CompareLenders,
        LinkSection,
        LatestUserReviews,
        IconBlock,
        CooperationLinks,
        CompareTwoBrokers,
        CustomerQuotes,
        TrustpilotTable,
        ConnectedBanksTable,
        ContentQuote,
        LenderPayoutTable,
    },
    props: {
        slices: {
            type: Array,
            required: true,
        },
        document: {
            type: Object,
            required: false,
            default: null,
        },
    },
};
</script>