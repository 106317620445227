<template>
    <Section :width="sectionWidth" class="customizable-table">
        <div 
            v-if="$validateText(data.primary.header)"
            class="section-header" 
            v-html="$prismic.asHtml(data.primary.header)"
        />
        <div
            v-if="$validateText(data.primary.teaser)"
            class="rich-text teaser"
            v-html="$prismic.asHtml(data.primary.teaser)"
        />

        <header-top-row 
            v-if="data.primary.header_placement === 'top-row'"
            :table-titles="tableTitles"
            :table-data="tableData"
        />

        <header-left-column 
            v-if="data.primary.header_placement === 'left-column'"
            :table-titles="tableTitles"
            :table-data="tableData"
        />
    </Section>
</template>

<script>
import HeaderTopRow from './HeaderTopRow.vue';
import HeaderLeftColumn from './HeaderLeftColumn.vue';
export default {
    components: {
        HeaderTopRow,
        HeaderLeftColumn
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        sectionWidth() {
            return this.data.primary.section_width ? this.data.primary.section_width : 'sm';
        },
        tableTitles() {
            return Object.entries(this.data.primary)
                .map(([key, value]) => key.startsWith('title_') && value)
                .filter(title => this.$validateText(title));
        },
        tableData() {
            return this.data.items.map(item => {
                return Object.entries(item)
                    .filter(([key]) => key.startsWith('data_'))
                    .splice(0, this.tableTitles.length)
                    .map(([, value]) => this.$removeParagraphs(value));
            });
        }
    }
};
</script>