<template>
    <div class="search">
        <Button
            :text="$translate('cl_add_lenders_button_cta', 'Lägg till lån')"
            size="md"
            background="green"
            @click="show = true"
        />

        <transition name="fade">
            <div
                v-if="show"
                class="search-open"
            >
                <div class="search-open__popup">
                    <div class="search-input-wrapper">
                        <input
                            ref="input"
                            v-model="query"
                            class="search-input"
                            type="text"
                            name="search"
                            :placeholder="$translate('input_search', 'Sök...')"
                            @keydown.esc="clearQuery()"
                        >
                        <i 
                            v-if="query.length" 
                            class="fa-regular fa-xmark clear-button" 
                            role="button" 
                            @click="clearQuery()" 
                        />
                    </div>
                    <ul
                        class="search-result"
                    >
                        <li
                            v-for="(lender, index) in lenderList"
                            :key="index"
                            @click="toggle(lender.id)"
                        >
                            <prismic-image
                                :img="lender.data.image"
                                w="70"
                            />
                            <span class="title">{{ lender.data.title }}</span>
                            <i 
                                v-if="addedLenderIds.includes(lender.id)" 
                                class="fa-solid fa-check green" 
                            />
                        </li>
                    </ul>
                    <div v-if="lenderList.length === 0" class="no-results"> 
                        {{ $translate('cl_search_no_results', 'Inga resultat hittades') }}
                    </div>
                </div>

                <div
                    v-if="show"
                    class="search-open__overlay"
                    @click="query = '', show = false"
                />
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        addedLenderIds: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            query: '',
            show: false
        };
    },
    computed: {
        lenderList() {
            return Object.values(this.$store.state.lenders)
                .filter(lender => lender.data.title.toLowerCase().includes(this.query.toLowerCase()));
        }
    },
    watch: {
        show() {
            if (this.show) {
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });
            }
        }
    },
    methods: {
        toggle(id) {
            this.$emit('toggle', id);
        },
        clearQuery() {
            this.query = '';
            this.$refs.input.focus();
        }
    }
};
</script>

<style lang="scss" scoped>
    .search {
        position: relative;
        &-open {
            z-index: 100;
            @include size(100vw,100vh);
            position: fixed;
            left: 0;
            top: 0;
            &__popup {
                @include spacing(padding,4);
                max-width: 100%;
                width: 500px;
                background: $white;
                @include border;
                @include border-radius(5px);
                z-index: 1;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 100px;
                @include device(pad)  {
                    top: 200px;
                }
                .search-input-wrapper {
                    position: relative;
                    input {
                        width: 100%;
                        padding: 15px;
                        @include border-radius(5px);
                        box-sizing: border-box;
                        @include border();
                        font-size: 14px;
                    }
                    .clear-button {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 20px;
                        right: 10px;
                        cursor: pointer;
                        color: #999;
                        &:hover { color: $font;}
                    }
                }
                ul {
                    max-height: 310px;
                    overflow-y: auto;
                    overscroll-behavior: contain;
                    li {
                        @include border(bottom);
                        padding: 5px;
                        cursor: pointer;
                        @include font-size(14px,8px);
                        @include flex(start);
                        min-height: 40px;
                        &:hover .title {
                            color: $green;
                            text-decoration: underline;
                        }
                        &:last-child {
                            border-bottom: none;
                        }
                        img {
                            margin-right: 10px;
                        }
                        span { flex: 1; }
                    }
                }
                .no-results{
                    padding: 10px;
                    @include font-size(14px,8px);
                }
            }
            &__overlay {
                position: fixed;
                @include size(100%);
                top: 0;
                left: 0;
                background: rgba(0,0,0,.5);
            }
        }
    }
</style>