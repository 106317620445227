<template>
    <div
        class="rich-text rich-text__quote"
        v-html="$prismic.asHtml(data.content)"
    />
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    .rich-text__quote::v-deep {
        color: #999;
        text-align: center;
        font-style: italic;

        h2 {
            border: 0;
            padding: 0;
            color: #999;
            width: auto;
            text-align: center;
        }
    }
</style>
