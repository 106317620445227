<template>
    <div class="question-options__label">
        <div class="inner">
            <label>
                <input v-model="answer" type="checkbox" class="checkbox" name="tips">
                <div><slot /></div>
            </label>
        </div>

        <div
            v-if="error"
            class="question-error"
        >
            {{ error }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        description: {
            type: String,
            required: false,
            default: String
        },
        answerKey: {
            type: String,
            required: true,
            default: String
        },
        error: {
            type: String,
            required: false,
            default: String
        }
    },
    data() {
        return {
            answer: false
        };
    },
    watch: {
        answer() {
            this.emit();
        }
    },
    methods: {
        emit() {
            this.$emit('set', this.answerKey, this.answer);
            this.$emit('resetError', this.answerKey);
        }
    }
};
</script>

<style lang="scss" scoped>

    .question-options__label {
        @include flex;
        margin-top: 30px;

        .inner {
            width: 100%;
            max-width: 300px;
        }

        label {
            cursor: pointer;
            display: flex;
            width: auto!important;
            font-weight: normal;
            line-height: 18px;
            font-size: 12px;
            text-align: left;
        }

        input {
            // @include size(30px);
            display: inline!important;
            // width: 30px!important;
            margin-right: 10px;
        }

        .question-error {
            color: #b1403d;
            background: rgba(177,64,61,.2);
            @include spacing(padding, 2);
            margin-top: 10px;
            font-weight: bold;
            font-size: 14px;
            width: 100%;
        }
    }
</style>
