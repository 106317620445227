<template>
    <Section
        class="banner bg-cream"
        width="sm"
    >
        <span class="text-bold green">{{ document.data.category.data.category }}</span>
        <h1 class="question-title h2">
            {{ document.data.question_title }}
        </h1>
        <p class="question-text">{{ document.data.question }}</p>
        <div class="question-info green">
            <span class="text-bold"> {{ document.data.questioner }}</span>
            -
            <span>{{ $format.date(document.first_publication_date) }}</span>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .section-inner {
    @include font-size(18px);

    .question-title {
        @include spacing(margin,3,bottom);
    }

    .question-text{
        @include font-size(18px, 10px);
    }

    .question-info {
        @include spacing(margin,1,top);
    }
    .text-bold {
        font-weight: bold;
    }
}
</style>