<template>
    <nuxt-link 
        :to="$prismic.linkResolver(lender)"
        class="reviews-body"
    >
        <prismic-image
            :img="lender.data.image"
            :alt="lender.data.title"
            w="100"
            h="50"
        />
        <span class="body-rater-name">
            {{ review.rater_name }}
        </span>
        <div class="body-rating">
            <i 
                v-for="rating in 5" 
                :key="`rating_${rating}`"  
                class="fa-solid fa-star"
                :class="review.rating_score >= rating ? 'gold-star' : 'gray-star'"
            />
        </div>
    </nuxt-link>
</template>

<script>
export default {
    props: {
        review: {
            type: Object,
            required: true
        }
    },
    computed: {
        lender() {
            return this.$store.state.lenders[this.review.lenderId];
        }
    }
};
</script>