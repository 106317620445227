<template>
    <Section :width="sectionWidth" class="customizable-table">
        <div 
            v-if="$validateText(data.primary.header)"
            class="section-header" 
            v-html="$prismic.asHtml(data.primary.header)"
        />
        <div
            v-if="$validateText(data.primary.teaser)"
            class="rich-text teaser"
            v-html="$prismic.asHtml(data.primary.teaser)"
        />

        <header-top-row 
            v-if="data.primary.header_placement === 'top-row'"
            :table-titles="tableTitles"
            :table-data="tableData"
            :lenders="lenders"
            :show-logos="data.primary.show_logos"
            :show-out-links="data.primary.show_out_links"
        />

        <header-left-column 
            v-else-if="data.primary.header_placement === 'left-column'"
            :table-titles="tableTitles"
            :table-data="tableData"
            :lenders="lenders"
            :show-logos="data.primary.show_logos"
            :show-out-links="data.primary.show_out_links"
        />
    </Section>
</template>

<script>
import HeaderTopRow from './HeaderTopRow.vue';
import HeaderLeftColumn from './HeaderLeftColumn.vue';
export default {
    components: {
        HeaderTopRow,
        HeaderLeftColumn
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        sectionWidth() {
            return this.data.primary.section_width ? this.data.primary.section_width : 'sm';
        },
        tableTitles() {
            return Object.entries(this.data.primary)
                .map(([key, value]) => key.startsWith('title_') && value)
                .filter(title => this.$validateText(title));
        },
        tableData() {
            return this.data.items
                .map(item => {
                    const lender = this.$store.state.lenders[item.lender.id];

                    return Object.entries(item)
                        .filter(([key]) => key.startsWith('data_'))
                        .splice(0, this.tableTitles.length)
                        .map(([, value]) => this.replaceShortcodes(value, lender));
                });
        },
        lenders() {
            return this.data.items
                .map(item => this.$store.state.lenders[item.lender.id]);
        }
    },
    methods: {
        replaceShortcodes(value, lender) {
            if (!lender) {
                return this.$removeParagraphs(value);
            }
            const model = new this.$models.Loan(lender);
            return this.$removeParagraphs(value)
                .replace('{amount}', model.amountString)
                .replace('{maxAmount}', model.maxAmountString)
                .replace('{minAmount}', model.minAmountString)
                .replace('{interest}', model.interestString)
                .replace('{effectiveInterest}', model.effectiveInterestString)
                .replace('{creditCheck}', this.$getCreditCheckString(model.creditCheck))
                .replace('{duration}', model.getDurationString(this.$translate('month_short', 'mån'), this.$translate('year', 'år')))
                .replace('{connectedBanks}', `${model.connectedBanks}`);
        },
    },
};
</script>

<style lang="scss" scoped>
    ::v-deep .application-button {
        font-size: 14px;
        padding: 10px 20px;
        display: inline-block;
    }
</style>