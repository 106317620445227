<template>
    <div>
        <h3 class="sub-section-header">{{ h3 }}</h3>
        <div 
            v-if="$validateText(data.primary.all_connected_lenders_teaser)" 
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.all_connected_lenders_teaser)" 
        />
        <div class="dynamic-table-container">
            <table>
                <thead>
                    <tr>
                        <th />
                        <td v-for="lender in lenders" :key="lender.id">
                            {{ lender.data.title }}
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="bank in allConnectedBanks" :key="bank.id">
                        <th>
                            <nuxt-link :to="$prismic.linkResolver(bank)" class="connected-bank-wrapper">
                                <!-- TODO: Make logos sticky mobile? Try once logos added -->
                                <div class="logo-wrapper">
                                    <PrismicImage
                                        v-if="bank.data.icon.url"
                                        :img="bank.data.icon"
                                        w="40"
                                        height="40"
                                    />
                                </div>
                                <div>
                                    <div class="green">{{ bank.data.title }}</div>
                                    <div class="interest">{{ $translate('interest', 'Ränta') }}: {{ bank.model.interestString }}</div>
                                </div>
                            </nuxt-link>
                        </th>
                        <td v-for="lender in lenders" :key="lender.id">
                            <i class="boolean-icon" :class="$formatBooleanIcon(hasBank(lender, bank))" /> 
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { orderBy } from 'lodash';
export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        lenders: {
            type: Array,
            required: true,
        }
    },
    computed: {
        h3() {
            if (this.$validateText(this.data.primary.all_connected_lenders_h3)) {
                return this.$prismic.asText(this.data.primary.all_connected_lenders_h3);
            }
            return this.$translate('ctl_connected_lenders_all_connected_lenders_h3', 'Alla långivare')
                .replace('{lender1}', this.lenders[0].data.title)
                .replace('{lender2}', this.lenders[1].data.title);
        },
        allConnectedBanks() {
            const allConnectedBanks = this.lenders.map(lender => lender.connectedBanks).flat();
            const uniqueConnectedBanks = allConnectedBanks.filter((bank, index, self) => self.findIndex(b => b.id === bank.id) === index);
            return orderBy(uniqueConnectedBanks, 'data.title', 'asc');
        }
    },
    methods: {
        hasBank(lender, bank) {
            return lender.connectedBanks.some(connectedBank => connectedBank.id === bank.id);
        },
    }
};
</script>

<style lang="scss" scoped>
    thead {
        td {
            min-width: 100px !important;
        }
    }
    td {
        vertical-align: middle;
        .boolean-icon {
            font-size: 20px;
        }
    }
    .connected-bank-wrapper {
        display: flex; 
        gap: 10px; 
        align-items: center; 
        color: unset; 
        text-decoration: unset;
        .logo-wrapper {
            width: 40px; 
            height: 40px;
            img {
                @include border-radius(5px);
            }
        }
        .interest {
            font-size: 12px;
            margin-top: 6px;
        }
    }
</style>