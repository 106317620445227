<template>
    <Section> 
        <h2 v-if="$validateText(data.primary.h2)" class="section-header">{{ $prismic.asText(data.primary.h2) }}</h2>
        <div class="container">
            <div :class="{'order-2': contentFirst}">
                <prismic-image 
                    :img="data.primary.image"
                    :alt="data.primary.image.alt"
                />
            </div>
            <div class="rich-text" v-html="$prismic.asHtml(data.primary.content)" />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        contentFirst() {
            return this.data.primary.content_first;
        }
    }
};
</script>

<style lang="scss" scoped>
    .container {
        display: flex;
        gap: 16px;
        flex-direction: column;

        @include device(desktop) {
            flex-direction: row;
            > div{
                flex: 1 1 0;
            }
        }

        img {
            width: 100%;
        }

        .rich-text::v-deep {
            p:first-child{
                margin-top: 0;
            }
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
    .order-2 {
        order: 2;
    }
</style>