<template>
    <li>
        <span class="lender-title">
            <nuxt-link :to="$prismic.linkResolver(lender)"> 
                {{ lender.data.title }}
            </nuxt-link>
            :
        </span>
        <span class="list-content" v-html="$prismic.asHtml(formatedText)" />
    </li>
</template>

<script>
import { replacePrismicObject } from '@swegaming-ab/nuxtjs-helpers';
import { capitalize } from 'lodash';

export default {
    props: {
        lenderId: {
            type: String,
            required: true
        },
        lenderType: {
            type: String,
            required: false,
            default: 'lender'
        },
        text: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            toplistText: this.text
        };
    },
    computed: {
        formatedText() {
            const creditCheck = capitalize(this.model.creditCheck.repersentative || this.model.creditCheck);
            replacePrismicObject(this.toplistText, '{amount}', this.model.amountString);
            replacePrismicObject(this.toplistText, '{maxAmount}', this.model.maxAmountString);
            replacePrismicObject(this.toplistText, '{minAmount}', this.model.minAmountString);
            replacePrismicObject(this.toplistText, '{creditCheck}', creditCheck);
            replacePrismicObject(this.toplistText, '{interest}', this.model.interestString);

            return this.toplistText;
        },
        lender() {
            if (this.lenderType === 'lender_corporate') {
                return this.$store.state.lendersCorporate[this.lenderId];
            }
            return this.$store.state.lenders[this.lenderId];
        },
        model() {
            if (this.lenderType === 'lender_corporate') {
                return new this.$models.CorporateLoan(this.lender);
            }
            return new this.$models.Loan(this.lender);
            
        }
    }
};
</script>

<style lang="scss" scoped>
    .lender-title {
        display: inline-flex;
        a {
            font-weight: bold;
            color: $blue;
        }
    }
    .list-content ::v-deep p {
        display: inline;
    }
</style>