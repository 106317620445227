<template>
    <ul class="lender-stats">
        <li
            v-for="(stat, index) in stats"
            :key="index"
        >
            <span>{{ stat.label }}:</span>
            <span v-html="stat.value" />
        </li>
    </ul>
</template>

<script>
import { capitalize } from 'lodash';

export default {
    props: {
        model: {
            type: Object,
            required: true
        },
        statsType: {
            type: String,
            requried: true,
            default: 'private'
        }
    },
    data() {
        let stats = [
            {
                label: this.$translate('amount', 'Belopp'),
                value: this.model.amountString
            },
            {
                label: this.$translate('interest', 'Ränta'),
                value: this.model.interestString
            },
            {
                label: this.$translate('effective_interest', 'Effektiv ränta'),
                value: this.model.effectiveInterestString
            },
            {
                label: this.$translate('duration', 'Löptid'),
                value: this.model.getDurationString()
            },
            {
                label: this.$translate('ld_age_limit_label', 'Ålderskrav'),
                value: this.$translate('ld_age_limit_value', '{minAge} eller äldre')
                    .replace('{minAge}', this.model.minAge)
            },
        ];

        if (this.model.isBroker) {
            stats.push({
                label: this.$translate('connected_banks', 'Anslutna långivare'),
                value: this.model.connectedBanks
            });
        }

        stats.push({
            label: this.$translate('ld_payment_remarks_label', 'Betalningsanmärkningar'),
            value: this.model.acceptsPaymentRemarks ? this.$translate('accepted', 'Accepteras') : this.$translate('not_accepted', 'Accepteras inte')
        });

        if (this.$isSweden()) {
            stats = stats.concat([
                {
                    label: this.$translate('without_uc', 'Utan UC'),
                    value: (!this.model.takesUC) ? this.$translate('uses_credit_check', 'Ja, använder {creditCheck}')
                        .replace('{creditCheck}', capitalize(this.model.creditCheck)) : this.$translate('no', 'Nej')
                },
                {
                    label: this.$translate('apply_with_bankid', 'Ansök med BankID'),
                    value: this.$helpers.boolean(this.model.hasBankId)
                },
                {
                    label: this.$translate('loan_protection_can_be_signed', 'Låneskydd kan tecknas'),
                    value: this.$helpers.boolean(this.model.hasLoanProtection)
                },
                {
                    label: this.$translate('direct_payout', 'Direkt­utbetalning'),
                    value: this.$helpers.boolean(this.model.hasDirectPayments)
                },
            ]);
        }

        stats.push({
            label: this.$translate('rating', 'Betyg'),
            value: this.$translate('average_rating_score', '{averageRating} av 5 ({amount} omdömen)')
                .replace('{averageRating}', this.$format.number(this.model._ratings.average_score, 1))
                .replace('{amount}', this.model._ratings.count)
        });

        return {
            stats: stats
        };
    },
};
</script>

<style lang="scss">
.lender-stats {
    li {
        @include grid(2, 20px);
        width: 100%;
        padding: 10px;
        font-family: $fontText;
        box-sizing: border-box;
        font-size: 14px;

        &:nth-child(even) { background: #fafafa; }

        i { margin-right: 10px; }

        span {
            &:first-child { text-align: right; }
            &:last-child { font-weight: bold; }
        }
    }
}
</style>
