<!-- TODO: Are we gonna use this? -->
<!-- Todo: translation -->
<template>
    <Section
        class=""
        width="md"
    >
        <div class="page-menu">
            <span class="page-menu__header">Hitta på sidan</span>
            <ul class="page-menu__list">
                <li class="list-item"><a v-scroll-to="'#'" href="#">Nyheter om statistiken</a></li>
                <li class="list-item"><a v-scroll-to="'#'" href="#">Privatlån</a></li>
                <li class="list-item"><a v-scroll-to="'#'" href="#">Snabblån</a></li>
                <li class="list-item"><a v-scroll-to="'#'" href="#">Kontokredit</a></li>
                <li class="list-item"><a v-scroll-to="'#'" href="#">Villkor för användning av statistiken</a></li>
            </ul>
        </div>

        <div class="page-content">

        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
    section::v-deep {
        .section-inner {
            @include flex;

            .page-menu {
                opacity: .5;
                flex: 1;
                max-width: 300px;
                &:hover { opacity: 1; }

                &__header {
                    font-size: 22px;
                    @include spacing(margin,6,bottom);
                    font-family: $fontHeader;
                    color: green;
                }
            }

            .page-content {
                flex: 1;
            }
        }
    }
</style>
