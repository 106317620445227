<template>
    <Section
        v-if="linkDocuments.length"
        width="md"
    >
        <div 
            v-if="$validateText(data.primary.text)"
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.text)"
        />
        <div class="link-section-wrapper">
            <nuxt-link
                v-for="(linkDocument, index) in linkDocuments"
                :key="index"
                :to="$prismic.linkResolver(linkDocument)"
                class="link-section-body"
            >
                <prismic-image 
                    v-if="linkDocument.data.image && linkDocument.data.image.url"
                    :img="linkDocument.data.image.thumbnail || linkDocument.data.image"
                    w="400"
                    h="200"
                />
                <img 
                    v-else
                    :src="fallbackImage.src" 
                    :alt="fallbackImage.alt"
                    width="400"
                    height="200"
                >
                <h3 v-if="$validateText(linkDocument.data.title)" class="h5 body-title">
                    {{ linkDocument.data.title }}
                </h3>
            </nuxt-link>
        </div>
    </Section>
</template>
<script>
import { replace } from '@swegaming-ab/nuxtjs-helpers';
export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        document: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            linkDocuments: []
        };
    },
    async fetch() {
        const predicates = [
            this.$prismic.predicates.any('document.type', ['page', 'post']),
        ];

        if (this.selectedDocumentsIds.length) {
            predicates.push(this.$prismic.predicates.in('document.id', this.selectedDocumentsIds));
        }
        else if (this.data.primary.prismic_tag) {
            predicates.push(
                this.$prismic.predicates.at('document.tags', [this.data.primary.prismic_tag]),
                this.$prismic.predicates.not('document.id', this.document.id),
            );
        }
        const response = await this.$prismic.api.query( 
            predicates, 
            {
                lang: process.env.PRISMIC_LOCALE,
                orderings: '[ document.first_publication_date desc ]',
                pageSize: 3
            }
        );
        this.linkDocuments = replace(response.results);
    },
    fetchKey() {
        return `three-document-links-${this.data.id}`;
    },
    computed: {
        selectedDocumentsIds() {
            return this.data.items
                .filter(item => item.document.id)
                .map(item => item.document.id);
        },
        fallbackImage() {
            if (this.$isNorway()) {
                return {
                    src: '/fallback-logo-no.png',
                    alt: 'Lånene.no logo'
                };
            }
            return {
                src: '/fallback-logo.png',
                alt: 'Lånen.se logo'
            };
        }
    }
};
</script>
<style lang='scss' scoped>
    .link-section-wrapper {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;

        @include device(pad) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include device(desktop) {
            grid-template-columns: repeat(3, 1fr);
        }

        .link-section-body {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            @include border-radius(3px); 
            box-shadow: $bxs;
            text-decoration: none;

            &:hover {
                .body-title {
                    text-decoration: underline;
                }
            }
            
            img {
                width: 100%;
                height: auto;
                aspect-ratio: 2 / 1;
                object-fit: cover;
            }
            
            .body-title {
                padding: 1rem;
                color: $font;
                text-decoration: none;
                word-break: break-word;
            }
        }
    }
</style>